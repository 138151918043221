import React, { useEffect, useState, useRef, useContext } from "react";
import {
  Row,
  Container,
  Col,
  Dropdown,
  Modal,
  Alert,
  Accordion,
  Card,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import MovieCard from "./partials/MovieCard.jsx";
import MovieShow from "./partials/movieShowNew.jsx";
import {
  clearSeatSelection,
  privateScreeningReserveSeat,
  releaseRealTimeBlockedSeats,
} from "../store/seatLayout/seatLayoutActions";
import {
  fetchMovieDetails,
  setStage,
  setBookingType,
  fetchOtherScreenings,
  setFromMoviePage,
  fetchConfirmedList,
  checkCreateYourShowEnabled,
} from "../store/movies/movieActions";
import { setShowLoginPopup } from "../store/auth/authActions";
import { addUserToSegment } from "../store/promotions/promotionsActions";
import dummyPoster from "@assets/pictures/dummyPoster.png";
import ScreeningCard from "./partials/screeningCard.jsx";
import ScreeningCardNew from "./partials/screeningCardNew.jsx";
import MovieFooter from "./partials/movieFooter.jsx";
import poster from "@assets/pictures/poster.png";
import calendar from "@assets/pictures/svgs/calendar.svg";
import eye from "@assets/pictures/svgs/eye.svg";
import clock from "@assets/pictures/svgs/clock.svg";
import format from "@assets/pictures/svgs/format.svg";
import people from "@assets/pictures/svgs/people.svg";
import activePeople from "@assets/pictures/svgs/people-active.svg";
import login from "@assets/pictures/svgs/login.svg";
import activeLogin from "@assets/pictures/svgs/login-active.svg";
import play from "@assets/pictures/svgs/play.svg";
import eyeGreen from "@assets/pictures/svgs/eye-green.svg";
import sandClock from "@assets/pictures/svgs/sand-clock.svg";
import filterImg from "@assets/pictures/svgs/filter-solid.svg";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { withNamespaces } from "react-i18next";
import { useParams } from "react-router";
import moment from "moment";
import arrowBack from "@assets/pictures/svgs/arrow-back.svg";
import arrowBackActive from "@assets/pictures/svgs/arrow-back-active.svg";
import DatePicker from "react-date-picker";
import calendarWhite from "@assets/pictures/svgs/calendar-white.svg";
import close from "@assets/pictures/svgs/close.svg";
import poster2 from "@assets/pictures/poster-2.png";
import Spinner from "./partials/Spinner";
import useIsMobile from "../customHooks/useIsMobile";
import ModalVideo from "react-modal-video";
import DayPicker from "react-day-picker";
import {
  hasDirector,
  hasStaring,
  sortAlphabetically,
} from "../helper/index.js";
import {
  getMovieVersionsText,
  generateMovieVersionsText,
} from "@helper/formatting";
import { getLangSpecificAttribute } from "@helper/languages";
import MomentLocaleUtils from "react-day-picker/moment";

const MovieDetails = (props) => {
  const currentCinema = useSelector((state) => state.movies.selectedCinema);
  const { match, t } = props;
  const [shareModal, setShareModal] = useState(false);
  const [currentVideoId, setCurrentVideoId] = useState(null);
  const [videoChannel, setVideoChannel] = useState("youtube");
  const [peopleSvg, setPeopleSvg] = useState(true);
  const [peopleSvg2, setPeopleSvg2] = useState(true);
  const [loginSvg, setLoginSvg] = useState(true);
  const [back, setBack] = useState(false);
  const history = useHistory();
  const { movie_id, content_lang_id, type } = useParams();
  const category_id = 2;
  const genre_id = 1;
  const { isPrivate } = match.params;
  // console.log("isPrivate :>> ", isPrivate);
  const dispatch = useDispatch();
  const [value, onChange] = useState(null);
  useEffect(() => {
    dispatch(
      fetchMovieDetails({
        movie_id,
        content_lang_id,
        cinema_id: selectedCinema?.cinema_id
          ? selectedCinema?.cinema_id
          : selectedCinemaFromLocal?.cinema_id
          ? selectedCinemaFromLocal?.cinema_id
          : null,
        cust_id: loggedInUser?.cust_id || null,
      })
    );
    // dispatch(fetchConfirmedList({ movie_id, category_id }));
    //console.log("[fetchConfirmedList] original function");
    dispatch(
      fetchConfirmedList({
        movie_id,
        call: 1,
        callback: () => {},
        setDate: setSelectedDate,
        setDateValue: onChange,
        isNowShowing: true,
        onChangeCinema,
        setSelectedLanguage,
        setSelectedFormat,
      })
    );
    dispatch(fetchOtherScreenings({ genre_id, movie_id }));
    dispatch(clearSeatSelection());
  }, [movie_id, content_lang_id]);
  const movieDetails = useSelector((state) => state.movies.movieDetails);
  //console.log("movie details logged", movieDetails);
  // useEffect(() => {
  //   if (movieDetails && category_id == 2) {
  //     dispatch(fetchOtherScreenings({ genre_id: 2 }));
  //   }
  // }, [movieDetails, category_id]);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const screeningTypes = useSelector((state) => state.movies.screeningTypes);
  const screeningTypesLoading = useSelector(
    (state) => state.movies.screeningTypesLoading
  );
  const show = useSelector((state) => state.movies.show);
  const otherScreeningsMovies = useSelector(
    (state) => state.movies.otherScreenings
  );
  const [isOpen, setOpen] = useState(false);
  const selected_language = useSelector(
    (state) => state.movies.selected_language
  );

  const { lang_name, iso_2, lang_id } = selected_language;
  const getCurrentLangIsoCode = () => iso_2?.toLowerCase() || "en";
  // const languageSpecificMovieFunction = () => {
  //   switch (lang_name) {
  //     case "English":
  //       getLanguageSpecificMovieDetails.current = movieDetails?.altMovieContent?.find(
  //         (c) => c.lang_name === "English"
  //       );
  //       break;
  //     case "German":
  //       getLanguageSpecificMovieDetails.current = movieDetails?.altMovieContent?.find(
  //         (c) => c.lang_name === "German"
  //       );
  //       break;
  //     case "Italian":
  //       getLanguageSpecificMovieDetails.current = movieDetails?.altMovieContent?.find(
  //         (c) => c.lang_name === "Italian"
  //       );
  //       break;
  //     case "French":
  //       getLanguageSpecificMovieDetails.current = movieDetails?.altMovieContent?.find(
  //         (c) => c.lang_name === "French"
  //       );
  //       break;
  //     case "Hindi":
  //       getLanguageSpecificMovieDetails.current = movieDetails?.altMovieContent?.find(
  //         (c) => c.lang_name === "Hindi"
  //       );
  //       break;
  //     default:
  //       getLanguageSpecificMovieDetails.current = movieDetails;
  //   }
  //   if (getLanguageSpecificMovieDetails.current === undefined) {
  //     getLanguageSpecificMovieDetails.current = movieDetails;
  //   }
  // };
  // useEffect(() => languageSpecificMovieFunction(), [lang_name, movieDetails]);
  // useEffect(
  //   () => movieDetails && dispatch(setLanguageSpecificMovieFunction()),
  //   [movieDetails]
  // );
  let getLanguageSpecificMovieDetails = useSelector(
    (state) => state.movies.getLanguageSpecificMovieDetails
  );

  const onSelectScreeningType = (type, showName) => {
    // console.log("type :>> ", type);
    dispatch(setBookingType(type));
    if (type === 1 || type === 2) {
      dispatch(setFromMoviePage());
      history.push(
        `/seat-layout/${show.md_id}/${show.ss_id}/${show.screen_id}/${category_id}/${show.gogo_ss_id}/${type}/${movie_id}/${content_lang_id}/${genre_id}/${isPrivate}`
      );
    } else {
      // console.log("showName :>> ", showName);
      let tempShow;
      if (showName) {
        tempShow = showName;
      } else {
        tempShow = show;
      }
      console.log("show :>> ", show);
      dispatch(
        privateScreeningReserveSeat({
          screen_id: tempShow.screen_id,
          history,
          gogo_ss_id: tempShow.gogo_ss_id,
          category_id,
          cinema_id: tempShow.cinema_id,
          movie_id,
          content_lang_id,
          genre_id,
          isPrivate,
        })
      );
    }
  };

  useEffect(() => {
    if (!movieDetails) {
      // alert("hi");
      // const spinner = document.getElementById("main-spinner");
      // if (spinner) {
      //   spinner.style.display = "flex";
      //   setTimeout(() => setCalendarPopup(false), 10);
      //   setTimeout(() => {
      //     spinner.style.display = "none";
      //   }, 2000);
      // }
      // else {
      //   alert("hi2");
      //   spinner.style.display = "none";
      // }
    }
  }, [movieDetails]);

  const movieSchedules = useSelector((state) => state.movies.movieSchedules);
  const movieSchedulesLoading = useSelector(
    (state) => state.movies.movieSchedulesLoading
  );
  const movieScheduleDetails = useSelector(
    (state) => state.movies.movieScheduleDetails
  );
  const onChangeCalenderDate = (value) => console.log(value);
  const todaysScreeningsMovies = useSelector(
    (state) => state.homepage.todaysScreenings
  );
  const [selectedDate, setSelectedDate] = useState(
    moment().format("YYYY-MM-DD")
  );
  const selectedCinemaFromLocal = useSelector(
    (state) => state.movies.selectedCinema
  );
  const [selectedCinema, setSelectedCinema] = useState(null);
  const [selectedLanguage, setSelectedLanguage] = useState(null);
  const [selectedLanguage2, setSelectedLanguage2] = useState(null);
  const [selectedFormat2, setSelectedFormat2] = useState(null);
  const [selectedFormat, setSelectedFormat] = useState(null);
  const dateMovieScheduleDetails = useSelector(
    (state) => state.movies.dateMovieScheduleDetails
  );
  const filterMovieScheduleDetails = useSelector(
    (state) => state.movies.filterMovieScheduleDetails
  );
  const seats_selected = useSelector(
    (state) => state.seatLayout.seats_selected
  );
  // useEffect(() => {
  //   if (dateMovieScheduleDetails?.dateList.length > 0) {
  //     console.log(
  //       "movieScheduleDetails.dateList[0].ss_actual_start_date :>> ",
  //       movieScheduleDetails.dateList[0].ss_actual_start_date
  //     );
  //     alert(2);
  //     setSelectedDate(movieScheduleDetails.dateList[0].ss_actual_start_date);
  //     onChange(movieScheduleDetails.dateList[0].ss_actual_start_date);
  //   }
  // }, [movieScheduleDetails]);
  // const dateSlider = {
  //   arrows: true,
  //   dots: false,
  //   infinite: true,
  //   speed: 500,
  //   slidesToShow: 6,
  //   slidesToScroll: 6,
  // };
  const dateSlider = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 5,
    responsive: [
      {
        breakpoint: 1500,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          arrows: true,
          slidesToShow: 12,
          slidesToScroll: 12,
        },
      },
      {
        breakpoint: 1100,
        settings: {
          arrows: true,
          slidesToShow: 10,
          slidesToScroll: 10,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          arrows: true,
          slidesToShow: 8,
          slidesToScroll: 8,
        },
      },
      {
        breakpoint: 767,
        settings: {
          arrows: true,
          slidesToShow: 4,
          slidesToScroll: 4,
        },
      },
    ],
  };
  const onChangeDate = (date) => {
    console.log("inside on change date :>> ", date);
    onChange(new Date(date.ss_actual_start_date));
    const { ss_actual_start_date } = date;
    setSelectedLanguage(null);
    setSelectedFormat(null);
    setSelectedLanguage2(null);
    setSelectedFormat2(null);

    setSelectedDate(ss_actual_start_date);
  };
  const onChangeCinema = (cinema, date = null) => {
    const { cinema_id } = cinema;
    // console.log(cinema);
    // console.log("[fetchConfirmedList] from onChangeCinema function");
    dispatch(
      fetchConfirmedList({
        movie_id,
        category_id,
        cinema_id,
        dateSchedule: moment(date ? date : selectedDate).format("YYYY-MM-DD"),
        lang_id: selectedLanguage?.lang_id,
        mf_id: selectedFormat?.mf_id,
      })
    );
    setSelectedCinema(cinema);

    if (cinema_id) {
      dispatch(
        fetchMovieDetails({
          movie_id,
          content_lang_id,
          cinema_id: cinema_id,
          cust_id: loggedInUser.cust_id || null,
        })
      );
    }
  };
  const onChangeLanguage = (language) => {
    const { lang_id } = language;

    setSelectedLanguage2(language);
    // setModalShow(false);
  };
  const onConfirmShowDetailsPopup = () => {
    setSelectedLanguage(selectedLanguage2);
    setSelectedFormat(selectedFormat2);
    dispatch(
      fetchConfirmedList({
        movie_id,
        category_id,
        cinema_id: selectedCinema?.cinema_id,
        dateSchedule: moment(selectedDate).format("YYYY-MM-DD"),
        lang_id: selectedLanguage2?.lang_id,
        mf_id: selectedFormat2?.mf_id,
      })
    );
    setModalShow(false);
  };

  const onChangeFormat = (format) => {
    const { mf_id } = format;
    // dispatch(
    //   fetchConfirmedList({
    //     movie_id,
    //     category_id,
    //     cinema_id: selectedCinema?.cinema_id,
    //     dateSchedule: moment(selectedDate).format("YYYY-MM-DD"),
    //     lang_id: selectedLanguage?.lang_id,
    //     mf_id,
    //   })
    // );
    setSelectedFormat2(format);
    // setModalShow(false);
  };

  const isCreateShowEnabled = useSelector(
    (state) => state.movies.createYourShowEnabled
  );

  useEffect(() => {
    const cinema = JSON.parse(localStorage.getItem(`selected_cinema`));

    if (
      selectedDate &&
      ((selectedCinema && selectedCinema.cinema_id) ||
        (cinema && cinema.cinema_id)) &&
      // selectedLanguage &&
      // selectedLanguage.lang_id &&
      // selectedFormat &&
      // selectedFormat.mf_id &&
      movie_id
    ) {
      dispatch(
        checkCreateYourShowEnabled({
          date: moment(selectedDate).format("YYYY-MM-DD"),
          cinema_id: selectedCinema
            ? selectedCinema.cinema_id
            : cinema
            ? cinema.cinema_id
            : null,
          // movie_lang_id: selectedLanguage.lang_id,
          // mf_id: selectedFormat.mf_id,
          movie_id,
        })
      );
    }
  }, [
    selectedDate,
    selectedCinema,
    // selectedLanguage,
    // selectedFormat,
    movie_id,
  ]);

  useEffect(() => {
    if (selectedDate) {
      console.log("[fetchConfirmedList] from selectedDate useEffect");
      dispatch(
        fetchConfirmedList({
          movie_id,
          category_id,
          dateSchedule: selectedDate,
          cinema_id: selectedCinema?.cinema_id,
          lang_id: null,
          mf_id: null,
          call: 2,
          callback: () => {},
        })
      );
    }
  }, [selectedDate]);

  // const [stage, setStage] = useState(1);
  // const stage = useSelector((state) => state.movies.stage);
  const stage = 1;
  const [calendarPopup, setCalendarPopup] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [calenderDateSelected, setCalenderDateSelected] = useState(false);
  const movieSlider = {
    arrows: true,
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow:
      otherScreeningsMovies.length > 6 ? 6 : otherScreeningsMovies.length,
    slidesToScroll: 6,
    responsive: [
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 6,
          slidesToScroll: 6,
          arrows: false,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
          arrows: false,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
          arrows: false,
        },
      },
    ],
  };

  const showDetails = {
    movieImg: poster2,
    tags: ["Family", "Children", "3D MAX"],
    features: ["2019", "FSK 18", "1h10min", "DE"],
    languages: ["English", "French", "Spanish", "Greek"],
    formats: ["2D", "3D", "IMAX"],
  };

  const loginDetails = useSelector((state) => state.auth);
  const { loggedInUser, userHistory, registerError, loginError } = loginDetails;
  const isAddedToSegmentOnView = useRef(false);

  useEffect(() => {
    if (loggedInUser && !isAddedToSegmentOnView.current && type == 3) {
      dispatch(
        addUserToSegment({
          movie_id,
          cust_id: loggedInUser.cust_id,
          cust_email: loggedInUser.cust_email,
          segment_type: "VIEW",
        })
      );
      isAddedToSegmentOnView.current = true;
    }
  }, [loggedInUser]);

  const handleAddUserToSegment = () => {
    if (!loggedInUser) {
      dispatch(setShowLoginPopup(true));
    } else if (loggedInUser.cust_id && loggedInUser.cust_email) {
      dispatch(
        addUserToSegment({
          movie_id,
          cust_id: loggedInUser.cust_id,
          cust_email: loggedInUser.cust_email,
          segment_type: "REMINDER",
        })
      );
    }
  };

  const footerObj = {
    image:
      getLangSpecificAttribute(
        movieDetails?.languageSpecificImages,
        lang_id,
        "artwork"
      ) || dummyPoster,
    movie_title: getLangSpecificAttribute(
      movieDetails?.altMovieContent,
      lang_id,
      "mc_title"
    ),
    lang: selectedLanguage?.lang_name,
    format: selectedFormat?.mf_nam,
    genre: movieDetails?.genre,
    rating: movieDetails?.rating,
    cinemaName: selectedCinema?.cinema_name,
    date: selectedDate,
    cine_address: selectedCinema?.cine_address || "",
    cine_location: selectedCinema?.cine_location || "",
  };

  const isMobile = useIsMobile();

  let activeCinema = selectedCinema ? selectedCinema : selectedCinemaFromLocal;

  // function showMovieSection() {
  //   const movieSection = document.querySelector("#movieShow");
  //   const bookNowBtn = document.querySelector(".book-now-btn");
  //   const screenHeight = window.innerHeight;
  //   if (movieSection && bookNowBtn) {
  //     const neededScroll = movieSection.offsetTop;
  //     const scrolled = window.scrollY;
  //     if (
  //       screenHeight - neededScroll + scrolled > 300 ||
  //       neededScroll < screenHeight
  //     ) {
  //       bookNowBtn.style.display = "none";
  //     } else {
  //       bookNowBtn.style.display = "flex";
  //     }
  //     console.log(
  //       "if condition is true",
  //       { screenHeight },
  //       { neededScroll },
  //       { scrolled }
  //     );
  //   }
  //   console.log("this should be logged");
  // }

  // useEffect(showMovieSection);
  // window.addEventListener("scroll", showMovieSection);

  // function showSectionScroll(e) {
  //   const movieSection = document.querySelector("#movieShow");
  //   const bookNowBtn = document.querySelector(".book-now-btn");
  //   if (movieSection && bookNowBtn) {
  //     movieSection.scrollIntoView();
  //     bookNowBtn.style.display = "none";
  //   }
  // }

  function arrowRotate() {
    // let openedElement = document.querySelector(".card-header.show");
    // let collapsingElement = document.querySelector(".collapsing");
    // console.log(openedElement);
    // const elem = e.target;
    // if (openedElement && openedElement !== elem) {
    //   console.log("entered");
    //   openedElement.classList.remove("show");
    // }
    // if (elem.classList.contains("card-header")) {
    //   if (elem.nextElementSibling.classList.contains("collapse")) {
    //     if (elem.classList.contains("show")) {
    //       elem.classList.remove("show");
    //       elem.classList.add("hide");
    //     } else if (elem.classList.contains("hide")) {
    //       elem.classList.remove("hide");
    //       elem.classList.add("show");
    //     } else {
    //       elem.classList.add("show");
    //     }
    //   }
    // }
    if (accordion.current.classList.contains("hide")) {
      accordion.current.classList.remove("hide");
      accordion.current.classList.add("show");
    } else if (accordion.current.classList.contains("show")) {
      accordion.current.classList.remove("show");
      accordion.current.classList.add("hide");
    }
  }

  const accordion = useRef();

  // const [faqs, setFaqs] = useState(null);

  // useEffect(() => {
  //   if (document.querySelector(".accordion")) {
  //     document
  //       .querySelector(".accordion")
  //       .addEventListener("click", arrowRotate);
  //   }
  // }, []);

  return (
    <div
      className=""
      onClick={() => {
        setCalendarPopup(false);
      }}
    >
      {/* <ShowDetailsPopup
        showDetails={showDetails}
        closeFunc={setModalShow}
        onHide={() => setModalShow(false)}
      /> */}
      {/* POPUP START */}
      <Modal
        show={modalShow}
        aria-labelledby="contained-modal-title-vcenter"
        className="show-details-popup"
        centered
        onHide={() => setModalShow(false)}
      >
        <section className="wrapper container-fluid">
          <img
            src={close}
            className="close-btn-main"
            onClick={() => {
              setModalShow(false);
              if (!selectedLanguage) {
                setSelectedLanguage2(null);
              }
              if (!selectedFormat) {
                setSelectedFormat2(null);
              }
            }}
            onHide={() => setModalShow(false)}
            alt=""
          />

          <div className="row align-items-center">
            <div className="col-sm-4 d-none d-md-block">
              <div className="poster-img">
                <img
                  src={
                    getLangSpecificAttribute(
                      movieDetails?.languageSpecificImages,
                      lang_id,
                      "artwork"
                    ) || dummyPoster
                  }
                  alt={t("alt.Poster")}
                  className=""
                />
              </div>
            </div>
            <div className="col-sm-8">
              <h4 className="heading">
                {getLangSpecificAttribute(
                  movieDetails?.altMovieContent,
                  lang_id,
                  "mc_title"
                )}
              </h4>
              {/* className="tags" */}
              <div className="md-genres">
                {/* {showDetails.tags.map((tag) => (
                  <span className="">{tag}</span>
                ))} */}
                {movieDetails?.genres_with_translations
                  ?.filter((genre) => genre[iso_2?.toLowerCase()])
                  ?.map(
                    (genre, index) =>
                      genre[iso_2.toLowerCase()] &&
                      index < 4 && (
                        <span key={index}>{genre[iso_2.toLowerCase()]}</span>
                      )
                  )}
              </div>
              <div className="features">
                <div className="features-part">
                  {/* {showDetails.features.map((feature) => (
                    <div>
                      <img src={calendar} alt="calendar" />
                      <span>{feature}</span>
                    </div>
                  ))} */}
                  <div>
                    <img src={calendar} alt={t("alt.calendar")} />
                    <span>
                      {moment(
                        movieDetails?.original_mrrdr_release_date ===
                          `Invalid Date`
                          ? moment()
                          : movieDetails?.original_mrrdr_release_date ||
                              moment()
                      ).format("Do MMM, YYYY")}
                    </span>
                  </div>
                  <div>
                    <img src={eye} alt={t("alt.calendar")} />
                    <span>{movieDetails?.rating}</span>
                  </div>
                  <div>
                    <img src={clock} alt={t("alt.calendar")} />
                    <span>
                      {movieDetails?.mrrdr_runtime} {t("common.mins")}
                    </span>
                  </div>
                  <div>
                    <img src={format} alt={t("alt.calendar")} />
                    {/* <span>{t("common.DE")}</span> */}
                    <span>{movieDetails?.mf_name}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="px-3 w-100">
              <h6 className="sub-heading">{t("Choose Language")}</h6>
              <div className="btn-main-group">
                {filterMovieScheduleDetails?.LanguageArray.map(
                  (language, index) => (
                    // add class active in button element when selected
                    <button
                      className={`tile ${
                        language.lang_id === selectedLanguage2?.lang_id &&
                        "active"
                      }`}
                      onClick={() => onChangeLanguage(language)}
                      key={`${index}`}
                    >
                      {language.lang_name}
                    </button>
                  )
                )}
              </div>
            </div>
          </div>

          <div className="row">
            <div className="px-3 w-100">
              <h6 className="sub-heading">{t("Choose Format")}</h6>
              <div className="btn-main-group">
                {filterMovieScheduleDetails?.FormatArray.map(
                  (format, index) => (
                    // add class active in button element when selected
                    <button
                      className={`tile format ${
                        format.mf_id === selectedFormat2?.mf_id && "active"
                      }`}
                      onClick={() => onChangeFormat(format)}
                      key={index}
                    >
                      {format.mf_name}
                    </button>
                  )
                )}
              </div>
            </div>
          </div>

          <div className="row pt-2">
            <div className="px-3 mt-4 mx-auto">
              {/* add class inactive when it is not selectable */}
              <button
                className="btn-main btn-main-lg"
                onClick={() => onConfirmShowDetailsPopup()}
              >
                {t("Confirm")}
              </button>
            </div>
          </div>
        </section>
      </Modal>
      {/* POPUP END */}

      {category_id == 2 ? (
        <div className="container-fluid container-xl header-space">
          <div className="row movie-container">
            {!isMobile ? (
              <>
                <>
                  {/* New layout starts */}
                  <section className="px-3 mi">
                    <div className="img-card">
                      <img
                        src={
                          getLangSpecificAttribute(
                            movieDetails?.languageSpecificImages,
                            lang_id,
                            "artwork"
                          ) || dummyPoster
                        }
                        alt=""
                        className=""
                      />
                    </div>
                  </section>

                  <section className="px-3 md movie-details">
                    <article className="d-flex align-items-center justify-content-between mb-2">
                      <div className="">
                        {getLangSpecificAttribute(
                          movieDetails?.altMovieContent,
                          lang_id,
                          "mc_title"
                        ) && (
                          <p className="movie-name mb-1">
                            {getLangSpecificAttribute(
                              movieDetails?.altMovieContent,
                              lang_id,
                              "mc_title"
                            )}
                          </p>
                        )}
                        {movieDetails?.mc_title &&
                          movieDetails?.mc_title !=
                            getLangSpecificAttribute(
                              movieDetails?.altMovieContent,
                              lang_id,
                              "mc_title"
                            ) && (
                            <p className="mf_name">{movieDetails?.mc_title}</p>
                          )}
                      </div>
                      <p className="mf_name pl-3">{movieDetails?.mf_name}</p>
                    </article>
                    <p className="text-limit movie-synopsis" id="movieDesc">
                      {getLangSpecificAttribute(
                        movieDetails?.altMovieContent,
                        lang_id,
                        "mc_plot"
                      ) || movieDetails?.mc_plot}
                    </p>
                    <table className="">
                      {movieDetails?.movie_title && (
                        <tr>
                          <td>{t("Original Title")}</td>
                          <td>{movieDetails?.movie_title}</td>
                        </tr>
                      )}
                      {movieDetails?.original_mrrdr_release_date && (
                        <tr>
                          <td>{t("Release Date")}</td>
                          <td>
                            {moment(
                              movieDetails?.original_mrrdr_release_date ===
                                `Invalid Date`
                                ? moment()
                                : movieDetails?.original_mrrdr_release_date ||
                                    moment()
                            ).format("MMMM D, YYYY")}
                          </td>
                        </tr>
                      )}
                      {movieDetails?.rating && (
                        <tr>
                          <td>{t("Age Rating")}</td>
                          <td>{movieDetails?.rating}</td>
                        </tr>
                      )}
                      {movieDetails?.mrrdr_runtime && (
                        <tr>
                          <td>{t("Runtime")}</td>
                          <td>
                            {movieDetails?.mrrdr_runtime} {t("common.mins")}
                          </td>
                        </tr>
                      )}
                      {movieDetails?.original_mrrdr_release_date && (
                        <tr>
                          <td>{t("Year")}</td>
                          <td>
                            {moment(
                              movieDetails?.original_mrrdr_release_date ===
                                `Invalid Date`
                                ? moment()
                                : movieDetails?.original_mrrdr_release_date ||
                                    moment()
                            ).format("YYYY")}
                          </td>
                        </tr>
                      )}
                      {movieDetails?.lang_name && (
                        <tr>
                          <td>{t("Original Language")}</td>
                          <td>{movieDetails?.lang_name}</td>
                        </tr>
                      )}

                      {movieDetails?.genres_with_translations && (
                        <tr>
                          <td>
                            {movieDetails?.genres_with_translations.length < 2
                              ? t("Genre")
                              : t("Genres")}
                          </td>
                          <td className="comma-separated">
                            {movieDetails?.genres_with_translations
                              ?.filter(
                                (genre, index) => genre[iso_2.toLowerCase()]
                              )
                              ?.map(
                                (genre, index) =>
                                  genre[iso_2.toLowerCase()] &&
                                  index < 4 && (
                                    <span key={index}>
                                      {genre[iso_2.toLowerCase()]}
                                    </span>
                                  )
                              )}
                          </td>
                        </tr>
                      )}

                      {hasDirector(movieDetails?.castCrewDetails) && (
                        <tr>
                          <td>
                            <p className="detail-title">{t("Director")}</p>
                          </td>
                          <td>
                            {/* <p> */}
                            {movieDetails?.castCrewDetails?.map(
                              (cast, index) =>
                                cast?.crew_title === `Director` && (
                                  <p key={index}>
                                    {/* {cast.crew_title} -{" "} */}
                                    {cast?.Crew?.map((crew, index2) => (
                                      <span
                                        key={index2}
                                        // style={{ display: "inline-block" }}
                                        className="text-break"
                                      >
                                        {/* <p> */}
                                        {"  "}
                                        {crew.mcc_cast_name}
                                        {index2 !== cast.Crew.length - 1
                                          ? `, `
                                          : ""}
                                        &nbsp;
                                        {/* </p> */}
                                      </span>
                                    ))}
                                  </p>
                                )
                            )}
                            {/* </p> */}
                          </td>
                        </tr>
                      )}
                      {hasStaring(movieDetails?.castCrewDetails) && (
                        <tr>
                          <td>
                            <p className="detail-title">{t("Cast")}</p>
                          </td>
                          <td>
                            {/* <p> */}
                            {movieDetails?.castCrewDetails?.map(
                              (cast, index) =>
                                cast?.crew_title !== `Director` && (
                                  // <div key={index}>
                                  <p key={index}>
                                    {/* {cast.crew_title} -{" "} */}
                                    {cast?.Crew?.map((crew, index2) => (
                                      <span
                                        key={index2}
                                        // style={{ display: "inline-block" }}
                                        className="text-break"
                                      >
                                        {/* <p> */}
                                        {"  "}
                                        {crew.mcc_cast_name}
                                        {index2 !== cast.Crew.length - 1
                                          ? `, `
                                          : ""}
                                        &nbsp;
                                        {/* </p> */}
                                      </span>
                                    ))}
                                  </p>
                                  // {/* </div> */}
                                )
                            )}
                            {/* </p> */}
                          </td>
                        </tr>
                      )}
                      {/* {movieDetails?.languages_with_translations?.length >
                        0 && (
                        <tr>
                          <td>
                            <p className="detail-title">
                              {t("common.Languages")}
                            </p>
                          </td>
                          <td>
                            <ul className="list-inline mb-0">
                              <ModalVideo
                                autoplay
                                channel={videoChannel}
                                isOpen={isOpen}
                                videoId={currentVideoId}
                                onClose={() => setOpen(false)}
                              />
                              {movieDetails?.languages_with_translations
                                ?.sort(sortAlphabetically(`lang_name`))
                                ?.map((lang, index) => (
                                  <li
                                    className="list-inline-item lang-coma"
                                    key={index}
                                  >
                                    <p className="mb-2">
                                      {lang[iso_2.toLowerCase()]}
                                    </p>
                                  </li>
                                ))}
                            </ul>
                          </td>
                        </tr>
                      )} */}
                      {/* {movieDetails?.movieSubtitles?.[0]?.lang_name && (
                        <tr>
                          <td>
                            <p className="detail-title">{t("Subtitles")}</p>
                          </td>
                          <td>
                            <ul className="list-inline mb-0">
                              {movieDetails?.movieSubtitles
                                ?.sort(sortAlphabetically(`lang_name`))
                                ?.map(
                                  (lang, index) =>
                                    (lang?.lang_name ||
                                      lang[iso_2.toLowerCase()]) && (
                                      <li
                                        className="list-inline-item lang-coma"
                                        key={index}
                                      >
                                        <p className="mb-2">
                                          {lang[iso_2.toLowerCase()]
                                            ? lang[iso_2.toLowerCase()]
                                            : lang?.lang_name}
                                        </p>
                                      </li>
                                    )
                                )}
                            </ul>
                          </td>
                        </tr>
                      )} */}
                      {movieDetails?.trailer_array?.length > 0 && (
                        <tr>
                          <td>
                            <p className="detail-title mt-2">
                              {movieDetails?.trailer_array?.length > 1
                                ? t("common.Trailers")
                                : t("Trailer")}
                            </p>
                          </td>
                          <td>
                            <ul className="list-inline mb-0">
                              <ModalVideo
                                autoplay
                                channel={videoChannel}
                                isOpen={isOpen}
                                videoId={currentVideoId}
                                onClose={() => setOpen(false)}
                              />
                              {movieDetails?.trailer_array
                                ?.sort(sortAlphabetically(`lang_name`))
                                ?.map((trailer, index) => (
                                  // trailer?.mt_link
                                  <li className="list-inline-item" key={index}>
                                    <p
                                      className="mr-3 play-trailer mb-2"
                                      onClick={() => {
                                        if (
                                          trailer?.mt_link?.includes("youtube")
                                        ) {
                                          setVideoChannel("youtube");
                                          setCurrentVideoId(
                                            trailer?.mt_link?.split("=")[1]
                                          );
                                        }
                                        if (
                                          trailer?.mt_link?.includes("vimeo")
                                        ) {
                                          setVideoChannel("vimeo");
                                          setCurrentVideoId(
                                            trailer?.mt_link?.split("/").pop()
                                          );
                                        }
                                        setOpen(true);
                                      }}
                                    >
                                      <img
                                        src={play}
                                        alt="play"
                                        className="mr-2"
                                      />

                                      {/* <button
                            className="btn-primary"
                            
                          >
                            VIEW DEMO
                          </button> */}
                                      {generateMovieVersionsText(
                                        activeCinema?.region_name,
                                        trailer.iso_3,
                                        trailer.sub_1_iso_3,
                                        trailer.sub_2_iso_3
                                      )}
                                    </p>
                                  </li>
                                ))}
                            </ul>
                          </td>
                        </tr>
                      )}
                    </table>
                  </section>

                  {type != 3 ? (
                    movieSchedules?.isShowScreening &&
                    isCreateShowEnabled && (
                      <>
                        <hr className="divider" />
                        <section className="px-3 mi">
                          <Link
                            to={`/create-show-details/${movie_id}/${content_lang_id}`}
                            onClick={() => {
                              const spinner = document.getElementById(
                                "main-spinner"
                              );
                              if (spinner) {
                                spinner.style.display = "flex";
                              } else {
                                spinner.style.display = "none";
                              }
                            }}
                            className="w-100"
                          >
                            <button
                              className="btn-main mx-auto md w-100"
                              onMouseOver={() => setPeopleSvg(false)}
                              onMouseOut={() => setPeopleSvg(true)}
                            >
                              {" "}
                              <img
                                src={peopleSvg ? people : activePeople}
                                alt="people"
                                className="mr-2"
                                // onClick={() =>
                                //   history.push(

                                //   )
                                // }
                              />{" "}
                              {t("Your own Screening")}
                            </button>
                          </Link>
                        </section>

                        <section className="px-3 md">
                          <p className="available-movie-versions">
                            {t(`available-movie-versions-for-on-demand`)}{" "}
                            {`${
                              selectedCinema?.cinema_name
                                ? selectedCinema?.cinema_name
                                : currentCinema?.cinema_name
                            }`}
                          </p>
                          <p className="audio-sub">
                            {getMovieVersionsText(
                              movieDetails?.movie_versions,
                              activeCinema?.region_name
                            ).map((item) => (
                              <span>{item}</span>
                            ))}
                          </p>
                        </section>
                      </>
                    )
                  ) : (
                    <>
                      <hr className="divider" />
                      <section className="px-3 mi">
                        <button
                          className="btn-main mx-auto md w-100"
                          onMouseOver={() => setPeopleSvg(false)}
                          onMouseOut={() => setPeopleSvg(true)}
                          onClick={handleAddUserToSegment}
                        >
                          {" "}
                          <img
                            src={peopleSvg ? people : activePeople}
                            alt="people"
                            className="mr-2"
                            // onClick={() =>
                            //   history.push(

                            //   )
                            // }
                          />{" "}
                          <small>
                            {t("Inform me when the tickets are available")}
                          </small>
                        </button>
                      </section>

                      <section className="px-3 md">
                        <p className="no-result text-left">
                          {t("From") +
                            " " +
                            moment(
                              movieDetails?.original_mrrdr_release_date ===
                                `Invalid Date`
                                ? moment()
                                : movieDetails?.original_mrrdr_release_date ||
                                    moment()
                            ).format("Do MMM, YYYY") +
                            " " +
                            t("in") +
                            " " +
                            currentCinema.cinema_name}
                        </p>
                      </section>
                    </>
                  )}
                </>
                {false && (
                  <>
                    {/* col-6 col-sm-4 col-md-3 col-xl-3 mb-4 mb-xl-0 */}
                    <div className="px-3 mi">
                      <div className="img-card">
                        <img
                          src={
                            getLangSpecificAttribute(
                              movieDetails?.languageSpecificImages,
                              lang_id,
                              "artwork"
                            ) || dummyPoster
                          }
                          alt=""
                          className=""
                        />
                      </div>
                    </div>
                    {/* col-md-9 */}
                    <div className="px-3 md">
                      <div className="row">
                        <div className="col-xl-6 mb-4 mb-md-0">
                          <article className="d-flex flex-column h-100 justify-content-between align-items-start">
                            {getLangSpecificAttribute(
                              movieDetails?.altMovieContent,
                              lang_id,
                              "mc_title"
                            ) && (
                              <p className="movie-name">
                                {getLangSpecificAttribute(
                                  movieDetails?.altMovieContent,
                                  lang_id,
                                  "mc_title"
                                )}
                              </p>
                            )}
                            <div className="movie-features">
                              <div className="">
                                <img src={calendar} alt={t("alt.calendar")} />
                                <span>
                                  {moment(
                                    movieDetails?.original_mrrdr_release_date ===
                                      `Invalid Date`
                                      ? moment()
                                      : movieDetails?.original_mrrdr_release_date ||
                                          moment()
                                  ).format("Do MMM, YYYY")}
                                </span>
                              </div>
                              <div className="">
                                <img src={eye} alt={t("alt.calendar")} />
                                <span>{movieDetails?.rating}</span>
                              </div>
                              <div className="">
                                <img src={clock} alt={t("alt.calendar")} />
                                <span>
                                  {movieDetails?.mrrdr_runtime}{" "}
                                  {t("common.mins")}
                                </span>
                              </div>
                              {/* <div className="">
                            <img src={format} alt={t("alt.calendar")} />
                            <span>{movieDetails?.mf_name}</span>
                          </div> */}
                            </div>
                            <div className="movie-format">
                              {/* <img
                            src={format}
                            className="mr-2"
                            alt={t("alt.calendar")}
                          /> */}
                              <span>{movieDetails?.mf_name}</span>
                            </div>
                            {/* className="tags" */}
                            <div className="md-genres">
                              {movieDetails?.genres_with_translations
                                ?.filter((genre) => genre[iso_2.toLowerCase()])
                                ?.map(
                                  (genre, index) =>
                                    genre[iso_2.toLowerCase()] &&
                                    index < 4 && (
                                      <span key={index}>
                                        {genre[iso_2.toLowerCase()]}
                                      </span>
                                    )
                                )}
                              {/* <span>Children</span> */}
                              {/* <span>{movieDetails?.mf_name}</span> */}
                            </div>
                            {/* <p */}
                            {/* className="text-limit d-none d-xl-block" */}
                            {/* id="movieDesc" */}
                            {/* > */}
                            {/* {movieDetails?.mc_plot} */}
                            {/* {getLanguageSpecificMovieDetails?.mc_plot &&
                          toggleRM(
                            "movieDesc",
                            getLanguageSpecificMovieDetails?.mc_plot,
                            100
                          )} */}
                            {/* </p> */}
                          </article>
                        </div>
                        <div className="col-xl-6 movie-details">
                          <table>
                            {hasStaring(movieDetails?.castCrewDetails) && (
                              <tr>
                                <td>
                                  <p className="detail-title">
                                    {t("Starring")}
                                  </p>
                                </td>
                                <td>
                                  {/* <p> */}
                                  {movieDetails?.castCrewDetails?.map(
                                    (cast, index) =>
                                      cast?.crew_title !== `Director` && (
                                        // <div key={index}>
                                        <p key={index}>
                                          {/* {cast.crew_title} -{" "} */}
                                          {cast?.Crew?.map((crew, index2) => (
                                            <span
                                              key={index2}
                                              // style={{ display: "inline-block" }}
                                              className="text-break"
                                            >
                                              {/* <p> */}
                                              {"  "}
                                              {crew.mcc_cast_name}
                                              {index2 !== cast.Crew.length - 1
                                                ? `, `
                                                : ""}
                                              &nbsp;
                                              {/* </p> */}
                                            </span>
                                          ))}
                                        </p>
                                        // {/* </div> */}
                                      )
                                  )}
                                  {/* </p> */}
                                </td>
                              </tr>
                            )}
                            {hasDirector(movieDetails?.castCrewDetails) && (
                              <tr>
                                <td>
                                  <p className="detail-title">
                                    {t("Director")}
                                  </p>
                                </td>
                                <td>
                                  {/* <p> */}
                                  {movieDetails?.castCrewDetails?.map(
                                    (cast, index) =>
                                      cast?.crew_title === `Director` && (
                                        <p key={index}>
                                          {/* {cast.crew_title} -{" "} */}
                                          {cast?.Crew?.map((crew, index2) => (
                                            <span
                                              key={index2}
                                              // style={{ display: "inline-block" }}
                                              className="text-break"
                                            >
                                              {/* <p> */}
                                              {"  "}
                                              {crew.mcc_cast_name}
                                              {index2 !== cast.Crew.length - 1
                                                ? `, `
                                                : ""}
                                              &nbsp;
                                              {/* </p> */}
                                            </span>
                                          ))}
                                        </p>
                                      )
                                  )}
                                  {/* </p> */}
                                </td>
                              </tr>
                            )}
                            {movieDetails?.languages_with_translations?.length >
                              0 && (
                              <tr>
                                <td>
                                  <p className="detail-title">
                                    {t("common.Languages")}
                                  </p>
                                </td>
                                <td>
                                  <ul className="list-inline mb-0">
                                    <ModalVideo
                                      autoplay
                                      channel={videoChannel}
                                      isOpen={isOpen}
                                      videoId={currentVideoId}
                                      onClose={() => setOpen(false)}
                                    />
                                    {movieDetails?.languages_with_translations
                                      ?.sort(sortAlphabetically(`lang_name`))
                                      ?.map((lang, index) => (
                                        <li
                                          className="list-inline-item lang-coma"
                                          key={index}
                                        >
                                          <p className="mb-2">
                                            {lang[iso_2.toLowerCase()]}
                                          </p>
                                        </li>
                                      ))}
                                  </ul>
                                </td>
                              </tr>
                            )}
                            {movieDetails?.movieSubtitles?.[0]?.lang_name && (
                              <tr>
                                <td>
                                  <p className="detail-title">
                                    {t("Subtitles")}
                                  </p>
                                </td>
                                <td>
                                  <ul className="list-inline mb-0">
                                    {/* <ModalVideo
                                  autoplay
                                  channel={videoChannel}
                                  isOpen={isOpen}
                                  videoId={currentVideoId}
                                  onClose={() => setOpen(false)}
                                /> */}
                                    {movieDetails?.movieSubtitles
                                      ?.sort(sortAlphabetically(`lang_name`))
                                      ?.map(
                                        (lang, index) =>
                                          (lang?.lang_name ||
                                            lang[iso_2.toLowerCase()]) && (
                                            <li
                                              className="list-inline-item lang-coma"
                                              key={index}
                                            >
                                              <p className="mb-2">
                                                {lang[iso_2.toLowerCase()]
                                                  ? lang[iso_2.toLowerCase()]
                                                  : lang?.lang_name}
                                              </p>
                                            </li>
                                          )
                                      )}
                                  </ul>
                                </td>
                              </tr>
                            )}
                            {movieDetails?.trailer_array?.length > 0 && (
                              <tr>
                                <td>
                                  <p className="detail-title mt-2">
                                    {t("common.Trailers")}
                                  </p>
                                </td>
                                <td>
                                  <ul className="list-inline mb-0">
                                    {movieDetails?.trailer_array
                                      ?.sort(sortAlphabetically(`lang_name`))
                                      ?.map((trailer, index) => (
                                        // trailer?.mt_link
                                        <li
                                          className="list-inline-item"
                                          key={index}
                                        >
                                          <p className="mr-3 play-trailer mb-2">
                                            <img
                                              src={play}
                                              alt="play"
                                              onClick={() => {
                                                if (
                                                  trailer?.mt_link?.includes(
                                                    "youtube"
                                                  )
                                                ) {
                                                  setVideoChannel("youtube");
                                                  setCurrentVideoId(
                                                    trailer?.mt_link?.split(
                                                      "="
                                                    )[1]
                                                  );
                                                }
                                                if (
                                                  trailer?.mt_link?.includes(
                                                    "vimeo"
                                                  )
                                                ) {
                                                  setVideoChannel("vimeo");
                                                  setCurrentVideoId(
                                                    trailer?.mt_link
                                                      ?.split("/")
                                                      .pop()
                                                  );
                                                }
                                                setOpen(true);
                                              }}
                                              className="mr-2"
                                            />

                                            {/* <button
                            className="btn-primary"
                            
                          >
                            VIEW DEMO
                          </button> */}
                                            {generateMovieVersionsText(
                                              activeCinema?.region_name,
                                              trailer.iso_3,
                                              trailer.sub_1_iso_3,
                                              trailer.sub_2_iso_3
                                            )}
                                            {/* {trailer?.lang_name
                                              .slice(0, 2)
                                              .toUpperCase()} */}
                                          </p>
                                        </li>
                                      ))}
                                  </ul>
                                </td>
                              </tr>
                            )}
                          </table>
                          {/* <div className="row mb-3">
                        <div className="col-4 pr-0">
                          <p className="detail-title">{t("common.Stars")}</p>
                        </div>
                        <div className="col-8 ml-n1">
                          <p>
                            {movieDetails?.castCrewDetails?.map(
                              (cast, index) => (
                                <div key={index}>
                                  {cast.crew_title} -{" "}
                                  {cast?.Crew?.map((crew, index2) => (
                                    <div
                                      key={index2}
                                      style={{ display: "inline-block" }}
                                    >
                                      <p>
                                        {"  "}
                                        {crew.mcc_cast_name}
                                        {index2 !== cast.Crew.length - 1
                                          ? `, `
                                          : ""}
                                        &nbsp;
                                      </p>
                                    </div>
                                  ))}
                                </div>
                              )
                            )}
                          </p>
                        </div>
                      </div> */}
                          {/* <div className="row mb-2">
                        <div className="col-4">
                          <p className="detail-title">
                            {t("common.Languages")}
                          </p>
                        </div>
                        <div className="col-8">
                          <ul className="list-inline mb-0">
                            <ModalVideo
                              autoplay
                              channel={videoChannel}
                              isOpen={isOpen}
                              videoId={currentVideoId}
                              onClose={() => setOpen(false)}
                            />
                            {movieDetails?.languages_with_translations?.map(
                              (lang) => (
                                <li className="list-inline-item lang-coma">
                                  <p className="mb-2">
                                    {lang[iso_2.toLowerCase()]}
                                  </p>
                                </li>
                              )
                            )}
                          </ul>
                        </div>
                      </div> */}
                          {/* <div className="row mb-2">
                        <div className="col-4">
                          <p className="detail-title">{t("common.Trailers")}</p>
                        </div>
                        <div className="col-8">
                          <ul className="list-inline mb-0">
                            {movieDetails?.trailer_array?.map((trailer) => (
                              // trailer?.mt_link
                              <li className="list-inline-item">
                                <p className="mr-3 play-trailer mb-2">
                                  <img
                                    src={play}
                                    alt="play"
                                    onClick={() => {
                                      if (
                                        trailer?.mt_link?.includes("youtube")
                                      ) {
                                        setVideoChannel("youtube");
                                        setCurrentVideoId(
                                          trailer?.mt_link?.split("=")[1]
                                        );
                                      }
                                      if (trailer?.mt_link?.includes("vimeo")) {
                                        setVideoChannel("vimeo");
                                        setCurrentVideoId(
                                          trailer?.mt_link?.split("/").pop()
                                        );
                                      }
                                      setOpen(true);
                                    }}
                                    className="mr-2"
                                  />

                                  /* <button
                            className="btn-primary"
                            
                          >
                            VIEW DEMO
                          </button>}
                                  {trailer?.lang_name.slice(0, 2).toUpperCase()}
                                </p>
                              </li>
                            ))}
                          </ul>
                        </div>
                      </div> */}
                          {/* <p className="text-limit" id="movieDesc"> */}
                          {/* {movieDetails?.mc_plot} */}
                          {/* {getLanguageSpecificMovieDetails?.mc_plot &&
                          toggleRM(
                            "movieDesc",
                            getLanguageSpecificMovieDetails?.mc_plot,
                            100
                          )} */}
                          {/* </p> */}
                        </div>
                      </div>
                      <p className="text-limit" id="movieDesc">
                        {getLangSpecificAttribute(
                          movieDetails?.altMovieContent,
                          lang_id,
                          "mc_plot"
                        ) || movieDetails?.mc_plot}

                        {/* {getLanguageSpecificMovieDetails?.mc_plot &&
                          toggleRM(
                            "movieDesc",
                            getLanguageSpecificMovieDetails?.mc_plot,
                            100
                          )} */}
                      </p>
                      {type != 3 &&
                        movieSchedules?.isShowScreening &&
                        isCreateShowEnabled && (
                          <div className="row mt-3">
                            <div className="px-3 d-flex">
                              <Link
                                to={`/create-show-details/${movie_id}/${content_lang_id}`}
                                onClick={() => {
                                  const spinner = document.getElementById(
                                    "main-spinner"
                                  );
                                  if (spinner) {
                                    spinner.style.display = "flex";
                                  } else {
                                    spinner.style.display = "none";
                                  }
                                }}
                              >
                                <button
                                  className="btn-main mr-3 md"
                                  onMouseOver={() => setPeopleSvg(false)}
                                  onMouseOut={() => setPeopleSvg(true)}
                                >
                                  {" "}
                                  <img
                                    src={peopleSvg ? people : activePeople}
                                    alt="people"
                                    className="mr-2"
                                    // onClick={() =>
                                    //   history.push(

                                    //   )
                                    // }
                                  />{" "}
                                  {t("Your own Screening")}
                                </button>
                              </Link>
                              {/* <Link
                          to={`/create-show-details/${movie_id}/${content_lang_id}/1`}
                        >
                          <button
                            className="btn-main md"
                            onMouseOver={() => setLoginSvg(false)}
                            onMouseOut={() => setLoginSvg(true)}
                          >
                            <img
                              src={loginSvg ? login : activeLogin}
                              alt="people"
                              className="mr-2"
                            />
                            {t("Private Screening")}
                          </button>
                        </Link> */}
                            </div>
                          </div>
                        )}
                    </div>
                    {/* <aside
                  className="share-movie"
                  onClick={() => setShareModal(true)}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="#e70d00"
                    class="bi bi-share-fill"
                    viewBox="0 0 16 16"
                  >
                    <path d="M11 2.5a2.5 2.5 0 1 1 .603 1.628l-6.718 3.12a2.499 2.499 0 0 1 0 1.504l6.718 3.12a2.5 2.5 0 1 1-.488.876l-6.718-3.12a2.5 2.5 0 1 1 0-3.256l6.718-3.12A2.5 2.5 0 0 1 11 2.5z" />
                  </svg>
                  <span className="pl-2">Share</span>
                </aside> */}
                  </>
                )}
              </>
            ) : (
              /* Mobile specific starts */
              <>
                <div className="col-12">
                  <section className="row align-items-start mb-3">
                    <article className="col-5 pr-2">
                      <figure className="mb-0 img-card">
                        <img
                          src={
                            getLangSpecificAttribute(
                              movieDetails?.languageSpecificImages,
                              lang_id,
                              "artwork"
                            ) || dummyPoster
                          }
                          alt=""
                          className=""
                        />
                      </figure>
                    </article>
                    <article className="col-7 pl-2">
                      {getLangSpecificAttribute(
                        movieDetails?.altMovieContent,
                        lang_id,
                        "mc_title"
                      ) && (
                        <p className="movie-name">
                          {getLangSpecificAttribute(
                            movieDetails?.altMovieContent,
                            lang_id,
                            "mc_title"
                          )}
                        </p>
                      )}
                      <p className="mb-2">{movieDetails?.mf_name}</p>
                      <div className="mb-2 d-flex align-items-center">
                        <img src={calendar} alt="calendar" className="mr-2" />
                        <span>
                          {moment(
                            movieDetails?.original_mrrdr_release_date ===
                              `Invalid Date`
                              ? moment()
                              : movieDetails?.original_mrrdr_release_date ||
                                  moment()
                          ).format("Do MMM, YYYY")}
                        </span>
                      </div>
                      <section className="d-flex align-items-center">
                        <div className="d-flex align-items-center">
                          <img src={clock} alt="calendar" className="mr-2" />
                          <span>
                            {movieDetails?.mrrdr_runtime} {t("common.mins")}
                          </span>
                        </div>
                        <div className="ml-3 d-flex align-items-center">
                          <img src={eye} alt="calendar" className="mr-2" />
                          <span>{movieDetails?.rating}</span>
                        </div>
                      </section>
                    </article>
                  </section>

                  <ModalVideo
                    autoplay
                    channel={videoChannel}
                    isOpen={isOpen}
                    videoId={currentVideoId}
                    onClose={() => setOpen(false)}
                  />
                  <div className="mb-2">
                    <ul className="list-inline mx-auto text-center mb-0">
                      {movieDetails?.trailer_array
                        ?.sort(sortAlphabetically(`lang_name`))
                        ?.map((trailer, index) => (
                          <li className="list-inline-item" key={index}>
                            <p className="mr-3 play-trailer">
                              <img
                                src={play}
                                alt="play"
                                onClick={() => {
                                  if (trailer?.mt_link?.includes("youtube")) {
                                    setVideoChannel("youtube");
                                    setCurrentVideoId(
                                      trailer?.mt_link?.split("=")[1]
                                    );
                                  }
                                  if (trailer?.mt_link?.includes("vimeo")) {
                                    setVideoChannel("vimeo");
                                    setCurrentVideoId(
                                      trailer?.mt_link?.split("/").pop()
                                    );
                                  }
                                  setOpen(true);
                                }}
                                className="mr-2"
                              />
                              {/* <button
                            className="btn-primary"
                            
                          >
                            VIEW DEMO
                          </button> */}
                              {generateMovieVersionsText(
                                activeCinema?.region_name,
                                trailer.iso_3,
                                trailer.sub_1_iso_3,
                                trailer.sub_2_iso_3
                              )}
                              {/* {trailer?.lang_name.slice(0, 2).toUpperCase()} */}
                            </p>
                          </li>
                        ))}
                    </ul>
                  </div>

                  {/* <div className="movie-features justify-content-center">
                    <div className="">
                      <img src={calendar} alt="calendar" />
                      <span>
                        {moment(
                          movieDetails?.original_mrrdr_release_date ===
                            `Invalid Date`
                            ? moment()
                            : movieDetails?.original_mrrdr_release_date ||
                                moment()
                        ).format("YYYY")}
                      </span>
                    </div>
                    <div className="">
                      <img src={eye} alt="calendar" />
                      <span>{movieDetails?.rating}</span>
                    </div>
                    <div className="">
                      <img src={clock} alt="calendar" />
                      <span>
                        {movieDetails?.mrrdr_runtime} {t("common.mins")}
                      </span>
                    </div>
                  </div> */}

                  <div className="mb-2 text-center">
                    {/* <img src={format} alt="calendar" /> */}
                  </div>
                  <div className="row mb-2 movie-details">
                    <div className="col-12">
                      <table>
                        {movieDetails?.lang_name && (
                          <tr>
                            <td>{t("Original Language")}</td>
                            <td>{movieDetails?.lang_name}</td>
                          </tr>
                        )}

                        <tr>
                          <td>
                            <p className="detail-title">{t("Director")}</p>
                          </td>
                          <td>
                            <p>
                              {movieDetails?.castCrewDetails?.map(
                                (cast, index) =>
                                  cast?.crew_title === `Director` && (
                                    <div key={index}>
                                      {/* {cast.crew_title} -{" "} */}
                                      {cast?.Crew?.map((crew, index2) => (
                                        <div
                                          key={index2}
                                          style={{
                                            display: "inline-block",
                                          }}
                                        >
                                          <p>
                                            {"  "}
                                            {crew.mcc_cast_name}
                                            {index2 !== cast.Crew.length - 1
                                              ? `, `
                                              : ""}
                                            &nbsp;
                                          </p>
                                        </div>
                                      ))}
                                    </div>
                                  )
                              )}
                            </p>
                          </td>
                        </tr>

                        <tr>
                          <td>
                            <p className="detail-title">{t("Starring")}</p>
                          </td>
                          <td>
                            <p>
                              {movieDetails?.castCrewDetails?.map(
                                (cast, index) =>
                                  cast?.crew_title !== `Director` && (
                                    <div key={index}>
                                      {/* {cast.crew_title} -{" "} */}
                                      {cast?.Crew?.map((crew, index2) => (
                                        <div
                                          key={index2}
                                          style={{
                                            display: "inline-block",
                                          }}
                                        >
                                          <p>
                                            {"  "}
                                            {crew.mcc_cast_name}
                                            {index2 !== cast.Crew.length - 1
                                              ? `, `
                                              : ""}
                                            &nbsp;
                                          </p>
                                        </div>
                                      ))}
                                    </div>
                                  )
                              )}
                            </p>
                          </td>
                        </tr>

                        {/* {movieDetails?.movieSubtitles?.[0]?.lang_name && (
                          <tr>
                            <td>
                              <p className="detail-title">{t("Subtitles")}</p>
                            </td>
                            <td>
                              <ul className="list-inline mb-0">
                               
                                {movieDetails?.movieSubtitles
                                  ?.sort(sortAlphabetically(`lang_name`))
                                  ?.map(
                                    (lang, index) =>
                                      lang?.lang_name && (
                                        <li
                                          className="list-inline-item lang-coma"
                                          key={index}
                                        >
                                          <p className="mb-2">
                                            {lang[iso_2.toLowerCase()]}
                                          </p>
                                        </li>
                                      )
                                  )}
                              </ul>
                            </td>
                          </tr>
                        )} */}
                      </table>
                    </div>
                  </div>

                  <section className="mb-2">
                    <p className="available-movie-versions text-center">
                      {t(`available-movie-versions-for-on-demand`)}{" "}
                      {`${
                        selectedCinema?.cinema_name
                          ? selectedCinema?.cinema_name
                          : currentCinema?.cinema_name
                      }`}
                    </p>
                    <p className="audio-sub">
                      {getMovieVersionsText(
                        movieDetails?.movie_versions,
                        activeCinema?.region_name
                      ).map((item) => (
                        <span>{item}</span>
                      ))}
                    </p>
                  </section>

                  {/* defaultActiveKey="0" */}
                  <Accordion>
                    <Card>
                      <Accordion.Toggle
                        as={Card.Header}
                        eventKey="0"
                        ref={accordion}
                        onClick={() => {
                          arrowRotate();
                          // showMovieSection();
                        }}
                        className="hide"
                      >
                        {t("More details")}
                      </Accordion.Toggle>
                      <Accordion.Collapse eventKey="0">
                        <Card.Body>
                          {/* className="tags justify-content-center" */}
                          <div className="md-genres text-center">
                            {movieDetails?.genres_with_translations
                              ?.filter((genre) => genre[iso_2.toLowerCase()])
                              ?.map(
                                (genre, index) =>
                                  genre[iso_2.toLowerCase()] &&
                                  index < 4 && (
                                    <span key={index}>
                                      {genre[iso_2.toLowerCase()]}
                                    </span>
                                  )
                              )}
                            {/* <span>Children</span> */}
                            {/* <span>{movieDetails?.mf_name}</span> */}
                          </div>
                          <p className="text-limit text-center">
                            {getLangSpecificAttribute(
                              movieDetails?.altMovieContent,
                              lang_id,
                              "mc_plot"
                            )}
                          </p>
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                  </Accordion>

                  <div className="img-card mb-2 text-center position-relative col-8 col-sm-8 px-0 mx-auto">
                    {/* <aside
                      className="share-movie mobile"
                      onClick={() => setShareModal(true)}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="#e70d00"
                        class="bi bi-share-fill"
                        viewBox="0 0 16 16"
                      >
                        <path d="M11 2.5a2.5 2.5 0 1 1 .603 1.628l-6.718 3.12a2.499 2.499 0 0 1 0 1.504l6.718 3.12a2.5 2.5 0 1 1-.488.876l-6.718-3.12a2.5 2.5 0 1 1 0-3.256l6.718-3.12A2.5 2.5 0 0 1 11 2.5z" />
                      </svg>
                      <span className="pl-2">Share</span>
                    </aside> */}
                  </div>

                  <div
                    className={`row mt-2 position-relative ${
                      dateMovieScheduleDetails?.dateList?.length > 0
                        ? "justify-content-evenly"
                        : "justify-content-center"
                    } align-items-center`}
                  >
                    <div className="pl-3 pr-2 d-flex">
                      {type != 3 ? (
                        movieSchedules?.isShowScreening &&
                        isCreateShowEnabled && (
                          <Link
                            to={`/create-show-details/${movie_id}/${content_lang_id}`}
                            onClick={() => {
                              const spinner = document.getElementById(
                                "main-spinner"
                              );
                              if (spinner) {
                                spinner.style.display = "flex";
                              } else {
                                spinner.style.display = "none";
                              }
                            }}
                          >
                            <button
                              className="btn-main md"
                              // onMouseOver={() => setPeopleSvg(false)}
                              // onMouseOut={() => setPeopleSvg(true)}
                            >
                              {" "}
                              <img
                                src={peopleSvg ? people : activePeople}
                                alt="people"
                                className="mr-2"
                                // onClick={() =>
                                //   history.push(

                                //   )
                                // }
                              />{" "}
                              {t("Your own Screening")}
                            </button>
                          </Link>
                        )
                      ) : (
                        <button
                          className="btn-main md"
                          // onMouseOver={() => setPeopleSvg(false)}
                          // onMouseOut={() => setPeopleSvg(true)}
                        >
                          {" "}
                          <img
                            src={peopleSvg ? people : activePeople}
                            alt="people"
                            className="mr-2"
                            // onClick={() =>
                            //   history.push(

                            //   )
                            // }
                          />{" "}
                          {t("Inform me when the tickets are available")}
                        </button>
                      )}
                      {/* <Link
                          to={`/create-show-details/${movie_id}/${content_lang_id}/1`}
                        >
                          <button
                            className="btn-main md"
                            onMouseOver={() => setLoginSvg(false)}
                            onMouseOut={() => setLoginSvg(true)}
                          >
                            <img
                              src={loginSvg ? login : activeLogin}
                              alt="people"
                              className="mr-2"
                            />
                            {t("Private Screening")}
                          </button>
                        </Link> */}
                    </div>
                    {type != 3 &&
                      dateMovieScheduleDetails?.dateList?.length > 0 && (
                        <div className="pr-3 pl-2 config">
                          <div
                            style={{ zIndex: 993, height: 0 }}
                            className={`${
                              !isMobile ? "position-relative" : ""
                            }`}
                            onClick={(e) => {
                              e.stopPropagation();
                            }}
                          >
                            {calendarPopup && (
                              // <DatePicker
                              //   formatShortWeekday={(locale, date) =>
                              //     moment(date)
                              //       .locale(locale)
                              //       .format("dd")
                              //       .substr(0, 1)
                              //   }
                              //   minDate={new Date()}
                              //   locale={iso_2?.toLowerCase()}
                              //   onChange={(value) => {
                              //     setCalenderDateSelected(true);
                              //     onChange(value);
                              //     onChangeDate({
                              //       ss_actual_start_date: moment(value).format(
                              //         "YYYY-MM-DD"
                              //       ),
                              //     });
                              //   }}
                              //   value={value}
                              //   className="date-picker-popup"
                              //   isOpen={calendarPopup}
                              //   onCalendarClose={() => setCalendarPopup(false)}
                              //   // locale={iso_2.toLowerCase()}
                              // />

                              <div className="new-date-picker new-date-picker-mobile">
                                <DayPicker
                                  selectedDays={value}
                                  disabledDays={{
                                    before: new Date(),
                                  }}
                                  onDayClick={(date, modifiers) => {
                                    if (modifiers && modifiers.disabled) return;
                                    setCalendarPopup(false);
                                    setCalenderDateSelected(true);
                                    onChange(date);
                                    onChangeDate({
                                      ss_actual_start_date: moment(date).format(
                                        "YYYY-MM-DD"
                                      ),
                                    });
                                  }}
                                  localeUtils={MomentLocaleUtils}
                                  locale={iso_2.toLowerCase()}
                                />
                              </div>
                            )}
                          </div>
                          <img
                            src={calendarWhite}
                            className="md-icon mr-2 mr-md-3"
                            alt=""
                          />
                          <span
                            className="title"
                            onClick={(e) => {
                              calendarPopup
                                ? setCalendarPopup(false)
                                : setCalendarPopup(true);
                              e.stopPropagation();
                            }}
                          >
                            {false
                              ? t("Choose Date")
                              : moment(selectedDate)
                                  .locale(iso_2.toLowerCase())
                                  .format("MMM D")}
                          </span>
                        </div>
                      )}
                  </div>
                </div>
              </> /* Mobile specific ends */
            )}
          </div>
        </div>
      ) : stage === 1 ? (
        <div className="container-fluid container-xl header-space">
          <p className="mx-auto big-heading text-center">
            {t("Select Time of your Movie")}
          </p>
          <section className="row justify-content-center for-prev-step">
            {stage !== 1 && (
              <div className="prev-step">
                <button
                  className="btn-main mx-auto mt-3 mt-xl-4"
                  onClick={() => setStage(stage - 1)}
                  onMouseOver={() => setBack(true)}
                  onMouseOut={() => setBack(false)}
                >
                  <img
                    src={back ? arrowBackActive : arrowBack}
                    className="mr3"
                    alt=""
                  />
                  {/* Back */}
                </button>
              </div>
            )}

            <div className="col-sm-9 mx-auto">
              <div className="steps">
                <div
                  className={`step ticket ${
                    stage === 1
                      ? "complete"
                      : stage === 2
                      ? "complete"
                      : stage === 3
                      ? "complete"
                      : ""
                  }`}
                >
                  {/* <img src={ticketRed} /> */}
                </div>
                <div
                  className={`step screen ${
                    stage === 2 ? "complete" : stage === 3 ? "complete" : ""
                  }`}
                >
                  {/* <img src={ticketRed} /> */}
                </div>
                <div className={`step seat ${stage === 3 ? "complete" : ""}`}>
                  {/* <img src={ticketRed} /> */}
                </div>
              </div>
            </div>
            {/* </div> */}
          </section>
        </div>
      ) : (
        stage === 2 &&
        screeningTypes &&
        !screeningTypesLoading && (
          <>
            <div className="container-fluid header-space">
              <p className="mx-auto big-heading text-center">
                {t("Select Screening Type")}
              </p>
              {/* <section className="row align-items-center">
                {screeningTypes.map((screen, index) => (
                  <ScreeningCard
                    cardType="basic"
                    onClick={(type) => onSelectScreeningType(type)}
                    key={index}
                    screen={screen}
                  />
                ))}
              </section> */}
              <ScreeningCardNew
                screeningTypes={screeningTypes}
                isUnconfirmedAvailable={show?.isUnconfirmedAvailable}
                passedFunc={(type) => onSelectScreeningType(type)}
              />
            </div>
          </>
        )
      )}
      {stage === 1 && (
        <>
          <div className="date-picker-section">
            <div className="container-fluid">
              {dateMovieScheduleDetails?.dateList?.length > 0 ? (
                <section className="row date-picker-sect">
                  <div className="col-10 col-md-11 mx-auto mx-xl-0 col-xl-5 mb-0 mb-md-4 mb-xl-0">
                    <Slider {...dateSlider} className="date-slider">
                      {dateMovieScheduleDetails?.dateList?.map(
                        (date, index) =>
                          index < 7 && (
                            <div key={index}>
                              <span
                                className={`date-section ${
                                  date.ss_actual_start_date === selectedDate &&
                                  "selected"
                                }`}
                                onClick={() => onChangeDate(date)}
                              >
                                <p className="month">
                                  {moment(date.ss_actual_start_date)
                                    .locale(iso_2.toLowerCase())
                                    .format("MMM")}
                                </p>
                                <p className="date">{date.day}</p>
                                <p className="day">
                                  {moment(date.ss_actual_start_date)
                                    .locale(iso_2.toLowerCase())
                                    .format("dddd")}
                                </p>
                              </span>
                            </div>
                          )
                      )}
                    </Slider>
                  </div>
                  {!isMobile && (
                    <div className="col-xl-7">
                      <div className="d-flex justify-content-between align-items-center h-100">
                        <div className="config border-right">
                          <span
                            className="actual-text position-relative"
                            style={{ zIndex: "993" }}
                          >
                            {calendarPopup && (
                              // <DatePicker
                              //   formatShortWeekday={(locale, date) =>
                              //     moment(date)
                              //       .locale(locale)
                              //       .format("dd")
                              //       .substr(0, 1)
                              //   }
                              //   minDate={new Date()}
                              //   locale={iso_2?.toLowerCase()}
                              //   onChange={(value) => {
                              //     setCalenderDateSelected(true);
                              //     onChange(value);
                              //     onChangeDate({
                              //       ss_actual_start_date: moment(value).format(
                              //         "YYYY-MM-DD"
                              //       ),
                              //     });
                              //   }}
                              //   value={value}
                              //   className="date-picker-popup"
                              //   isOpen={calendarPopup}
                              //   // onCalendarClose={() => setCalendarPopup(false)}
                              //   // locale={iso_2.toLowerCase()}
                              // />
                              <span
                                className="new-date-picker"
                                onClick={(e) => {
                                  e.preventDefault();
                                  e.stopPropagation();
                                }}
                              >
                                <DayPicker
                                  selectedDays={value}
                                  disabledDays={{
                                    before: new Date(),
                                  }}
                                  onDayClick={(date, modifiers) => {
                                    if (modifiers && modifiers.disabled) return;
                                    setCalendarPopup(false);
                                    setCalenderDateSelected(true);
                                    onChange(date);
                                    onChangeDate({
                                      ss_actual_start_date: moment(date).format(
                                        "YYYY-MM-DD"
                                      ),
                                    });
                                  }}
                                  localeUtils={MomentLocaleUtils}
                                  locale={iso_2.toLowerCase()}
                                />
                              </span>
                            )}
                            <img
                              src={calendarWhite}
                              className="md-icon mr-2 mr-md-3"
                              alt=""
                            />
                            <span
                              className="title"
                              onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();

                                calendarPopup
                                  ? setCalendarPopup(false)
                                  : setCalendarPopup(true);
                              }}
                            >
                              {false
                                ? t("Choose Date")
                                : moment(selectedDate)
                                    .locale(iso_2.toLowerCase())
                                    .format("ll")}
                            </span>
                          </span>
                        </div>

                        <div className="config border-right">
                          <div className="dropdown-bg">
                            <Dropdown className="multiselect">
                              <Dropdown.Toggle
                                className="dropdownClassic"
                                id="dropdown-basic"
                              >
                                <span className="title">
                                  {selectedCinema
                                    ? selectedCinema.cinema_name
                                    : t("home_page.select-cinema")}
                                </span>
                              </Dropdown.Toggle>

                              <Dropdown.Menu
                                align="right"
                                className="dropdownItems"
                              >
                                {dateMovieScheduleDetails?.CinemaArray?.map(
                                  (cinema, index) => (
                                    <Dropdown.Item
                                      key={index}
                                      className={
                                        selectedCinema?.cinema_id ===
                                          cinema?.cinema_id && "selected"
                                      }
                                      onClick={() => onChangeCinema(cinema)}
                                    >
                                      {cinema.cinema_name}
                                    </Dropdown.Item>
                                  )
                                )}
                              </Dropdown.Menu>
                            </Dropdown>
                          </div>
                        </div>

                        <div className="config d-none d-md-flex">
                          {/*<div className="dropdown-bg">
                      <Dropdown className="multiselect">
                        <Dropdown.Toggle
                          className="dropdownClassic"
                          id="dropdown-basic"
                        >
                          <span className="title">English 2D</span>
                        </Dropdown.Toggle>

                        <Dropdown.Menu align="right" className="dropdownItems">
                          <Dropdown.Item href="#/action-1" className="">
                            ENGLISH
                          </Dropdown.Item>
                          <Dropdown.Item href="#/action-2" className="">
                            SANSKRIT
                          </Dropdown.Item>
                          <Dropdown.Item href="#/action-3">ARAB</Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>*/}
                          <span
                            className="title show-details-trigger"
                            onClick={() => setModalShow(true)}
                          >
                            {selectedLanguage
                              ? selectedLanguage?.lang_name === "All"
                                ? t("common.Language")
                                : selectedLanguage?.lang_name
                              : t("common.Language")}
                            &nbsp; &amp; &nbsp;
                            {selectedFormat
                              ? selectedLanguage?.lang_name === "All"
                                ? t("common.Format")
                                : selectedFormat.mf_name
                              : t("common.Format")}
                          </span>
                        </div>
                      </div>
                    </div>
                  )}
                </section>
              ) : (
                movieSchedules?.MovieListArray?.length > 0 && (
                  <h5 className="py-2 text-center">
                    {t("No show dates found")}
                  </h5>
                )
              )}
            </div>
          </div>
          {/* Markings */}
          <div
            className={`container-fluid ${
              category_id == 1 ? "movie-footer-space" : ""
            }`}
          >
            {
              // category_id == 2 &&
              false &&
                !isMobile &&
                // type != 3 &&
                movieSchedules?.MovieListArray?.length > 0 && (
                  <div className="row show-status">
                    <div className="px-3 mx-auto">
                      <ul className="list-inline mb-0 d-flex align-items-center">
                        <li className="list-inline-item">
                          <div className="d-flex align-items-center flex-wrap flex-sm-nowrap">
                            <img
                              src={eyeGreen}
                              alt="green eye"
                              className="mr-3 mb-2 mb-sm-0"
                            />
                            <p>{t("Confirmed Show | minimum seats booked")}</p>
                          </div>
                        </li>
                        <li className="list-inline-item">
                          <div className="d-flex align-items-center flex-wrap flex-sm-nowrap">
                            <img
                              src={sandClock}
                              alt="sand clock"
                              className="mr-3 mb-2 mb-sm-0"
                            />
                            <p>{t("Less than minimum seats booked")}</p>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                )
            }
            {/* Shows */}
            {movieSchedulesLoading ? (
              <div className="row">
                <div className="col-12 text-center">
                  <Spinner />
                </div>
              </div>
            ) : movieSchedules?.MovieListArray?.length === 0 ? (
              type != 3 ? (
                <div className="row">
                  <div className="col-12 text-center  d-flex justify-content-center align-items-center flex-column">
                    {/* <h4>{t("No shows found")}</h4> */}

                    <p className="no-result">{t("your-own-scr-msg-2")}</p>
                    {/* <Link
                    to={`/create-show-details/${movie_id}/${content_lang_id}`}
                    onClick={() => {
                      const spinner = document.getElementById("main-spinner");
                      if (spinner) {
                        spinner.style.display = "flex";
                      } else {
                        spinner.style.display = "none";
                      }
                    }}
                  >
                    <button
                      className="btn-main mr-3 md"
                      onMouseOver={() => setPeopleSvg2(false)}
                      onMouseOut={() => setPeopleSvg2(true)}
                    >
                      {" "}
                      <img
                        src={peopleSvg2 ? people : activePeople}
                        alt="people"
                        className="mr-2"
                        // onClick={() =>
                        //   history.push(

                        //   )
                        // }
                      />{" "}
                      {t("Your own Screening")}
                    </button>
                  </Link> */}
                  </div>
                </div>
              ) : (
                isMobile && (
                  <p className="no-result">
                    {t("From") +
                      " " +
                      moment(
                        movieDetails?.original_mrrdr_release_date ===
                          `Invalid Date`
                          ? moment()
                          : movieDetails?.original_mrrdr_release_date ||
                              moment()
                      ).format("Do MMM, YYYY") +
                      " " +
                      t("in") +
                      " " +
                      currentCinema.cinema_name}
                  </p>
                )
              )
            ) : (
              movieSchedules?.MovieListArrayNew?.[0]?.CinemaList?.map(
                (cinema, index) => (
                  <MovieShow
                    key={cinema.cinema_name + index}
                    theatre={cinema.cinema_name}
                    theatreAddress={`${cinema?.cine_address}`}
                    languages={cinema.format_list}
                    shows={cinema.format_list}
                    privateCallback={(type, item) =>
                      onSelectScreeningType(3, item)
                    }
                    isPrivate={isPrivate}
                    movie_id={movie_id}
                    content_lang_id={content_lang_id}
                    category_id={category_id}
                    genre_id={genre_id}
                    cinemaIndex={index}
                    cinema={cinema}
                    newStatusType={true}
                  />
                )
              )
            )}
          </div>

          {false && category_id == 2 && (
            <>
              {" "}
              <div className="container-fluid mb-5">
                {otherScreeningsMovies?.length > 0 && (
                  <div className="row movie-slider-heading">
                    <div className="px-3">
                      <span className="">{t("Other Screenings")}</span>
                    </div>
                  </div>
                )}
                {/* Movies List 1*/}
                <div className="row">
                  <div className="col-12 px-0">
                    {otherScreeningsMovies?.length > 0 && (
                      <Slider {...movieSlider} className="movie-slider">
                        {otherScreeningsMovies?.map((movie, index) => (
                          <div key={index}>
                            <MovieCard {...movie} key={index} slider />
                          </div>
                        ))}
                      </Slider>
                    )}
                  </div>
                </div>
              </div>
            </>
          )}

          {category_id == 1 && (
            <MovieFooter {...footerObj} />
            // <div className="movie-footer">
            //   <div className="container-fluid">
            //     <section className="row">
            //       <div className="sect">
            //         <div className="col-4">
            //           <div className="movie-img-n-details">
            //             <div className="img-wrapper">
            //               <img src={movieDetails?.languageSpecificImages?.find(lang=>lang.content_lang_id===lang_id)?.artwork || movieDetails?.md_thumbnail_url || dummyPoster} alt="" />
            //             </div>
            //             <div>
            //               <p className="movie-title">
            //                 {movieDetails?.mc_title}
            //               </p>
            //               <p className="movie-details">
            //                 {selectedLanguage?.lang_name}{" "}
            //                 {selectedFormat?.mf_name}
            //               </p>
            //               <p className="movie-details">
            //                 <span>{movieDetails?.genre} | </span>
            //                 <span>{movieDetails?.rating}</span>
            //               </p>
            //             </div>
            //           </div>
            //         </div>
            //         <div className="col-3">
            //           {selectedCinema && (
            //             <>
            //               <p className="movie-field-title">Cinema:</p>
            //               <p className="movie-field">
            //                 {selectedCinema.cinema_name}
            //               </p>
            //             </>
            //           )}
            //           {selectedDate && (
            //             <>
            //               <p className="movie-field-title">
            //                 Date {false && `& Time:`}
            //               </p>
            //               <p className="movie-field">
            //                 {moment(selectedDate).format("ll")}
            //               </p>
            //             </>
            //           )}
            //         </div>
            //         <div className="col-2">
            //       <p className="movie-field-title">Screening type:</p>
            //       <p className="movie-field">Private</p>
            //       <p className="movie-field-title">Show:</p>
            //       <p className="movie-field">Some show</p>
            //     </div>
            //         <div className="col-3">
            //       <p className="movie-field-title">Total amount:</p>
            //       <p className="movie-field-title">
            //         (Amount inclusive of all taxes)
            //       </p>
            //       <p className="total-price">TBD</p>
            //     </div>
            //       </div>
            //     </section>
            //   </div>
            // </div>
          )}
          {/* fixed settings icon for mobile starts */}
          {/* <div
                        className="settings-icon"
                        onClick={() => setModalShow(true)}
                      >
                        <svg
                          width="32"
                          height="32"
                          viewBox="0 0 32 32"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M12.5 0C12.2239 0 12 0.223857 12 0.5V3.627C11.0002 3.95 10.0533 4.39081 9.17575 4.93309L7.16115 2.91853C6.9659 2.72327 6.6493 2.72327 6.45405 2.91853L2.21142 7.16115C2.01616 7.35645 2.01616 7.673 2.21142 7.8683L4.4231 10.08C3.95389 10.9957 3.59014 11.9743 3.34787 13H0.5C0.223857 13 0 13.2239 0 13.5V19.5C0 19.7761 0.223857 20 0.5 20H3.627C3.84184 20.6651 4.10881 21.3066 4.4231 21.92L2.21142 24.1318C2.01616 24.327 2.01616 24.6436 2.21142 24.8389L6.45405 29.0814C6.6493 29.2767 6.9659 29.2767 7.16115 29.0814L9.17575 27.0669C10.0533 27.6092 11.0002 28.05 12 28.373V31.5C12 31.7761 12.2239 32 12.5 32H18.5C18.7761 32 19 31.7761 19 31.5V28.6521C20.0257 28.4098 21.0043 28.0461 21.92 27.5769L24.1318 29.7886C24.327 29.9839 24.6436 29.9839 24.8389 29.7886L29.0814 25.5459C29.2767 25.3507 29.2767 25.0341 29.0814 24.8389L27.0669 22.8242C27.6092 21.9467 28.05 20.9998 28.373 20H31.5C31.7761 20 32 19.7761 32 19.5V13.5C32 13.2239 31.7761 13 31.5 13H28.6521C28.3281 11.6286 27.787 10.341 27.0669 9.17575L29.0814 7.16115C29.2767 6.9659 29.2767 6.6493 29.0814 6.45405L24.8389 2.21142C24.6436 2.01616 24.327 2.01616 24.1318 2.21142L21.92 4.4231C21.0043 3.95389 20.0257 3.59014 19 3.34787V0.5C19 0.223857 18.7761 0 18.5 0H12.5ZM16 24C20.4183 24 24 20.4183 24 16C24 11.5817 20.4183 8 16 8C11.5817 8 8 11.5817 8 16C8 20.4183 11.5817 24 16 24Z"
                            fill="#E70D00"
                          />
                        </svg>
                      </div> */}
          {isMobile && (
            <div className="filter_btn" onClick={() => setModalShow(true)}>
              <img src={filterImg} height="30px" width="30px" alt="" />
            </div>
          )}
          {/* settings icon ends */}
          {/* Book Now */}
          {/* {isMobile && !(
              movieSchedules?.MovieListArray?.length === 0 ||
              movieSchedules?.dateList?.length === 0
            ) && (
            <a
              className="book-now-btn"
              // href="#movieShow"
              onClick={showSectionScroll}
            >
              <button className="btn-main btn-main-lg">Book Now</button>
            </a>
          )} */}
        </>
      )}
      {/* <ShareTicketPopup
        link={`${HOST_URL}/movie-details/${
          movie_id
        }/${content_lang_id}/${type}`}
        link={window.location.href}
        show={shareModal}
        onHide={() => setShareModal(false)}
        sharedObject="Movie"
      /> */}
    </div>
  );
};

export default withNamespaces()(MovieDetails);
