import React, {
  useEffect,
  useState,
  useRef,
  useContext,
  useCallback,
} from "react";
import {
  fetchTodaysScreenings,
  fetchCreateOwnShow,
  fetchComingSoon,
  fetchFormatList,
  fetchLanguageList,
  fetchFestivalList,
  fetchBanners,
  quickBook,
  quickBookMovieList,
  fetchFilters,
  setQbStage,
  setFilter,
} from "../store/homepage/homepageActions";
import { setStage, createShow } from "../store/movies/movieActions";
import { Link } from "react-router-dom";
import search from "@assets/pictures/svgs/search.svg";
import { withNamespaces } from "react-i18next";
import { Dropdown, Modal, Spinner } from "react-bootstrap";
import MovieSection from "./partials/movieSection.jsx";
import MovieCard from "./partials/MovieCard.jsx";
import PnoSlider from "./partials/PnoSlider.jsx";
import LoadMoreButton from "./partials/LoadMoreButton.jsx";
import FestsNEvents from "./partials/festsNEvents.jsx";
import sampleBg from "@assets/pictures/sample-bg.png";
import placeholderBanner from "@assets/pictures/banner-placeholder-new.png";
import poster from "@assets/pictures/poster.png";
import dummyPoster from "@assets/pictures/dummyPoster.png";
import calendar from "@assets/pictures/svgs/calendar.svg";
import eye from "@assets/pictures/svgs/eye.svg";
import clock from "@assets/pictures/svgs/clock.svg";
import globe from "@assets/pictures/svgs/globe.svg";
import format from "@assets/pictures/svgs/format.svg";
import filterImg from "@assets/pictures/svgs/filter-solid.svg";
import reel from "@assets/pictures/svgs/reel.svg";
import clockWhite from "@assets/pictures/svgs/clock-white.svg";
import { findRenderedDOMComponentWithClass } from "react-dom/test-utils";
import debounce from "lodash/debounce";
import { globalConfigContext } from "@context/GlobalConfigContext";
import Slider from "react-slick";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import moment from "moment";
import { motion } from "framer-motion";
import useIsMobile from "../customHooks/useIsMobile";
import useIsDesktop from "../customHooks/useIsDesktop";
import close from "@assets/pictures/svgs/close.svg";
import { readMore, toggleRM } from "../helper/formatting.js";
import { FilterSquareFill } from "react-bootstrap-icons";
import { hasDirector, hasStaring } from "../helper";
import { getLangSpecificAttribute } from "@helper/languages";

const HomeContainer = ({ t }) => {
  // const [foo, setFoo] = useState(Math.random());
  // useEffect(()=> {
  // setFoo(Math.random())
  // }, []);

  const dispatch = useDispatch();
  const history = useHistory();
  const todaysScreeningsMovies = useSelector(
    (state) => state.homepage.todaysScreenings
  );
  const createOwnShowMovies = useSelector(
    (state) => state.homepage.createOwnShow
  );
  const comingSoonMovies = useSelector((state) => state.homepage.comingSoon);
  const todaysScreeningsLastPage = useSelector(
    (state) => state.homepage.todaysScreeningsLastPage
  );
  const createOwnShowLastPage = useSelector(
    (state) => state.homepage.createOwnShowLastPage
  );
  const comingSoonLastPage = useSelector(
    (state) => state.homepage.comingSoonLastPage
  );

  const uniqueTodaysScreeningMovies = {
    ...todaysScreeningsMovies,
    data: [
      ...new Map(
        todaysScreeningsMovies?.data?.map((item) => [item["movie_id"], item])
      ).values(),
    ],
  };
  const uniqueCreateOwnShowMovies = {
    ...createOwnShowMovies,
    data: [
      ...new Map(
        createOwnShowMovies?.data?.map((item) => [item["movie_id"], item])
      ).values(),
    ],
  };
  const uniqueComingSoonMovies = {
    ...comingSoonMovies,
    data: [
      ...new Map(
        comingSoonMovies?.data?.map((item) => [item["movie_id"], item])
      ).values(),
    ],
  };

  const formats = useSelector((state) => state.homepage.formatList);
  const languages = useSelector((state) => state.homepage.languageList);
  const festivals = useSelector((state) => state.homepage.festivalList);
  const banners = useSelector((state) => state.homepage.banners);
  const onSlide = () => setIsClicked(true);
  const heroSlider = {
    pauseOnFocus: true,
    onSwipe: onSlide,
    arrows: true,
    dots: true,
    infinite: true,
    useTransform: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    speed: 2000,
    autoplay: true,
    autoplaySpeed: 5000,
    responsive: [
      {
        breakpoint: 767,
        settings: {
          arrows: false,
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch({ type: "FETCH_TODAYS_SCREENINGS" });
    dispatch({ type: "FETCH_CREATE_OWN_SHOW" });
    dispatch({ type: "FETCH_COMING_SOON" });
  }, []);
  const onSelectFilter = (filter) => {
    dispatch(setFilter(filter));
  };
  const [selectedFilters, setSelectedFilters] = useState({});
  const onSubmitFilter = () => {
    setComingSoonCurrentPage(1);
    setCreateOwnShowCurrentPage(1);
    setTodayScreeningCurrentPage(1);
    dispatch({ type: `FETCH_TODAYS_SCREENINGS` });
    dispatch({ type: `FETCH_CREATE_OWN_SHOW` });
    dispatch({ type: `FETCH_COMING_SOON` });
    // setSelectedFilter(filter);
    setShowFilterModal(false);
    let obj = {};
    filters.map((filter) =>
      filter.typeArray.map((type) => {
        if (type.selected) {
          obj[filter.query_id] = type.type_id;
        }
      })
    );
    setSelectedFilter(obj);
    console.log(
      "🚀 ~ file: mainHomeContainer.jsx ~ line 145 ~ onSubmitFilter ~ obj",
      obj
    );
    dispatch(
      fetchCreateOwnShow({
        limit,
        currentPage: createOwnShowCurrentPage,
        ...obj,
      })
    );
    dispatch(
      fetchTodaysScreenings({
        limit,
        currentPage: todayScreeningCurrentPage,
        ...obj,
      })
    );
    dispatch(
      fetchComingSoon({
        limit,
        currentPage: comingSoonCurrentPage,
        ...obj,
      })
    );
    // if (filter.query_id === "lang_id") {
    //   setSelectedLanguage({ lang_id: filter.type_id });
    // } else {
    //   setSelectedFormat({ mf_id: filter.type_id });
    // }
  };
  const qbStage = useSelector((state) => state.homepage.qbStage);
  // const [qbStage, setQbStage] = useState(0);
  const [selectedFilter, setSelectedFilter] = useState(null);

  const filters = useSelector((state) => state.homepage.filters);
  const [selectedFilterLanguage, setSelectedFilterLanguage] = useState(null);
  const [selectedFilterLanguage2, setSelectedFilterLanguage2] = useState(null);
  const [selectedFilterFormat2, setSelectedFilterFormat2] = useState(null);
  const [selectedFilterFormat, setSelectedFilterFormat] = useState(null);
  const [selectedFormat, setSelectedFormat] = useState(null);
  const [selectedLanguage, setSelectedLanguage] = useState(null);
  const [qbSelectedLanguage, setQbSelectedLanguage] = useState(null);
  const [qbSelectedMovie, setQbSelectedMovie] = useState(null);
  const [qbSelectedFormat, setQbSelectedFormat] = useState(null);
  const [qbSelectedDate, setQbSelectedDate] = useState(null);
  const [qbSelectedTime, setQbSelectedTime] = useState(null);
  const [qbSelectedCinema, setQbSelectedCinema] = useState(null);
  const [limit, setLimit] = useState(6);
  const [todayScreeningCurrentPage, setTodayScreeningCurrentPage] = useState(1);
  const [createOwnShowCurrentPage, setCreateOwnShowCurrentPage] = useState(1);
  const [comingSoonCurrentPage, setComingSoonCurrentPage] = useState(1);
  const todayScreeningLoadMore = ({ callback }) => {
    setTodayScreeningCurrentPage(todayScreeningCurrentPage + 1);
    setTimeout(() => callback(false), 750);
  };
  const createOwnShowLoadMore = ({ callback }) => {
    setCreateOwnShowCurrentPage(createOwnShowCurrentPage + 1);
    setTimeout(() => callback(false), 750);
  };
  const comingSoonLoadMore = ({ callback }) => {
    setComingSoonCurrentPage(comingSoonCurrentPage + 1);
    setTimeout(() => callback(false), 750);
  };
  useEffect(() => {
    dispatch(
      fetchTodaysScreenings({
        limit,
        currentPage: todayScreeningCurrentPage,
        ...selectedFilters,
      })
    );
  }, [dispatch, limit, todayScreeningCurrentPage, selectedFilters]);
  useEffect(() => {
    dispatch(
      fetchCreateOwnShow({
        limit,
        currentPage: createOwnShowCurrentPage,
        ...selectedFilters,
      })
    );
  }, [dispatch, limit, createOwnShowCurrentPage, selectedFilters]);
  useEffect(() => {
    dispatch(
      fetchComingSoon({
        limit,
        currentPage: comingSoonCurrentPage,
        ...selectedFilters,
      })
    );
  }, [dispatch, limit, comingSoonCurrentPage, selectedFilters]);
  useEffect(() => {
    dispatch(fetchFormatList());
    dispatch(fetchLanguageList());
    dispatch(fetchFestivalList());
    dispatch(fetchBanners());
    dispatch(quickBookMovieList({ type: `quickBook` }));
    dispatch(fetchFilters());
  }, []);
  const onClickFormat = (format) => setSelectedFormat(format);
  const onClickQbFormat = (format) => {
    dispatch(setQbStage(3));
    setQbSelectedFormat(format);
    setQbSelectedCinema(null);
    setQbSelectedDate(null);
    setQbSelectedTime(null);
    dispatch(
      quickBook({
        movie_id: qbSelectedMovie.movie_id,
        lang_id: qbSelectedLanguage.lang_id,
        mf_id: format.mf_id,
        qbStage,
      })
    );
  };
  const onClickLanguage = (language) => setSelectedLanguage(language);
  const onClickQbLanguage = (language) => {
    dispatch(setQbStage(2));
    setQbSelectedLanguage(language);
    setQbSelectedFormat(null);
    setQbSelectedCinema(null);
    setQbSelectedDate(null);
    setQbSelectedTime(null);
    dispatch(
      quickBook({
        movie_id: qbSelectedMovie.movie_id,
        lang_id: language.lang_id,
        qbStage,
      })
    );
  };
  const onClickQbCinema = (cinema) => {
    dispatch(setQbStage(4));

    setQbSelectedCinema(cinema);
    setQbSelectedDate(null);
    setQbSelectedTime(null);
    dispatch(
      quickBook({
        movie_id: qbSelectedMovie.movie_id,
        lang_id: qbSelectedLanguage.lang_id,
        mf_id: qbSelectedFormat.mf_id,
        cinema_id: cinema.cine_id,
        qbStage,
      })
    );
  };

  const onClickQuickBook = () => {
    console.log(qbSelectedTime);
    console.log(qbSelectedDate);
    console.log(qbSelectedMovie);
    const movie = quickBookMovies.find(
      (movie) => movie.movie_id === qbSelectedMovie?.movie_id
    );
    console.log(
      "🚀 ~ file: mainHomeContainer.jsx ~ line 310 ~ onClickQuickBook ~ movie",
      movie
    );
    const payload = {
      mrrdr_runtime: movie?.mrrdr_runtime,
      cinema_ss_id: qbSelectedTime?.cinema_ss_id,
      start_time: qbSelectedTime?.dateShow,
      schedule_date: qbSelectedDate?.dateShow,
    };
    dispatch(createShow(payload, history));
  };
  const onClickQbDate = (date) => {
    dispatch(setQbStage(5));
    setQbSelectedDate(date);
    setQbSelectedTime(null);
    dispatch(
      quickBook({
        movie_id: qbSelectedMovie.movie_id,
        lang_id: qbSelectedLanguage.lang_id,
        mf_id: qbSelectedFormat.mf_id,
        cinema_id: qbSelectedCinema.cine_id,
        fromDate: date.dateShow,
        qbStage,
      })
    );
  };
  const onClickQbTime = (time) => {
    dispatch(setQbStage(6));
    setQbSelectedTime(time);
    // qbStage
  };

  const [activeSection, setActiveSection] = useState("Today's Screenings");
  const isMobile = useIsMobile();
  const isDesktop = useIsDesktop();
  const searchResult = useSelector((state) => state.homepage.searchMovies);

  const onChangeSearch = (search) => {
    setSearchedTerm(search);
    searchMovieCallback(search);
  };
  const moviesState = useSelector((state) => state.homepage);
  const {
    quickBookMovies,
    quickBookLanguages,
    quickBookFormats,
    quickBookCinemas,
    quickBookDates,
    quickBookTimes,
  } = moviesState;
  /************************* */
  const { arabic, country_data } = useContext(globalConfigContext);
  const [searchTerm, setSearchedTerm] = useState("");
  const searchMoviesLoading = useSelector(
    (state) => state.homepage.searchMoviesLoading
  );
  // const [showOffers, setShowOffers] = useState(true);
  const showOffers = true;
  const setShowOffers = () => {};
  const searchMovieCallback = useCallback(
    debounce((searchTerm2) => {
      dispatch(quickBookMovieList({ search: searchTerm2, type: `quickBook` }));
    }, 1000),
    []
  );

  // useEffect(() => {
  //   if (migrated_user) {
  //     // history.push(`/reset-password/${migrated_user.user_id}/${migrated_user.reset_password_token}/?migrated=yes`)
  //     setShowMigratedUserMessage(true);
  //     setLoginUsername("");
  //     setLoginPassword("");
  //   }
  // }, [migrated_user]);
  const onClickQbMovie = (movie) => {
    dispatch(setQbStage(1));
    setQbSelectedMovie(movie);
    setQbSelectedLanguage(null);
    setQbSelectedFormat(null);
    setQbSelectedCinema(null);
    setQbSelectedDate(null);
    setQbSelectedTime(null);
    dispatch(quickBook({ movie_id: movie.movie_id, qbStage }));
  };
  const [showFilterModal, setShowFilterModal] = useState(false);
  const selected_language = useSelector(
    (state) => state.movies.selected_language
  );
  const { iso_2, lang_id, lang_name } = selected_language;
  const inputRef = useRef();

  // useEffect for setting quickbook cinema if only one cinema is there
  useEffect(() => {
    if (qbStage === 3 && quickBookCinemas && quickBookCinemas.length === 1) {
      onClickQbCinema(quickBookCinemas[0]);
    }
  }, [quickBookCinemas]);

  function handleInputFocus() {
    if (!isMobile) {
      document
        .querySelector(".inputWrapper.qb_container > aside")
        .addEventListener("click", function (e) {
          document.activeElement.blur();
        });
    }
  }
  const [isClicked, setIsClicked] = useState(false);
  useEffect(handleInputFocus, []);
  // useEffect(() => {
  //   document.ondrag = (event) => {
  //     console.log(`being dragged`);
  //     setIsClicked(true);
  //   };

  //   document.onclick = (event) => {
  //     console.log(`being clicked`);
  //   };
  // }, []);

  function btnMarginHandler() {
    if (window.innerWidth > 767) {
      const btn = document.querySelector(".qb_box .btn-main");
      const box = document.querySelector(".qb_box");
      if (btn && box) {
        if (box.clientHeight > 100) {
          // console.log("height", box.scrollHeight);
          btn.style.margin = "0 auto";
        } else {
          btn.style.margin = "";
        }
      }
    }
  }

  useEffect(btnMarginHandler, []);
  window.addEventListener("resize", function () {
    btnMarginHandler();
  });
  return (
    <div>
      <div className="px-0">
        {/* FILTER BUTTON */}
        <div className="filter_btn" onClick={() => setShowFilterModal(true)}>
          <img src={filterImg} height="30px" width="30px" alt="" />
        </div>
        {/* Hero Section Starts */}
        <div className="">
          {false &&
            (banners?.length > 0 ? (
              <Slider {...heroSlider} className="dots-1">
                {banners?.map((banner, index) => (
                  <div
                    // draggable="true"
                    // clickable="true"
                    key={index}
                    className={`cursor-pointer`}
                    style={{
                      pointerEvents:
                        banner.movie_id &&
                        banner.content_lang_id &&
                        banner.genre_id
                          ? "initial"
                          : "none",
                    }}
                    onClick={() => {
                      if (!isClicked) {
                        // if (isClicked || isMobile) {
                        if (banner?.banner_type_id === 1) {
                          dispatch(setStage({ stage: 1, show: null }));
                          history.push(
                            `/movie-details/${banner.movie_id}/${banner.content_lang_id}`
                          );
                        } else if (
                          banner?.banner_type_id === 2 &&
                          banner?.banner_generic_url
                        ) {
                          window.location.assign(banner?.banner_generic_url);
                        }
                      } else {
                        setIsClicked(false);
                      }
                    }}
                  >
                    <section className="hero-section">
                      <div className="hero-img-section">
                        <img
                          src={getLangSpecificAttribute(
                            banner?.languageSpecificImages,
                            lang_id,
                            "banner_image_url",
                            "lang_id"
                          )}
                          alt="Movie Banner"
                          className="hero-img-1"
                        />
                      </div>
                      {/* className="hero-text justify-content-center align-items-end align-items-md-end align-items-xl-center pb-0 pb-md-5 pb-xl-0" */}
                      <div
                        className={`justify-content-center align-items-end pb-0 pb-md-4 pb-xl-5 ${
                          isMobile
                            ? "hero-text"
                            : banner?.fade === "Y"
                            ? "hero-text"
                            : "hero-text-1"
                        }`}
                      >
                        <div className="container-fluid container-xl pb-4 pb-md-4 pb-xl-0">
                          <div className="">
                            {/* <div className="row align-items-center mb-md-3">
                            <article className="col-6 pr-0 pr-md-3">
                              <p className="movie-name">
                                {
                                  banner?.altMovieContent?.find(
                                    (c) => c.lang_id === lang_id
                                  )?.mc_title
                                }
                              </p>
                            </article>
                            <article className="col-6">
                              <button
                                className="btn-main btn-main-sm ml-auto ml-md-0"
                                onClick={() => {
                                  dispatch(setStage({ stage: 1, show: null }));
                                  history.push(
                                    `/movie-details/${banner.movie_id}/${banner.content_lang_id}`
                                  );
                                }}
                              >
                                {isMobile
                                  ? "Book"
                                  : t("common.Book/Movie Info")}
                              </button>
                            </article>
                          </div> */}
                            <div className="row align-items-center align-items-md-stretch">
                              <div className="col-12">
                                <div className="d-flex flex-column justify-content-between align-items-start h-100">
                                  {banner?.banner_type_id === 1 ? (
                                    <p className="movie-name">
                                      {getLangSpecificAttribute(
                                        banner?.languageSpecificImages,
                                        lang_id,
                                        "banner_header",
                                        "lang_id"
                                      ) || banner?.banner_header}
                                      {/*  || banner.banner_name */}
                                    </p>
                                  ) : (
                                    <p className="movie-name">
                                      {banner?.banner_header}
                                    </p>
                                  )}
                                  {isDesktop && (
                                    <p
                                      className="text-limit-1 mb-md-3"
                                      id={`desc${index}`}
                                    >
                                      {/* {banner?.banner_subheader} */}
                                      {getLangSpecificAttribute(
                                        banner?.languageSpecificImages,
                                        lang_id,
                                        "banner_subheader",
                                        "lang_id"
                                      ) || banner?.banner_subheader}
                                    </p>
                                  )}
                                  {isDesktop && banner?.banner_type_id === 1 && (
                                    <>
                                      {/* <div className="movie-features">
                                      <div className="">
                                        <img src={calendar} alt="calendar" />
                                        <span>
                                          {moment(
                                            banner?.mrrdr_release_date ===
                                              `Invalid Date`
                                              ? moment()
                                              : banner?.mrrdr_release_date ||
                                                  moment()
                                          ).format("MMMM d, YYYY")}
                                        </span>
                                      </div> */}
                                      {banner?.banner_type_id === 1 &&
                                      banner?.button_enabled === "Y" ? (
                                        <button
                                          className="btn-main btn-main-sm ml-5 mb-xl-2"
                                          onClick={() => {
                                            dispatch(
                                              setStage({ stage: 1, show: null })
                                            );
                                            history.push(
                                              `/movie-details/${banner.movie_id}/${banner.content_lang_id}`
                                            );
                                          }}
                                        >
                                          {banner?.button_text?.length > 0
                                            ? t(`${banner?.button_text}`)
                                            : t("Book Now")}
                                        </button>
                                      ) : null}

                                      {/* <div className="">
                                        <img src={eye} alt="calendar" />
                                        <span>{banner?.rating}</span>
                                      </div>
                                      <div className="">
                                        <img src={clock} alt="calendar" />
                                        <span>
                                          {banner?.mrrdr_runtime}{" "}
                                          {t("common.mins")}
                                        </span>
                                      </div> */}
                                      {/* <div className="">
                                        <img src={format} alt="calendar" />
                                        <span>{banner?.mf_name}</span>
                                      </div> */}
                                      {/* </div> */}
                                      {/* <div className="movie-format"> */}
                                      {/* <img
                                        src={format}
                                        alt="calendar"
                                        className="mr-2"
                                      /> */}
                                      {/* <span>{banner?.mf_name}</span> */}
                                      {/* </div> */}
                                      {false && (
                                        <div className="tags">
                                          {banner?.genres_with_translations
                                            ?.filter(
                                              (genre) =>
                                                genre[iso_2.toLowerCase()]
                                            )
                                            ?.map((genre, index) => (
                                              <span className="" key={index}>
                                                {genre[iso_2.toLowerCase()]}
                                              </span>
                                            ))}
                                          {/* <span>Children</span> */}
                                          {/* {banner?.mf_name && (
                              <span>{banner?.mf_name}</span>
                            )} */}
                                        </div>
                                      )}
                                    </>
                                  )}
                                  {/* {isDesktop && banner?.banner_type_id === 1 && (
                                  <button
                                    className="btn-main"
                                    onClick={() => {
                                      dispatch(
                                        setStage({ stage: 1, show: null })
                                      );
                                      history.push(
                                        `/movie-details/${banner.movie_id}/${banner.content_lang_id}`
                                      );
                                    }}
                                  >
                                    {book?.text.length > 0
                                      ? t(`${book?.text}`)
                                      : t("Book Now")}
                                  </button>
                                )} */}
                                </div>
                              </div>

                              <div
                                className={`${
                                  false
                                    ? "col-6 col-md-6 movie-details"
                                    : "d-none"
                                }`}
                              >
                                <div className="row h-100">
                                  <div className="col-12">
                                    <article className="d-flex flex-column justify-content-end h-100 align-items-end">
                                      {!isDesktop &&
                                      banner?.banner_type_id === 1 &&
                                      banner?.button_enabled === "Y" ? (
                                        <button
                                          className="btn-main btn-main-sm ml-auto ml-xl-0 mb-xl-2"
                                          onClick={() => {
                                            dispatch(
                                              setStage({ stage: 1, show: null })
                                            );
                                            history.push(
                                              `/movie-details/${banner.movie_id}/${banner.content_lang_id}`
                                            );
                                          }}
                                        >
                                          {banner?.button_text?.length > 0
                                            ? t(`${banner?.button_text}`)
                                            : t("Book Now")}
                                        </button>
                                      ) : null}

                                      <>
                                        {false &&
                                          isDesktop &&
                                          banner?.banner_type_id === 1 && (
                                            <table>
                                              {hasStaring(
                                                banner?.castCrewDetails
                                              ) && (
                                                <tr>
                                                  <td>
                                                    <p className="detail-title">
                                                      {t("Starring")}
                                                    </p>
                                                  </td>
                                                  <td>
                                                    {banner?.castCrewDetails?.map(
                                                      (cast, index) =>
                                                        cast?.crew_title !==
                                                          `Director` && (
                                                          <div key={index}>
                                                            {/* {cast.crew_title} -{" "} */}
                                                            {cast?.Crew?.map(
                                                              (
                                                                crew,
                                                                index2
                                                              ) => (
                                                                <div
                                                                  key={index2}
                                                                  style={{
                                                                    display:
                                                                      "inline-block",
                                                                  }}
                                                                >
                                                                  <p>
                                                                    {"  "}
                                                                    {
                                                                      crew.mcc_cast_name
                                                                    }
                                                                    {index2 !==
                                                                    cast.Crew
                                                                      .length -
                                                                      1
                                                                      ? `, `
                                                                      : ""}
                                                                    &nbsp;
                                                                  </p>
                                                                </div>
                                                              )
                                                            )}
                                                          </div>
                                                        )
                                                    )}
                                                  </td>
                                                </tr>
                                              )}
                                              {hasDirector(
                                                banner?.castCrewDetails
                                              ) && (
                                                <tr>
                                                  <td>
                                                    <p className="detail-title">
                                                      {t("Director")}
                                                    </p>
                                                  </td>
                                                  <td>
                                                    {banner?.castCrewDetails?.map(
                                                      (cast, index) =>
                                                        cast?.crew_title ===
                                                          `Director` && (
                                                          <div key={index}>
                                                            {/* {cast.crew_title} -{" "} */}
                                                            {cast?.Crew?.map(
                                                              (
                                                                crew,
                                                                index2
                                                              ) => (
                                                                <div
                                                                  key={index2}
                                                                  style={{
                                                                    display:
                                                                      "inline-block",
                                                                  }}
                                                                >
                                                                  <p>
                                                                    {"  "}
                                                                    {
                                                                      crew.mcc_cast_name
                                                                    }
                                                                    {index2 !==
                                                                    cast.Crew
                                                                      .length -
                                                                      1
                                                                      ? `, `
                                                                      : ""}
                                                                    &nbsp;
                                                                  </p>
                                                                </div>
                                                              )
                                                            )}
                                                          </div>
                                                        )
                                                    )}
                                                  </td>
                                                </tr>
                                              )}
                                              {banner
                                                ?.languages_with_translations
                                                .length > 0 && (
                                                <tr>
                                                  <td>
                                                    <p className="detail-title">
                                                      {t("common.Languages")}
                                                    </p>
                                                  </td>
                                                  <td>
                                                    <p className="lang-coma-parent">
                                                      {banner?.languages_with_translations
                                                        ?.sort(
                                                          (a, b) =>
                                                            a.lang_name !==
                                                              null &&
                                                            b.lang_name !==
                                                              null &&
                                                            b.lang_name -
                                                              a.lang_name
                                                        )
                                                        ?.reverse()
                                                        ?.map((lang, index) => (
                                                          <span
                                                            className="lang-coma"
                                                            key={index}
                                                          >
                                                            {
                                                              lang[
                                                                iso_2.toLowerCase()
                                                              ]
                                                            }
                                                          </span>
                                                        ))}
                                                    </p>
                                                  </td>
                                                </tr>
                                              )}
                                              {banner?.movieSubtitles?.[0]
                                                ?.lang_name && (
                                                <tr>
                                                  <td>
                                                    <p className="detail-title">
                                                      {t("Subtitles")}
                                                    </p>
                                                  </td>
                                                  <td>
                                                    <p className="lang-coma-parent">
                                                      {banner?.movieSubtitles
                                                        ?.sort(
                                                          (a, b) =>
                                                            a.lang_name !==
                                                              null &&
                                                            b.lang_name !==
                                                              null &&
                                                            b.lang_name -
                                                              a.lang_name
                                                        )
                                                        ?.reverse()
                                                        ?.map(
                                                          (lang, index) =>
                                                            lang?.lang_name && (
                                                              <span
                                                                className="lang-coma"
                                                                key={index}
                                                              >
                                                                {
                                                                  lang?.lang_name
                                                                }
                                                              </span>
                                                            )
                                                        )}
                                                    </p>
                                                  </td>
                                                </tr>
                                              )}
                                              {banner?.description && (
                                                <tr>
                                                  <td>
                                                    <p className="detail-title">
                                                      {t("Synopsis")}
                                                    </p>
                                                  </td>
                                                  <td>
                                                    <p
                                                      className="text-limi"
                                                      id={`desc${index}`}
                                                    >
                                                      {banner?.description}
                                                    </p>
                                                  </td>
                                                </tr>
                                              )}
                                            </table>
                                          )}
                                        {false &&
                                          isDesktop &&
                                          banner?.banner_type_id === 2 && (
                                            <>
                                              <p>{banner?.banner_subheader}</p>
                                            </>
                                          )}
                                        {/* <div className="row mb-3">
                                  <div className="col-4">
                                    <p className="detail-title">
                                      {t("home_page.Stars")}
                                    </p>
                                  </div>
                                  <div className="col-8">
                                    {banner?.castCrewDetails?.map(
                                      (cast, index) => (
                                        <div key={index}>
                                          {cast.crew_title} -{" "}
                                          {cast?.Crew?.map((crew, index2) => (
                                            <div
                                              key={index2}
                                              style={{
                                                display: "inline-block",
                                              }}
                                            >
                                              <p>
                                                {"  "}
                                                {crew.mcc_cast_name}
                                                {index2 !==
                                                cast.Crew.length - 1
                                                  ? `, `
                                                  : ""}
                                                &nbsp;
                                              </p>
                                            </div>
                                          ))}
                                        </div>
                                      )
                                    )}
                                  </div>
                                </div> */}
                                        {/* <div className="row mb-3">
                                  <div className="col-4">
                                    <p className="detail-title">
                                      {t("common.Languages")}
                                    </p>
                                  </div>
                                  <div className="col-8">
                                    {banner?.languages_with_translations?.map(
                                      (lang, index) => (
                                        <p className="" key={index}>
                                          {lang[iso_2.toLowerCase()]}
                                        </p>
                                      )
                                    )}
                                  </div>
                                </div> */}
                                        {/* <p */}
                                        {/* className="text-limi" */}
                                        {/* id={`desc${index}`} */}
                                        {/* > */}
                                        {/* {banner &&
                                    toggleRM(
                                      `desc${index}`,
                                      banner?.description,
                                      250
                                    )} */}
                                        {/* {banner?.description} */}
                                        {/* </p> */}
                                      </>
                                    </article>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>
                  </div>
                ))}
              </Slider>
            ) : (
              <Slider {...heroSlider} className="dots-1">
                <div
                  draggable="true"
                  clickable="true"
                  className={`cursor-pointer`}
                  // style={{
                  //   pointerEvents:
                  //     banner.movie_id && banner.content_lang_id && banner.genre_id
                  //       ? "initial"
                  //       : "none",
                  // }}
                  // onClick={() => {
                  //   if (isClicked) {
                  //     dispatch(setStage({ stage: 1, show: null }));
                  //     history.push(
                  //       `/movie-details/${banner.movie_id}/${banner.content_lang_id}`
                  //     );
                  //   }
                  // }}
                >
                  <section className="hero-section">
                    <div className="hero-img-section">
                      <img
                        src={placeholderBanner}
                        alt="Movie Banner"
                        className="hero-img-1"
                      />
                    </div>
                    {/* hero-text justify-content-center align-items-end align-items-md-end align-items-xl-center pb-0 pb-md-5 pb-xl-0 */}
                    <div className="hero-text justify-content-center align-items-center ">
                      {/* container-fluid container-xl pb-4 pb-md-4 pb-xl-0 */}
                      <div className="container-fluid container-xl text-center">
                        <section className="placeholder-banner">
                          <figure className="">
                            <img src={reel} alt="" />
                          </figure>
                          <p className="">
                            <img src={clockWhite} alt="" className="mr-2" />
                            {t("Loading")} ...
                          </p>
                          {/* <h1 className="d-none d-lg-block">
                          Movie title goes here
                        </h1> */}
                        </section>
                      </div>
                    </div>
                    {/* <div className="hero-text justify-content-center align-items-end align-items-md-end align-items-xl-center pb-0 pb-md-5 pb-xl-0">
                    <div className="container-fluid container-xl pb-4 pb-md-4 pb-xl-0">
                      <div className="">
                        <div className="d-flex align-items-center justify-content-between">
                          {false && (
                            <p className="movie-name">
                              
                              Banner Title
                            </p>
                          )}
                        </div>
                        {false && !isMobile && (
                          <div className="row">
                            <div className="col-sm-5 col-md-6">
                              <div className="movie-features">
                                <div className="">
                                  <img src={calendar} alt="calendar" />
                                  <span>{moment().format("YYYY")}</span>
                                </div>
                                <div className="">
                                  <img src={eye} alt="calendar" />
                                  <span>U/A</span>
                                </div>
                              </div>
                              <div className="movie-features">
                                <div className="">
                                  <img src={clock} alt="calendar" />
                                  <span>100 {t("common.mins")}</span>
                                </div>
                                <div className="">
                                  <img src={globe} alt="calendar" />
                                  <span>3D</span>
                                </div>
                              </div>
                              <div className="tags">
                                
                                <span>Action</span>
                                <span>Adventure</span>
                                <span>Romance</span>
                               
                              </div>
                              {!isMobile && (
                                <button
                                  className="btn-main mt-3"
                                 
                                >
                                  {t("common.Book/Movie Info")}
                                </button>
                              )}
                            </div>

                            <div className="col-sm-7 col-md-6 movie-details d-none d-md-block">
                              <div className="row">
                                <div className="col-lg-9">
                                  <div className="row mb-3">
                                    
                                    <div className="col-8">
                                     
                                    </div>
                                  </div>
                                  <div className="row mb-3">
                                    <div className="col-4">
                                      <p className="detail-title">
                                        {t("common.Languages")}
                                      </p>
                                    </div>
                                    <div className="col-8">
                                    
                                      <span>English</span>,&nbsp;
                                      <span>French</span>,&nbsp;
                                      <span>German</span>&nbsp;
                                    </div>
                                  </div>
                                  <p className="text-limi" id={`desc}`}>
                                  
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div> */}
                  </section>
                </div>
              </Slider>
            ))}

          <div className="col-md-12 col-lg-12 ">
            {!isMobile ? (
              <>
                <div className="qb_box">
                  <article>
                    {/* <div className="dropdown-bg">
                        <Dropdown className="">
                          <Dropdown.Toggle
                            className="dropdownClassic"
                            id="dropdown-basic"
                          >
                            Enter Movie Name
                          </Dropdown.Toggle>

                          <Dropdown.Menu className="dropdownItems">
                            <Dropdown.Item href="#/action-1" className="">
                              ENGLISH
                            </Dropdown.Item>
                            <Dropdown.Item href="#/action-2" className="">
                              SANSKRIT
                            </Dropdown.Item>
                            <Dropdown.Item href="#/action-3">
                              ARAB
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </div> */}

                    {/* <div className="dropdown-bg"> */}
                    <div className="inputWrapper qb_container">
                      <img
                        src={search}
                        alt="search"
                        className="search-svg"
                        onClick={(e) => e.target.nextElementSibling.focus()}
                      />
                      {/* <div className="dropdown-toggle-icon position-relative h-100"> */}
                      <input
                        type="text"
                        className=""
                        placeholder={
                          qbSelectedMovie
                            ? qbSelectedMovie.movie_title
                            : t("Search Movie")
                        }
                        value={searchTerm}
                        onChange={(e) => {
                          onChangeSearch(e.target.value);
                        }}
                      />
                      {/* </div> */}

                      <aside className="">
                        {quickBookMovies?.length > 0 ? (
                          quickBookMovies.map((search, index) => (
                            <SearchedMovie
                              type="yellow"
                              movie={search}
                              key={index}
                              onChangeSearch={onChangeSearch}
                              onClick={(movie) => onClickQbMovie(movie)}
                              quickBook={true}
                            />
                          ))
                        ) : searchTerm && searchResult?.length === 0 ? (
                          <h6 className="mx-auto mb-0">
                            {t("common.No movies found")}
                          </h6>
                        ) : searchMoviesLoading ? (
                          <Spinner
                            animation="border"
                            role="status"
                            className="mx-auto"
                          />
                        ) : null}
                      </aside>
                    </div>
                    {/* </div> */}
                  </article>

                  <article>
                    <div className="dropdown-bg ">
                      <Dropdown>
                        <Dropdown.Toggle
                          className={`dropdownClassic ${
                            !qbSelectedMovie && "inactive"
                          }`}
                          id="dropdown-basic "
                        >
                          {qbSelectedLanguage
                            ? qbSelectedLanguage.lang_name
                            : t("common.Language")}
                        </Dropdown.Toggle>

                        <Dropdown.Menu className="dropdownItems ">
                          {quickBookLanguages?.map((language, index) => (
                            <Dropdown.Item
                              className={
                                qbSelectedLanguage?.lang_id ===
                                  language.lang_id && "selected"
                              }
                              key={index}
                              onClick={() => onClickQbLanguage(language)}
                            >
                              {language.lang_name}
                            </Dropdown.Item>
                          ))}
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  </article>
                  <article>
                    <div className="dropdown-bg">
                      <Dropdown className="">
                        <Dropdown.Toggle
                          className={`dropdownClassic ${
                            !qbSelectedLanguage && "inactive"
                          }`}
                          id="dropdown-basic"
                        >
                          {qbSelectedFormat
                            ? qbSelectedFormat.mf_name
                            : t("home_page.Version")}
                        </Dropdown.Toggle>

                        <Dropdown.Menu className="dropdownItems align-right">
                          {quickBookFormats?.map((format, index) => (
                            <Dropdown.Item
                              className={`${
                                qbSelectedFormat?.mf_id === format.mf_id &&
                                "selected"
                              }`}
                              key={index}
                              onClick={() => onClickQbFormat(format)}
                            >
                              {format.mf_name}
                            </Dropdown.Item>
                          ))}
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  </article>

                  <article>
                    <div className="dropdown-bg">
                      <Dropdown className="">
                        <Dropdown.Toggle
                          className={`dropdownClassic ${
                            !qbSelectedFormat && "inactive"
                          }`}
                          id="dropdown-basic"
                        >
                          {qbSelectedCinema
                            ? qbSelectedCinema.cine_name
                            : t("Cinema")}
                        </Dropdown.Toggle>
                        <Dropdown.Menu className="dropdownItems">
                          {quickBookCinemas?.map((cine, index) => (
                            <Dropdown.Item
                              onClick={() => onClickQbCinema(cine)}
                              key={index}
                              className={`${
                                qbSelectedCinema?.cine_id === cine.cine_id &&
                                "selected"
                              }`}
                            >
                              {cine.cine_name}
                            </Dropdown.Item>
                          ))}
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  </article>

                  <article>
                    <div className="dropdown-bg">
                      <Dropdown className="">
                        <Dropdown.Toggle
                          className={`dropdownClassic ${
                            !qbSelectedCinema && "inactive"
                          }`}
                          id="dropdown-basic"
                        >
                          {qbSelectedDate
                            ? moment(
                                qbSelectedDate.dateFormat,
                                `YYYY-MM-DD`
                              ).format(`ll`)
                            : t("Date")}
                        </Dropdown.Toggle>
                        <Dropdown.Menu className="dropdownItems">
                          {quickBookDates?.map((date, index) => (
                            <Dropdown.Item
                              onClick={() => onClickQbDate(date)}
                              key={index}
                              className={`${
                                qbSelectedDate?.ss_start_date ===
                                  date.ss_start_date && "selected"
                              }`}
                            >
                              {moment(date.dateFormat, `YYYY-MM-DD`).format(
                                `ll`
                              )}
                            </Dropdown.Item>
                          ))}
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  </article>

                  <article>
                    <div className="dropdown-bg">
                      <Dropdown className="">
                        <Dropdown.Toggle
                          className={`dropdownClassic ${
                            !qbSelectedDate && "inactive"
                          }`}
                          id="dropdown-basic"
                        >
                          {qbSelectedTime
                            ? qbSelectedTime.dateRange
                            : t("Time")}
                        </Dropdown.Toggle>
                        <Dropdown.Menu className="dropdownItems align-right">
                          {quickBookTimes?.map(
                            (time, index) =>
                              time.dateRange > moment().format("HH:mm") && (
                                <Dropdown.Item
                                  onClick={() => onClickQbTime(time)}
                                  key={index}
                                  className={`${
                                    qbSelectedTime?.dateRange ===
                                      time.dateRange && "selected"
                                  }`}
                                >
                                  {time.dateRange}
                                </Dropdown.Item>
                              )
                          )}
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  </article>

                  <article className="d-flex">
                    <button
                      onClick={onClickQuickBook}
                      className={`btn-main btn-main-lg ${
                        !qbSelectedTime && `inactive`
                      }`}
                    >
                      {t("quick-book")}
                    </button>
                  </article>
                </div>
              </>
            ) : (
              <>
                {/* <div className="mx-auto">
                    <p className="mbl-filter">
                      <svg
                        className="mr-3"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M20.626 3.60938H3.37128C2.79707 3.60938 2.43847 4.23516 2.72675 4.73438L8.17832 14.0016V19.6406C8.17832 20.0555 8.51113 20.3906 8.92363 20.3906H15.0736C15.4861 20.3906 15.8189 20.0555 15.8189 19.6406V14.0016L21.2728 4.73438C21.5588 4.23516 21.2002 3.60938 20.626 3.60938ZM14.1408 18.7031H9.85644V15.0469H14.1432V18.7031H14.1408ZM14.3658 13.1578L14.1432 13.5469H9.8541L9.63144 13.1578L4.98378 5.29688H19.0135L14.3658 13.1578Z"
                          fill="white"
                        />
                      </svg>
                      Filter
                    </p>
                  </div> */}
              </>
            )}
          </div>
          {/* <div className="container-fluid container-xl">
            <div className="row selects-row">
              
            </div>
          </div> */}
        </div>
        {/* Hero Section Ends */}

        <section className="container-fluid container-xl pt-2">
          {/* Mobile specific Starts */}
          <div className="row d-flex d-md-none my-3">
            <div className="col-12">
              <motion.div
                className="mobile-movie-sections"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 1.5 }}
              >
                <p
                  className={`${
                    activeSection === "Today's Screenings" ? "active" : ""
                  }`}
                  onClick={() => setActiveSection("Today's Screenings")}
                >
                  {/* {t("Today's Screenings")} */}
                  Now Showing
                </p>

                <p
                  className={`mx-4 ${
                    activeSection === "onDemand" ? "active" : ""
                  }`}
                  onClick={() => setActiveSection("onDemand")}
                >
                  {t("On Demand")}
                </p>

                <p
                  className={`${activeSection === "Upcoming" ? "active" : ""}`}
                  onClick={() => setActiveSection("Upcoming")}
                >
                  {t("Upcoming")}
                </p>
              </motion.div>
            </div>
          </div>

          <div className="row d-flex d-md-none mb-4">
            {activeSection === "Today's Screenings" &&
              uniqueTodaysScreeningMovies?.data?.length > 0 && (
                <>
                  {uniqueTodaysScreeningMovies?.data?.map((movie, index) => (
                    <MovieCard {...movie} key={index} index={index} type={2} />
                  ))}
                  {todaysScreeningsLastPage >
                    uniqueTodaysScreeningMovies?.pagination?.currentPage && (
                    <LoadMoreButton
                      show={true}
                      onClick={todayScreeningLoadMore}
                    />
                  )}
                </>
              )}

            {activeSection === "Upcoming" &&
              uniqueComingSoonMovies?.data?.length > 0 && (
                <>
                  {uniqueComingSoonMovies?.data?.map((movie, index) => (
                    <MovieCard {...movie} key={index} index={index} type={3} />
                  ))}
                  {comingSoonLastPage >
                    uniqueComingSoonMovies?.pagination?.currentPage && (
                    <LoadMoreButton show={true} onClick={comingSoonLoadMore} />
                  )}
                </>
              )}

            {activeSection === "onDemand" &&
              uniqueCreateOwnShowMovies?.data?.length > 0 && (
                <>
                  {uniqueCreateOwnShowMovies?.data?.map((movie, index) => (
                    <MovieCard {...movie} key={index} index={index} type={1} />
                  ))}
                  {createOwnShowLastPage >
                    uniqueCreateOwnShowMovies?.pagination?.currentPage && (
                    <LoadMoreButton
                      show={true}
                      onClick={createOwnShowLoadMore}
                    />
                  )}
                </>
              )}
          </div>
          {/* Mobile specific Ends */}

          {/* Movie Sections Starts */}
          {uniqueTodaysScreeningMovies?.data?.length > 0 && (
            <MovieSection
              heading={t("Now Showing")}
              totalMovies={uniqueTodaysScreeningMovies?.pagination?.total}
              moviesArray={uniqueTodaysScreeningMovies?.data}
              movieCardArray={uniqueTodaysScreeningMovies?.data}
              moviesObject={uniqueTodaysScreeningMovies}
              loadMore={todayScreeningLoadMore}
              currentPage={uniqueTodaysScreeningMovies?.pagination?.currentPage}
              lastPage={todaysScreeningsLastPage}
              type={2}
              notOnMobile
              // setShowOffers={setShowOffers}
            />
          )}

          {uniqueCreateOwnShowMovies?.data?.length > 0 && (
            <MovieSection
              heading={t("On Demand")}
              totalMovies={uniqueCreateOwnShowMovies?.pagination?.total}
              moviesArray={uniqueCreateOwnShowMovies?.data}
              movieCardArray={uniqueCreateOwnShowMovies?.data}
              moviesObject={uniqueCreateOwnShowMovies}
              loadMore={createOwnShowLoadMore}
              currentPage={uniqueCreateOwnShowMovies?.pagination?.currentPage}
              lastPage={createOwnShowLastPage}
              type={1}
              // setShowOffers={setShowOffers}
              notOnMobile
            />
          )}

          {uniqueComingSoonMovies?.data?.length > 0 && (
            <MovieSection
              heading={t("Upcoming")}
              totalMovies={uniqueComingSoonMovies?.pagination?.total}
              moviesArray={uniqueComingSoonMovies?.data}
              movieCardArray={uniqueComingSoonMovies?.data}
              moviesObject={comingSoonMovies}
              loadMore={comingSoonLoadMore}
              currentPage={uniqueComingSoonMovies?.pagination?.currentPage}
              lastPage={comingSoonLastPage}
              type={3}
              notOnMobile
              // setShowOffers={setShowOffers}
            />
          )}
          {/* Movie sections Ends */}

          {festivals?.data?.length > 0 && (
            <div className="row">
              <div className="col text-center">
                <p className="big-heading pt-4 pt-md-5 border-top">
                  {t("Mini Festivals and Events")}
                </p>
              </div>
            </div>
          )}

          {/* Mini Festival and Events */}

          {festivals &&
            festivals.data &&
            festivals.data.map((festival, index) => (
              <FestsNEvents
                //   movieImg={sampleBg}
                //   name="Harry Potter Film Series"
                //   moviesLength="8"
                //   dates="28 Feb - 28 Mar"
                //   description="Well, we've got some surprisingly good news for you! Right now,
                // you can watch all eight Harry Potter films.
                // Harry Potter is a series of novels by J.K. Rowling. It is about a
                // young boy named Harry Potter and his adventures as he attends
                // Hogwarts School of Witchcraft and Wizardry, learns how to perform
                // magic and comes face to face with his archenemy, Lord Voldemort."
                festival={festival}
                key={index}
              />
            ))}
          {/* Festival and Events Section Ends */}

          <PnoSlider />
        </section>
      </div>
      {/* POPUP START */}
      <Modal
        show={showFilterModal}
        aria-labelledby="contained-modal-title-vcenter"
        className="show-details-popup"
        centered
        onHide={() => setShowFilterModal(false)}
      >
        <section className="wrapper container-fluid">
          <img
            src={close}
            className="close-btn-main"
            onClick={() => {
              setShowFilterModal(false);
              if (!selectedFilterLanguage) {
                setSelectedFilterLanguage2(null);
              }
              if (!selectedFilterFormat) {
                setSelectedFilterFormat2(null);
              }
            }}
            onHide={() => setShowFilterModal(false)}
            alt=""
          />

          {/* <div className="row align-items-center">
            <div className="col-sm-4 d-none d-md-block">
              <div className="poster-img">
                <img
                  src={movieDetails?.md_thumbnail_url}
                  alt={t("alt.Poster")}
                  className=""
                />
              </div>
            </div>
            <div className="col-sm-8">
              <h4 className="heading">{movieDetails?.mc_title}</h4>
              <div className="tags"> */}
          {/* {showDetails.tags.map((tag) => (
                  <span className="">{tag}</span>
                ))} */}
          {/* <span>{movieDetails?.genre}</span>
                <span>{movieDetails?.mf_name}</span>
              </div>
              <div className="features">
                <div className="features-part"> */}
          {/* {showDetails.features.map((feature) => (
                    <div>
                      <img src={calendar} alt="calendar" />
                      <span>{feature}</span>
                    </div>
                  ))} */}
          {/* <div>
                    <img src={calendar} alt={t("alt.calendar")} />
                    <span>
                      {moment(movieDetails?.mrrdr_release_date).format("YYYY")}
                    </span>
                  </div>
                  <div>
                    <img src={eye} alt={t("alt.calendar")} />
                    <span>{movieDetails?.rating}</span>
                  </div>
                  <div>
                    <img src={clock} alt={t("alt.calendar")} />
                    <span>
                      {movieDetails?.mrrdr_runtime} {t("common.mins")}
                    </span>
                  </div>
                  <div>
                    <img src={globe} alt={t("alt.calendar")} />
                    <span>{t("common.DE")}</span>
                  </div>
                </div>
              </div>
            </div>
          </div> */}

          {filters?.map((filter, index) => (
            <div className="row" key={index}>
              {filter?.show && (
                <div className="px-3 w-100">
                  <h6 className="sub-heading">
                    {filter?.query_id === "lang_id"
                      ? t("Choose Language")
                      : filter?.query_id === "mf_id"
                      ? t("Choose Format")
                      : filter?.title}
                  </h6>
                  <div className="btn-main-group">
                    {filter?.typeArray?.map((type, typeIndex) => (
                      // add class active in button element when selected
                      <button
                        className={`tile ${type?.selected && "active"}`}
                        key={typeIndex}
                        onClick={() =>
                          onSelectFilter({
                            query_id: filter.query_id,
                            type_id: type.type_id,
                            index,
                            typeIndex,
                          })
                        }
                      >
                        {type?.type_name}
                      </button>
                    ))}
                  </div>
                </div>
              )}
            </div>
          ))}

          <div className="row pt-2">
            <div className="px-3 mt-4 mx-auto">
              {/* add class inactive when it is not selectable */}
              <button
                className="btn-main btn-main-lg"
                onClick={() => onSubmitFilter()}
              >
                {t("Confirm")}
              </button>
            </div>
          </div>
        </section>
      </Modal>
      {/* POPUP END */}
    </div>
  );
};

const SearchedMovie = ({
  // type = "yellow",
  onChangeSearch,
  onClick,
  movie: {
    movie_title,
    movie_id,
    content_lang_id,
    genre_id,
    category_id,
    showType,
  },
}) => {
  let type = "yellow";
  if (showType === "On Demand") {
    type = "yellow";
  } else if (showType === "Book Now") {
    type = "green";
  } else if (showType === "Now Showing") {
    type = "red";
  }
  return (
    <Link
      // to={`/movie-details/${movie_id}/${content_lang_id}/${category_id}/${genre_id}`}
      className="searched-movie-link"
    >
      <div
        className="searched-movie py-1 mb-0"
        onClick={() => {
          console.log("hello");
          onChangeSearch("");
          // history.push();
          onClick({ movie_title, movie_id });
        }}
      >
        {/* <div className="img-wrapper"> */}
        {/* <img src={md_thumbnail_url} alt="" /> */}
        {/* <img src="https://cinematic-server-gdc-3.brij.tech/uploads/gallery/1605268593716-1014338_artwork_ov.png" alt="movie-img" /> */}
        {/* </div> */}
        {/* <div className="movie-label d-md-none d-lg-none"> */}
        {/* <label className={`btn-${type}`}>{showType}</label> */}
        {/* </div> */}
        <div className="movie-content">
          <div className="d-none d-md-block d-lg-block">
            {/* <button className={`btn-main ${type}`}>{showType}</button> */}
          </div>
          <p className="movie-name">{movie_title}</p>
          {/* <p className="genre"> */}
          {/* {genre_name} | {rating}{" "} */}
          {/* </p> */}
          {/* <p className="experiences">{mf_name}</p> */}
        </div>
      </div>
    </Link>
  );
};
export default withNamespaces()(HomeContainer);
