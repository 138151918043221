import MovieService from "@apiService/MovieService";
import {
  FETCH_MOVIE_DETAILS,
  FETCH_MOVIE_DETAILS_SUCCESS,
  FETCH_MOVIE_DETAILS_ERROR,
  FETCH_MOVIE_SCHEDULES,
  FETCH_MOVIE_SCHEDULES_SUCCESS,
  FETCH_MOVIE_SCHEDULES_ERROR,
  ON_SET_COMPLETE_RATE_CARD,
  ON_SET_COMPLETE_NEW_TOTAL_AMOUNT,
  FETCH_SCHEDULE_DETAILS,
  FETCH_SCHEDULE_DETAILS_SUCCESS,
  FETCH_SCHEDULE_DETAILS_ERROR,
  BOOK_SEAT,
  BOOK_SEAT_SUCCESS,
  BOOK_SEAT_ERROR,
  FETCH_MOVIE_SCHEDULES_DETAILS_SUCCESS,
  SET_STAGE,
  SET_BOOKING_TYPE,
  FETCH_BOOKING_DETAILS,
  FETCH_BOOKING_DETAILS_SUCCESS,
  SET_SHOW_BOOKING_ID,
  FETCH_BOOKING_DETAILS_ERROR,
  SET_STRIPE_PUBLISHER_KEY,
  STRIPE_PAYMENT_SUCCESS,
  FETCH_OTHER_SCREENINGS,
  FETCH_OTHER_SCREENINGS_SUCCESS,
  FETCH_OTHER_SCREENINGS_ERROR,
  FETCH_GLOBAL_OPTIONS,
  FETCH_GLOBAL_OPTIONS_SUCCESS,
  FETCH_GLOBAL_OPTIONS_ERROR,
  FETCH_CURRENCY,
  FETCH_CURRENCY_SUCCESS,
  FETCH_CURRENCY_ERROR,
  FETCH_SCREENING_TYPES,
  FETCH_SCREENING_TYPES_SUCCESS,
  FETCH_SCREENING_TYPES_ERROR,
  SET_PRIVATE_SCREEN_AMOUNT,
  START_TIMER,
  RESET_TIMER,
  SET_FROM_MOVIE_PAGE,
  FETCH_MOVIE_DETAILS_SUCCESS2,
  CREATE_SHOW,
  CREATE_SHOW_SUCCESS,
  CREATE_SHOW_ERROR,
  SET_SHOW,
  SET_TIMEOUT_ERROR,
  FETCH_RESERVATION_DETAILS,
  FETCH_RESERVATION_DETAILS_SUCCESS,
  FETCH_RESERVATION_DETAILS_ERROR,
  SET_TIMER,
  CLEAR_STATE,
  FETCH_MOVIE_SCHEDULES_SUCCESS2,
  FETCH_MOVIE_SCHEDULES_SUCCESS3,
  STOP_TIMER,
  SEND_SMS,
  SEND_SMS_SUCCESS,
  SEND_SMS_ERROR,
  SEND_EMAIL,
  SEND_EMAIL_SUCCESS,
  SEND_EMAIL_ERROR,
  SET_SELECTED_LANGUAGE,
  FETCH_NOW_SHOWING,
  FETCH_NOW_SHOWING_SUCCESS,
  FETCH_NOW_SHOWING_ERROR,
  SELECT_CINEMA_SUCCESS,
  FETCH_CINEMAS,
  FETCH_CINEMAS_SUCCESS,
  FETCH_CINEMAS_ERROR,
  SET_LANGUAGE_SPECIFIC_MOVIE_DETAILS,
  SET_LANGUAGE_SPECIFIC_MOVIE_SCHEDULE_TODAY_DETAILS,
  SET_IS_CREATE_YOUR_SHOW_ENABLED,
  SET_MOVIE_FORMATS,
  SET_MOVIE_LANGUAGES,
  FETCH_ALL_MOVIES_SCHEDULES,
  FETCH_ALL_MOVIES_SCHEDULES_SUCCESS,
  FETCH_ALL_MOVIES_SCHEDULES_ERROR,
} from "./movieTypes";
import {
  SET_VOUCHERS,
  SET_APPLIED_VOUCHER,
  CLEAR_VOUCHERS_DATA,
  SET_VOUCHERS_ERROR,
  CLEAR_SELECTED_VOUCHER,
} from "../promotions/promotionsTypes";
import {
  bookFnb,
  updateFnbDataIfNotPresent,
} from "../foodAndBeverages/foodAndBeveragesActions";
import { toast } from "react-toastify";
import moment from "moment";
import { groupBy } from "lodash";

import { resetTempReservationId } from "../seatLayout/seatLayoutActions";
import {SET_INTERNET_HANDLING_FEES_APPLIED} from "../seatLayout/seatLayoutTypes";

let timerInterval = null;

export const setFromMoviePage = () => ({
  type: SET_FROM_MOVIE_PAGE,
});

export const fetchMovieDetails = ({
  movie_id,
  content_lang_id,
  category_id,
  cinema_id,
  cust_id = null,
}) => async (dispatch) => {
  try {
    dispatch({ type: FETCH_MOVIE_DETAILS });
    const { data } = await MovieService.GetMovieDetails({
      movie_id,
      content_lang_id,
      category_id,
      cinema_id,
      md_for_ondemand: "Y",
      guest_user_id: localStorage.getItem(`guest_user_id`),
      cust_id: cust_id,
    });
    if (data.status && data.Records.length > 0) {
      dispatch({
        type: FETCH_MOVIE_DETAILS_SUCCESS,
        payload: data.Records[0],
      });
      dispatch({
        type: FETCH_MOVIE_DETAILS_SUCCESS2,
        payload: data.Records[0],
      });
      dispatch(setLanguageSpecificMovieFunction());
    } else {
      dispatch({ type: FETCH_MOVIE_DETAILS_ERROR, payload: data.message });
    }
  } catch (error) {
    dispatch({ type: FETCH_MOVIE_DETAILS_ERROR, payload: error });
  }
};

export const fetchMovieSchedules = ({
  movie_id,
  dateSchedule,
  cinema_id,
  lang_id,
  mf_id,
  call,
  callback,
  onConfirmShowDetailsPopup,
  onChangeFormat,
  onChangeLanguage,
  dateNull,
  setSelectedLanguage,
  setSelectedFormat,
  setSelectedLanguage2,
  setSelectedFormat2,
  onChangeCinema,
  selectedLanguage2,
  selectedFormat2,
}) => async (dispatch) => {
  try {
    console.log(`call`, call);
    console.log(`lang_id`, lang_id);
    console.log(`mf_id`, mf_id);
    dispatch({ type: FETCH_MOVIE_SCHEDULES });
    const { data } = await MovieService.GetMovieSchedules({
      movie_id,
      queries: {
        fromDate: dateNull ? null : dateSchedule,
        cinema_id,
        lang_id,
        mf_id,
      },
    });
    if (data.status) {
      dispatch({
        type: FETCH_MOVIE_SCHEDULES_SUCCESS,
        payload: data,
      });
      if (call === 1) {
        dispatch({ type: FETCH_MOVIE_SCHEDULES_SUCCESS2, payload: data });
        dispatch({
          type: FETCH_MOVIE_SCHEDULES_SUCCESS3,
          payload: data,
        });

        dispatch({ type: SET_MOVIE_FORMATS, payload: data.FormatArray });
        dispatch({ type: SET_MOVIE_LANGUAGES, payload: data.LanguageArray });

        if (
          data.MovieListArray.length > 0 &&
          (data.LanguageArray.length > 1 || data.FormatArray.length > 1)
        ) {
          // callback(true);

          if (data.LanguageArray.length === 1) {
            // setSelectedLanguage(data.LanguageArray[0]);
            // onChangeLanguage(data.LanguageArray[0]);
          }

          if (data.FormatArray.length === 1) {
            // setSelectedFormat(data.FormatArray[0]);
            // onChangeFormat(data.FormatArray[0]);
          }
        } else if (data.MovieListArray.length > 0) {
          console.log(
            "inital date obj ======> 1",
            data.dateList[0].ss_actual_start_date
          );
          onChangeFormat(data.FormatArray[0]);
          onChangeLanguage(data.LanguageArray[0]);
          onConfirmShowDetailsPopup(
            data.LanguageArray[0],
            data.FormatArray[0],
            data.dateList[0].ss_actual_start_date
          );
        } else {
          console.log(
            "inital date obj ======> 2",
            data.dateList[0].ss_actual_start_date
          );
          onConfirmShowDetailsPopup(
            null,
            null,
            data.dateList[0].ss_actual_start_date
          );
        }
        if (data?.CinemaArray.length === 1) {
          onChangeCinema(data?.CinemaArray[0], false);
        }
      } else if (call === 2) {
        dispatch({
          type: FETCH_MOVIE_SCHEDULES_DETAILS_SUCCESS,
          payload: data,
        });
        dispatch({
          type: FETCH_MOVIE_SCHEDULES_SUCCESS3,
          payload: data,
        });

        if (
          data.MovieListArray.length > 0 &&
          (data.LanguageArray.length > 1 || data.FormatArray.length > 1)
        ) {
          if (!lang_id || !mf_id) {
            // callback(true);
          }
          // if (
          //   data.LanguageArray.find(
          //     (x) => x.lang_id === selectedLanguage2?.lang_id
          //   ) === undefined ||
          //   data.FormatArray.find((x) => x.mf_id === selectedFormat2?.mf_id) ===
          //     undefined
          // ) {
          //   callback(true);
          // } else {
          //   if (selectedLanguage2) {
          //     setSelectedLanguage(selectedLanguage2);
          //   }

          //   if (selectedFormat2) {
          //     setSelectedFormat(selectedFormat2);
          //   }
          // }

          if (!lang_id && data.LanguageArray.length === 1) {
            // setSelectedLanguage2(data.LanguageArray[0]);
          }

          if (!mf_id && data.FormatArray.length === 1) {
            // setSelectedFormat2(data.FormatArray[0]);
          }
        } else if (data.MovieListArray.length > 0) {
          if (!lang_id && data.LanguageArray.length > 0) {
            // setSelectedLanguage(data.LanguageArray[0]);
            // setSelectedLanguage2(data.LanguageArray[0]);
          }

          if (!mf_id && data.FormatArray.length > 0) {
            // setSelectedFormat(data.FormatArray[0]);
            // setSelectedFormat2(data.FormatArray[0]);
          }
        }
      }
    } else {
      dispatch({ type: FETCH_MOVIE_SCHEDULES_ERROR, payload: data.message });
    }
  } catch (error) {
    dispatch({ type: FETCH_MOVIE_SCHEDULES_ERROR, payload: error });
  }
};

export const fetchConfirmedList = ({
  movie_id,
  dateSchedule,
  cinema_id,
  lang_id,
  mf_id,
  call,
  callback,
  setDate,
  setDateValue,
  isNowShowing,
  onChangeCinema,
  setSelectedLanguage,
  setSelectedFormat,
}) => async (dispatch) => {
  try {
    dispatch({ type: FETCH_MOVIE_SCHEDULES });
    const { data } = await MovieService.GetConfirmedList({
      movie_id,
      queries: { fromDate: dateSchedule, cinema_id, lang_id, mf_id },
    });
    if (data.status) {
      dispatch({
        type: FETCH_MOVIE_SCHEDULES_SUCCESS,
        payload: data,
      });

      // if (!dateSchedule) {
      //   dispatch({
      //     type: FETCH_MOVIE_SCHEDULES_DETAILS_SUCCESS,
      //     payload: data,
      //   });
      // }
      if (call === 1) {
        dispatch({ type: FETCH_MOVIE_SCHEDULES_SUCCESS2, payload: data });
        dispatch({
          type: FETCH_MOVIE_SCHEDULES_SUCCESS3,
          payload: data,
        });
        if (
          data.MovieListArray.length > 0 &&
          (data.LanguageArray.length > 2 || data.FormatArray.length > 2)
        ) {
          callback(true);
        }
        console.log("data?.CinemaArray.length :>> ", data?.CinemaArray.length);
        if (data?.CinemaArray.length === 2) {
          onChangeCinema(
            data?.CinemaArray[1],
            data?.dateList[0].ss_actual_start_date
          );
        }
        if (data?.FormatArray.length === 2) {
          console.log("its coming here.. setSelectedFormat");
          setSelectedFormat(data?.FormatArray[1]);
        }
        if (data?.LanguageArray.length === 2) {
          console.log("its coming here.. setSelectedLanguage");
          setSelectedLanguage(data?.LanguageArray[1]);
        }
        if (data.dateList.length > 0) {
          setDate(data.dateList[0].ss_actual_start_date);
          setDateValue(new Date(data.dateList[0].ss_actual_start_date));
        }
      } else if (call === 2) {
        dispatch({
          type: FETCH_MOVIE_SCHEDULES_DETAILS_SUCCESS,
          payload: data,
        });
        dispatch({
          type: FETCH_MOVIE_SCHEDULES_SUCCESS3,
          payload: data,
        });

        if (
          data.MovieListArray.length > 0 &&
          (data.LanguageArray.length > 2 || data.FormatArray.length > 2)
        ) {
          callback(true);
        }
      }
    } else {
      dispatch({ type: FETCH_MOVIE_SCHEDULES_ERROR, payload: data.message });
    }
  } catch (error) {
    dispatch({ type: FETCH_MOVIE_SCHEDULES_ERROR, payload: error });
  }
};

export const setStage = (payload) => async (dispatch, getState) => {
  if (!payload.show) {
    dispatch({
      type: SET_STAGE,
      payload,
    });
  } else {
    const { mycinema_ss_d_id } = getState().movies.myCinemaCreateShow;
    console.log(
      "🚀 ~ file: movieActions.js ~ line 382 ~ setStage ~ mycinema_ss_d_id",
      mycinema_ss_d_id
    );
    console.log("payload :>> ", payload);
    // dispatch(fetchScreeningTypes({ mycinema_ss_d_id, payload }));
    if (payload.direct !== true) {
      payload.history.push(`/screening-type/${mycinema_ss_d_id}`);
    }
  }
};

export const setBookingType = (payload) => async (dispatch) =>
  dispatch({
    type: SET_BOOKING_TYPE,
    payload,
  });

export const onSetCompleteRateCard = (payload) => async (dispatch) =>
  dispatch({ type: ON_SET_COMPLETE_RATE_CARD, payload });

export const onSetCompleteNewTotalAmount = (payload) => async (dispatch) =>
  dispatch({ type: ON_SET_COMPLETE_NEW_TOTAL_AMOUNT, payload });

export const setShowBookingID = (payload) => async (dispatch) =>
  dispatch({ type: SET_SHOW_BOOKING_ID, payload });

export const setPrivateScreeningAmount = (payload) => async (dispatch) =>
  dispatch({ type: SET_PRIVATE_SCREEN_AMOUNT, payload });

export const fetchScheduleDetailsBrij = ({ ss_id, category_id }) => async (
  dispatch
) => {
  try {
    dispatch({ type: FETCH_SCHEDULE_DETAILS });
    const service =
      category_id === 1
        ? MovieService.GetScheduleDetailsBrij
        : MovieService.GetScheduleDetailsBrij;
    const { data } = await service({
      ss_id,
    });
    if (data.status && data.Records.length > 0) {
      dispatch({
        type: FETCH_SCHEDULE_DETAILS_SUCCESS,
        payload: { ...data.Records[0], ss_id },
      });
      dispatch({ type: FETCH_MOVIE_DETAILS_SUCCESS, payload: data.Records[0] });
      dispatch({
        type: SET_SHOW,
        payload: { ...data.Records[0], category_id },
      });
    } else {
      dispatch({ type: FETCH_SCHEDULE_DETAILS_ERROR, payload: data.message });
    }
  } catch (error) {
    dispatch({ type: FETCH_SCHEDULE_DETAILS_ERROR, payload: error });
  }
};

export const fetchScheduleDetails = ({
  ss_id,
  category_id,
  mycinema_ss_d_id,
  booking_type_id,
}) => async (dispatch) => {
  try {
    dispatch({ type: FETCH_SCHEDULE_DETAILS });
    const service =
      category_id === 1
        ? MovieService.GetScheduleDetails
        : MovieService.GetCinemaScheduleDetails;
    const { data } = await service({
      ss_id,
      mycinema_ss_d_id,
      booking_type_id,
    });
    if (data.status && data.Records.length > 0) {
      dispatch({
        type: FETCH_SCHEDULE_DETAILS_SUCCESS,
        payload: { ...data.Records[0], ss_id },
      });
      dispatch({ type: FETCH_MOVIE_DETAILS_SUCCESS, payload: data.Records[0] });
      dispatch({
        type: SET_SHOW,
        payload: { ...data.Records[0], category_id },
      });
    } else {
      dispatch({ type: FETCH_SCHEDULE_DETAILS_ERROR, payload: data.message });
    }
  } catch (error) {
    dispatch({ type: FETCH_SCHEDULE_DETAILS_ERROR, payload: error });
  }
};

const getOfferObject = (state) => {
  let offerData = {
    t_offer_price: 0,
    oc_discount_value: 0,
    is_on_net_amount: null,
    is_offer_for: null,
    is_offer_applied: false,
  };

  if (!state) {
    return offerData;
  }

  const { applied_voucher, offer_type, voucher_discount } = state.promotions;

  if (applied_voucher) {
    offerData = {
      ...offerData,
      t_offer_price: voucher_discount,
      oc_discount_value: voucher_discount,
      is_on_net_amount: applied_voucher.voucher_redeem_on_net,
      is_offer_for: offer_type,
      is_offer_applied: true,
      ...applied_voucher,
    };
  }

  return offerData;
};

export const bookSeats = ({
  reservation_id,
  cinema_id,
  customer_id,
  email,
  viaStripe,
  callback,
  secondCallback,
  ft_id,
}) => async (dispatch, getState) => {
  try {
    const state = getState();
    dispatch({ type: BOOK_SEAT });
    let offerData = getOfferObject(state);

    const { data } = await MovieService.BookSeats({
      isGroupRateCard: true,
      reservation_id,
      isSplitPayment: false,
      unpaid_booking: false,
      cinema_id,
      payment_mode_id: 14,
      approverId: null,
      order_code: null,
      oldCustomer: true,
      customer_id,
      cust_id: customer_id,
      email,
      payment_mode: {
        bs_id: 1,
        bs_source: `Box Office`,
        bs_is_active: "Y",
        created_by: null,
        created_at: null,
        updated_by: 4,
        updated_at: "2020-07-16T04:20:00.000Z",
        bspm_id: 20,
        booking_source_id: 1,
        mapping_is_active: "Y",
        payment_mode_id: 14,
        pm_payment_mode: "Stripe",
        pm_icon_url: null,
        pm_is_active: "Y",
        pm_id: 14,
        amount: 0,
      },
      isLoyaltyApplied: false,
      offer: offerData,
      ft_id,
    });
    if (data && data.status) {
      dispatch({ type: BOOK_SEAT_SUCCESS });
      dispatch({ type: SET_SHOW_BOOKING_ID, payload: data.bookingCode });
      if (window.innerWidth < 767) {
        secondCallback(3);
      } else {
        secondCallback(2);
      }
    } else {
      // toast.dismiss();
      // toast.error(data.message);
      dispatch({ type: BOOK_SEAT_ERROR, payload: data.message });
    }
  } catch (error) {
    if (error.response) {
      dispatch({ type: BOOK_SEAT_ERROR, payload: error.response.data });
    } else {
      dispatch({ type: BOOK_SEAT_ERROR, payload: error.message });
    }
  } finally {
    callback(false);
  }
};

export const cinemaBookSeats = ({
  reservation_id,
  cinema_id,
  customer_id,
  email,
  viaStripe,
  callback,
  secondCallback,
  ft_id,
}) => async (dispatch, getState) => {
  try {
    let state = getState();
    const {
      auth: {
        loggedInUser: { cust_email: email },
      },
      movies: {
        currency: { curr_code: currency_code },
      },
    } = getState();
    dispatch({ type: BOOK_SEAT });
    let offerData = getOfferObject(state);
    const { data } = await MovieService.CinemaBookSeats({
      isGroupRateCard: true,
      reservation_id,
      isSplitPayment: false,
      unpaid_booking: false,
      cinema_id,
      payment_mode_id: 14,
      approverId: null,
      order_code: null,
      oldCustomer: true,
      customer_id,
      cust_id: customer_id,
      email,
      payment_mode: {
        bs_id: 1,
        bs_source: `Box Office`,
        bs_is_active: "Y",
        created_by: null,
        created_at: null,
        updated_by: 4,
        updated_at: "2020-07-16T04:20:00.000Z",
        bspm_id: 20,
        booking_source_id: 1,
        mapping_is_active: "Y",
        payment_mode_id: 14,
        pm_payment_mode: "Stripe",
        pm_icon_url: null,
        pm_is_active: "Y",
        pm_id: 14,
        amount: 0,
      },
      isLoyaltyApplied: false,
      offer: offerData,
      currency_code,
      ft_id,
    });
    if (data && data.status) {
      dispatch({ type: BOOK_SEAT_SUCCESS });
      dispatch({ type: SET_SHOW_BOOKING_ID, payload: data.sb_booking_code });
      if (window.innerWidth < 767) {
        secondCallback(3);
      } else {
        secondCallback(2);
      }
    } else {
      // toast.dismiss();
      // toast.error(data.message);
      dispatch({ type: BOOK_SEAT_ERROR, payload: data.message });
    }
  } catch (error) {
    if (error.response) {
      dispatch({ type: BOOK_SEAT_ERROR, payload: error.response.data });
    } else {
      dispatch({ type: BOOK_SEAT_ERROR, payload: error.message });
    }
  } finally {
    callback(false);
  }
};

export const fetchBookingDetails = ({ sb_id }) => async (dispatch) => {
  try {
    dispatch({ type: FETCH_BOOKING_DETAILS });
    const { data } = await MovieService.GetBookingDetails({
      sb_id,
    });
    if (data.status && data.Records.length > 0) {
      dispatch({
        type: FETCH_BOOKING_DETAILS_SUCCESS,
        payload: data.Records[0],
      });
    } else {
      dispatch({ type: FETCH_BOOKING_DETAILS_ERROR, payload: data.message });
    }
  } catch (error) {
    dispatch({ type: FETCH_BOOKING_DETAILS_ERROR, payload: error });
  }
};

export const fetchBookingDetailsBrij = ({ sb_id }) => async (dispatch) => {
  try {
    dispatch({ type: FETCH_BOOKING_DETAILS });
    const { data } = await MovieService.GetBookingDetailsBrij({
      sb_id,
    });
    if (data.status && data.Records.length > 0) {
      dispatch({
        type: FETCH_BOOKING_DETAILS_SUCCESS,
        payload: data.Records[0],
      });
    } else {
      dispatch({ type: FETCH_BOOKING_DETAILS_ERROR, payload: data.message });
    }
  } catch (error) {
    dispatch({ type: FETCH_BOOKING_DETAILS_ERROR, payload: error });
  }
};

export const fetchStripePublisherKey = (payload) => async (dispatch) => {
  try {
    const { data } = await MovieService.GetStripePublisherKey();
    if (data.status) {
      dispatch({
        type: SET_STRIPE_PUBLISHER_KEY,
        payload: data.stripePublisherKey,
      });
      localStorage.setItem(`stripe_publisher_key`, data.stripePublisherKey);
    }
  } catch (error) {
    console.error(error);
  }
};
export const onSuccessStripeToken = ({
  reservation_id,
  token,
  cust_id,
  email,
  cinema_id,
  category_id,
  callback,
  secondCallback,
  cinema_name,
  mobile,
  countryMobCode,
}) => async (dispatch, getState) => {
  dispatch({ type: STOP_TIMER });
  console.log("reservation_id :>> ", reservation_id);
  try {
    const state = getState();
    const { selected_fnb_items, fnb_price } = state.fnb;
    const { curr_code, curr_id } = state.movies.currency;

    const { data } = await MovieService.CreateStripePaymentIntent({
      reservation_id,
      token,
      cust_id,
      isCinema: category_id == 2 ? false : true,
      selected_fnb_items,
    });

    if (data.status && data.charge) {
      dispatch({
        type: STRIPE_PAYMENT_SUCCESS,
      });

      let ft_id;

      if (selected_fnb_items && selected_fnb_items.length > 0) {
        // perform fnb booking first
        let result = await dispatch(
          bookFnb({
            reservation_id,
            selectedFnbItems: selected_fnb_items,
            cinemaObj: {
              cinema_id: cinema_id,
              cinema_name: cinema_name,
            },
            currency_id: curr_id,
            ft_total_amount: fnb_price.total_price,
            ft_net_amount: fnb_price.total_price - fnb_price.total_tax,
            email,
            mobile,
            countryMobCode,
            ft_currency_name: curr_code,
          })
        );

        if (!result.status) {
          dispatch({ type: BOOK_SEAT_ERROR, payload: "Fnb booking failed" });
          return;
        }

        ft_id = result.ft_id;
      }

      category_id == 1
        ? dispatch(
            cinemaBookSeats({
              reservation_id,
              cinema_id,
              customer_id: cust_id,
              email,
              viaStripe: true,
              callback,
              secondCallback,
              ft_id,
            })
          )
        : dispatch(
            bookSeats({
              reservation_id,
              cinema_id,
              customer_id: cust_id,
              email,
              viaStripe: true,
              callback,
              secondCallback,
              ft_id,
            })
          );
    }
  } catch (error) {
    console.error(error);
  }
  dispatch(resetTempReservationId());
};

export const onSuccessStripeTokenBrij = ({
  reservation_id,
  token,
  cust_id,
  email,
  cinema_id,
  category_id,
  callback,
  secondCallback,
  cinema_name,
  mobile,
  countryMobCode,
}) => async (dispatch, getState) => {
  dispatch({ type: STOP_TIMER });
  console.log("reservation_id :>> ", reservation_id);

  const state = getState();
  const { selected_fnb_items, fnb_price } = state.fnb;
  const { curr_code, curr_id } = state.movies.currency;

  try {
    const { data } = await MovieService.CreateStripePaymentIntentBrij({
      reservation_id,
      token,
      cust_id,
      isCinema: category_id == 2 ? false : true,
      selected_fnb_items,
    });
    if (data.status && data.charge) {
      dispatch({
        type: STRIPE_PAYMENT_SUCCESS,
      });

      let ft_id;

      if (selected_fnb_items && selected_fnb_items.length > 0) {
        // perform fnb booking first
        let result = await dispatch(
          bookFnb({
            reservation_id,
            selectedFnbItems: selected_fnb_items,
            cinemaObj: {
              cinema_id: cinema_id,
              cinema_name: cinema_name,
            },
            currency_id: curr_id,
            ft_total_amount: fnb_price.total_price,
            ft_net_amount: fnb_price.total_price - fnb_price.total_tax,
            email,
            mobile,
            countryMobCode,
            ft_currency_name: curr_code,
          })
        );

        if (!result.status) {
          dispatch({ type: BOOK_SEAT_ERROR, payload: "Fnb booking failed" });
          return;
        }

        ft_id = result.ft_id;
      }

      category_id == 1
        ? dispatch(
            cinemaBookSeats({
              reservation_id,
              cinema_id,
              customer_id: cust_id,
              email,
              viaStripe: true,
              callback,
              secondCallback,
              ft_id,
            })
          )
        : dispatch(
            bookSeats({
              reservation_id,
              cinema_id,
              customer_id: cust_id,
              email,
              viaStripe: true,
              callback,
              secondCallback,
              ft_id,
            })
          );
    }
  } catch (error) {
    console.error(error);
  }

  dispatch(resetTempReservationId());
};

export const fetchOtherScreenings = ({ movie_id, genre_id }) => async (
  dispatch
) => {
  try {
    dispatch({ type: FETCH_OTHER_SCREENINGS });
    const { data } = await MovieService.GetTodaysScreening({ genre_id });
    if (data.status && data.Records.data.length > 0) {
      data.Records.data = data.Records.data.filter(
        (m) => m.movie_id !== movie_id
      );
      dispatch({
        type: FETCH_OTHER_SCREENINGS_SUCCESS,
        payload: data.Records.data,
      });
    } else {
      dispatch({ type: FETCH_OTHER_SCREENINGS_ERROR, payload: data.message });
    }
  } catch (error) {
    dispatch({ type: FETCH_OTHER_SCREENINGS_ERROR, payload: error });
  }
};

export const fetchGlobalOptions = () => async (dispatch) => {
  try {
    dispatch({ type: FETCH_GLOBAL_OPTIONS });
    const { data } = await MovieService.GetGlobalOptions();
    console.log("data.Records", data.Records);
    dispatch({ type: FETCH_GLOBAL_OPTIONS_SUCCESS, payload: data.Records });
    // const seat_release_time = data.Records.find(
    //   (go) => go.go_key === "RELEASE_SEAT_BLOCKED_TIME"
    // );
    // if (seat_release_time !== undefined) {
    //   dispatch({
    //     type: SET_CHECKOUT_TIMER,
    //     payload: seat_release_time.go_value,
    //   });
    // }
    const currency = data.Records.find((go) => go.go_key === "CURRENCY");
    if (currency !== undefined) {
      dispatch({ type: FETCH_CURRENCY });
      const { data: currencyData } = await MovieService.GetCurrency({
        curr_id: currency.go_value,
      });

      if (currencyData && currencyData.status && currencyData.Records) {
        dispatch({
          type: FETCH_CURRENCY_SUCCESS,
          payload: currencyData.Records,
        });
      } else {
        dispatch({ type: FETCH_CURRENCY_ERROR });
      }
    }
  } catch (error) {
    dispatch({ type: FETCH_GLOBAL_OPTIONS_ERROR, payload: error.message });
  }
};

export const fetchScreeningTypes = ({ mycinema_ss_d_id, payload }) => async (
  dispatch
) => {
  try {
    dispatch({ type: FETCH_SCREENING_TYPES });
    const { data } = await MovieService.GetScreeningTypes({ mycinema_ss_d_id });
    if (data.status && data.Records.length > 0) {
      dispatch({
        type: FETCH_SCREENING_TYPES_SUCCESS,
        payload: data.Records,
      });

      dispatch({
        type: SET_STAGE,
        payload,
      });
      const privateType = data.Records.find(
        (type) => type.booking_type_id === 3
      );
      dispatch({
        type: SET_PRIVATE_SCREEN_AMOUNT,
        payload: {
          amount: privateType.amount,
          net_amount: privateType.net_amount,
        },
      });
    } else {
      dispatch({
        type: FETCH_SCREENING_TYPES_ERROR,
        payload: { message: data.message },
      });
      dispatch({
        type: SET_TIMEOUT_ERROR,
        payload: { message: data.message, show: true },
      });
    }
  } catch (error) {
    dispatch({ type: FETCH_SCREENING_TYPES_ERROR, payload: error });
  }
};

export const startTimer = () => ({
  type: START_TIMER,
  payload: {
    timerInterval,
  },
});

export const resetTimer = () => {
  if (timerInterval) {
    clearInterval(timerInterval);
    timerInterval = null;
  }

  return {
    type: RESET_TIMER,
  };
};

export const startTimerAction = () => async (dispatch) => {
  // dispatch(resetTimer());
  resetTimer();
  timerInterval = setInterval(() => {
    dispatch(startTimer());
  }, 1 * 1000);
};

export const clearState = () => async (dispatch) => {
  dispatch({ type: CLEAR_STATE });
  dispatch({ type: CLEAR_VOUCHERS_DATA });
};
//   {
//     "mrrdr_runtime": 139,
//     "start_time": "10:30",
//     "schedule_date": "2021-03-20",
//     "cinema_ss_id": "1",
//     "guest_user_id": "12345678"
// }

export const createShow = (
  { mrrdr_runtime, start_time, schedule_date, cinema_ss_id },
  history,
  direct
) => async (dispatch) => {
  try {
    // dispatch({ type: CREATE_SHOW });
    const payload = {
      mrrdr_runtime,
      start_time,
      schedule_date,
      cinema_ss_id,
      guest_user_id: localStorage.getItem(`guest_user_id`),
    };
    const { data } = await MovieService.CreateShow(payload);
    console.log("🚀 ~ file: movieActions.js ~ line 1822 ~ )=> ~ data", data);
    if (data.status) {
      dispatch({
        type: CREATE_SHOW_SUCCESS,
        payload: data,
      });
      // toast.success(data.message);
      dispatch(
        setStage({
          stage: 2,
          show: { mrrdr_runtime, start_time, schedule_date, cinema_ss_id },
          history,
          direct,
        })
      );
    } else {
      console.log("its coming here because of error in create show");
      toast.error(data.message);
      dispatch({ type: CREATE_SHOW_ERROR, payload: data.message });
    }
  } catch (error) {
    dispatch({ type: CREATE_SHOW_ERROR, payload: error });
  }
};

export const setTimeoutError = (payload) => async (dispatch) =>
  dispatch({ type: SET_TIMEOUT_ERROR, payload });

export const setSelectedLanguage = (payload) => async (dispatch) =>
  dispatch({ type: SET_SELECTED_LANGUAGE, payload });

export const fetchReservationDetails = ({
  reservation_id,
  category_id,
}) => async (dispatch) => {
  try {
    dispatch({ type: FETCH_RESERVATION_DETAILS });
    const { data } = await MovieService.GetReservationDetails({
      reservation_id,
    });
    if (data && data.status) {
      dispatch({
        type: FETCH_RESERVATION_DETAILS_SUCCESS,
        payload: data.Records[0],
      });
      dispatch({
        type: FETCH_SCHEDULE_DETAILS_SUCCESS,
        payload: data.Records[0],
      });
      dispatch({
        type: SET_TIMER,
        payload: {
          minutes: data.Records[0].timer_minutes,
          seconds: data.Records[0].timer_second,
        },
      });
      dispatch(updateFnbDataIfNotPresent(data.Records[0].fnb_data));
      dispatch(startTimerAction());
    } else {
      dispatch({
        type: SET_SHOW,
        payload: {
          movie_id: data.movie_id,
          content_lang_id: data.content_lang_id,
          category_id,
        },
      });
      dispatch({
        type: SET_TIMEOUT_ERROR,
        payload: { message: data.message, show: true },
      });
    }
  } catch (error) {
    dispatch({ type: FETCH_RESERVATION_DETAILS_ERROR, error });
  }
};

export const fetchReservationDetailsBrij = ({
  reservation_id,
  category_id,
}) => async (dispatch) => {
  try {
    dispatch({ type: FETCH_RESERVATION_DETAILS });
    const { data } = await MovieService.GetReservationDetailsBrij({
      reservation_id,
    });
    if (data && data.status) {
      dispatch({
        type: FETCH_RESERVATION_DETAILS_SUCCESS,
        payload: data.Records[0],
      });
      dispatch({
        type: FETCH_SCHEDULE_DETAILS_SUCCESS,
        payload: data.Records[0],
      });
      dispatch({
        type: SET_TIMER,
        payload: {
          minutes: data.Records[0].timer_minutes,
          seconds: data.Records[0].timer_second,
        },
      });
      dispatch({
        type: SET_INTERNET_HANDLING_FEES_APPLIED,
        payload: data?.is_internet_handling_fee_applied === 'Y',
      })
      dispatch(updateFnbDataIfNotPresent(data.Records[0].fnb_data));
      dispatch(startTimerAction());
    } else {
      dispatch({
        type: SET_SHOW,
        payload: {
          movie_id: data.movie_id,
          content_lang_id: data.content_lang_id,
          category_id,
        },
      });
      dispatch({
        type: SET_TIMEOUT_ERROR,
        payload: { message: data.message, show: true },
      });
    }
  } catch (error) {
    dispatch({ type: FETCH_RESERVATION_DETAILS_ERROR, error });
  }
};

export const sendSms = ({ sb_booking_code }) => async (dispatch) => {
  try {
    dispatch({ type: SEND_SMS });
    const { data } = await MovieService.SendSMS({ sb_booking_code });
    if (data && data.status) {
      dispatch({ type: SEND_SMS_SUCCESS });
      // toast.success(`Sms sent!`);
      return data;
    } else {
      dispatch({ type: SEND_SMS_ERROR, payload: data.error });
      return data;
    }
  } catch (error) {
    console.error(error);
    dispatch({ type: SEND_SMS_ERROR, payload: error });
    return error;
  }
};

export const sendEmail = ({ sb_booking_code }) => async (
  dispatch,
  getState
) => {
  try {
    const {
      auth: {
        loggedInUser: { cust_email: email },
      },
      movies: {
        currency: { curr_code: currency_code },
      },
    } = getState();
    dispatch({ type: SEND_EMAIL });
    const { data } = await MovieService.SendEmail({
      sb_booking_code,
      currency_code,
      email,
    });
    if (data && data.status) {
      dispatch({ type: SEND_EMAIL_SUCCESS });
      return data;
      // toast.success(`Email sent!`);
    } else {
      dispatch({ type: SEND_EMAIL_ERROR, payload: data.error });
      return data;
    }
  } catch (error) {
    console.error(error);
    dispatch({ type: SEND_EMAIL_ERROR, payload: error });
    return error;
  }
};

export const getCinemas = () => async (dispatch) => {
  try {
    dispatch({ type: FETCH_CINEMAS });
    const { data } = await MovieService.GetCinemas();
    if (data && data.status && data.Records.length > 0) {
      dispatch({ type: FETCH_CINEMAS_SUCCESS, payload: data.Records });
    }
  } catch (error) {
    dispatch({ type: FETCH_CINEMAS_ERROR, payload: error.message });
  }
};

export const setSelectedCinema = (cinema, history) => async (dispatch) => {
  dispatch({ type: SELECT_CINEMA_SUCCESS, payload: cinema });
  // let pathname = window.location.pathname;
  // if (
  //   !pathname ||
  //   (!pathname.startsWith("/final-ticket") &&
  //     !pathname.startsWith("/user-profile"))
  // ) {
  //   history.push(`/`);
  // }
};

export const setLanguageSpecificMovieFunction = () => async (dispatch) =>
  dispatch({ type: SET_LANGUAGE_SPECIFIC_MOVIE_DETAILS });

export const checkCreateYourShowEnabled = (payload) => async (dispatch) => {
  try {
    let res = await MovieService.CheckCreateYourShow(payload);
    let { data } = res;

    if (data.status && data.createYourShowEnabled) {
      dispatch({ type: SET_IS_CREATE_YOUR_SHOW_ENABLED, payload: true });
    } else {
      dispatch({ type: SET_IS_CREATE_YOUR_SHOW_ENABLED, payload: false });
    }
  } catch (err) {
    console.log("error in checkCreateYourShowEnabled", err);
  }
};

export const fetchAllMoviesSchedules = (payload) => async (dispatch) => {
  try {
    dispatch({ type: FETCH_ALL_MOVIES_SCHEDULES });

    const { data } = await MovieService.GetAllMoviesAndSchedules(payload);
    if (data.status) {
      dispatch({
        type: FETCH_ALL_MOVIES_SCHEDULES_SUCCESS,
        payload: {
          movieSchedules: data.Records,
          datesArray: data.Dates,
        },
      });
    } else {
      dispatch({
        type: FETCH_ALL_MOVIES_SCHEDULES_ERROR,
        payload: data.message,
      });
    }
  } catch (err) {
    dispatch({
      type: FETCH_ALL_MOVIES_SCHEDULES_ERROR,
      payload: err.message,
    });
  }
};
