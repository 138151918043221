import Api from "./Api";
import { createQueryString } from "./ApiHelper";
import moment from "moment";
export default {
  GetTodaysScreening(payload) {
    const cinema = JSON.parse(localStorage.getItem(`selected_cinema`));
    if (cinema) {
      payload.cinema_id = cinema.cinema_id;
    }
    const query = createQueryString(payload);
    return Api().get(`api/cinema/admin/now-showing-confirmed-list${query}`);
  },
  GetCreateOwnShow(payload) {
    const cinema = JSON.parse(localStorage.getItem(`selected_cinema`));
    if (cinema) {
      payload.cinema_id = cinema.cinema_id;
    }
    // const date = moment().format("YYYY-MM-DD");
    // console.log(
    //   "🚀 ~ file: MovieService.js ~ line 11 ~ GetCreateOwnShow ~ date",
    //   date
    // );
    // payload.fromDate = moment(new Date()).add(7, "days").format("YYYY-MM-DD");
    // console.log(
    //   "🚀 ~ file: MovieService.js ~ line 11 ~ GetCreateOwnShow ~ fromDate",
    //   payload
    // );
    // payload.toDate = moment(new Date()).add(14, "days").format("YYYY-MM-DD");
    const query = createQueryString(payload);
    return Api().get(`api/cinema/admin/now-showing-list${query}`);
  },
  GetComingSoon(payload) {
    const cinema = JSON.parse(localStorage.getItem(`selected_cinema`));
    if (cinema) {
      payload.cinema_id = cinema.cinema_id;
    }
    const query = createQueryString(payload);
    return Api().get(`api/cinema/admin/upcoming-show-list${query}`);
  },
  GetFormatList(payload) {
    return Api().get(`api/cinema/format-list`);
  },
  GetLanguageList(payload) {
    return Api().get(`api/cinema/language-list`);
  },
  GetFestivalList(payload) {
    return Api().get(`api/cinema/festival-list`);
  },
  GetBanners(payload = {}) {
    console.log(
      "🚀 ~ file: MovieService.js ~ line 50 ~ GetBanners ~ payload",
      payload
    );
    const cinema = JSON.parse(localStorage.getItem(`selected_cinema`));
    console.log(
      "🚀 ~ file: MovieService.js ~ line 55 ~ GetBanners ~ cinema",
      cinema
    );
    if (cinema) {
      payload.cinema_id = cinema.cinema_id;
    }
    const query = createQueryString(payload);
    console.log(
      "🚀 ~ file: MovieService.js ~ line 55 ~ GetBanners ~ query",
      query
    );
    return Api().get(`api/cinema/banners${query}`);
  },
  SearchMovie(payload) {
    // const cinema = JSON.parse(localStorage.getItem(`selected_cinema`));
    // if (cinema) {
    //   payload.cinema_id = cinema.cinema_id;
    // }
    payload.limit = 5;
    payload.currentPage = 1;
    const query = createQueryString(payload);
    return Api().get(`api/cinema/admin/search-movie${query}`);
  },
  GetMovieDetails(payload) {
    const {
      movie_id,
      content_lang_id,
      cinema_id,
      md_for_ondemand,
      guest_user_id,
      cust_id,
    } = payload;
    const query = createQueryString({
      cinema_id,
      md_for_ondemand,
      guest_user_id,
      cust_id,
    });
    return Api().get(
      `api/cinema/movie-detail/${movie_id}/${content_lang_id}/${query}`
    );
  },
  GetMovieSchedules(payload) {
    const cinema = JSON.parse(localStorage.getItem(`selected_cinema`));
    if (cinema) {
      payload.cinema_id = cinema.cinema_id;
    }
    const { movie_id } = payload;
    payload.queries.guest_user_id = localStorage.getItem(`guest_user_id`);
    const query = createQueryString(payload.queries);
    console.log("query :>> ", query);
    return Api().get(
      `api/cinema/admin/v2/movie-schedule-list/${movie_id}/${query}`
      // http://localhost:3800/api/cinema/admin/movie-schedule-list/80
    );
  },
  GetSeatLayout(payload) {
    return Api().post("/api/cinema/admin/seat-layout-list", payload);
  },
  GetSeatLayoutBrij(payload) {
    return Api().post("/api/external/seat-layout", payload);
  },
  GetSeatType(payload) {
    const { screen_id } = payload;
    return Api().get(`/api/cinema/seat-type/${screen_id}`, payload);
  },
  GetScheduleDetails(payload) {
    const { ss_id } = payload;
    return Api().get(`/api/cinema/schedule-details/${ss_id}`, payload);
  },
  GetScheduleDetailsBrij(payload) {
    const { ss_id } = payload;
    return Api().get(`/api/cinema/schedule-details/${ss_id}`, payload);
  },
  GetCinemaScheduleDetails(payload) {
    const { mycinema_ss_d_id } = payload;
    return Api().get(
      `/api/cinema/admin/cinema-schedule-details/${mycinema_ss_d_id}`,
      payload
    );
  },
  ReserveSeats(payload) {
    return Api().post(`/api/cinema/reserve-seat`, payload);
  },
  CinemaReserveSeats(payload) {
    return Api().post(`/api/cinema/admin/reserve-seats`, payload);
  },
  BookSeats(payload) {
    return Api().post(`/api/cinema/book-seat`, payload);
  },
  CinemaBookSeats(payload) {
    return Api().post(`/api/cinema/admin/book-seat`, payload);
  },
  GetBookingDetails(payload) {
    const { sb_id } = payload;
    return Api().get(`/api/cinema/booking-details/${sb_id}`, payload);
  },
  GetBookingDetailsBrij(payload) {
    const { sb_id } = payload;
    return Api().get(`/api/cinema/booking-details/${sb_id}`, payload);
  },
  GetStripePublisherKey(payload) {
    return Api().get(`/api/cinema/stripe-publisher-key`, payload);
  },
  CreateStripePaymentIntent(payload) {
    const { reservation_id } = payload;
    return Api().post(
      `/api/cinema/admin/create-stripe-payment-intent/${reservation_id}`,
      payload
    );
  },
  CreateStripePaymentIntentBrij(payload) {
    const { reservation_id } = payload;
    return Api().post(
      `/api/external/create-stripe-payment-intent/${reservation_id}`,
      payload
    );
  },
  GetMiniFestivals(payload) {
    const cinema = JSON.parse(localStorage.getItem(`selected_cinema`));
    if (cinema) {
      payload.cinema_id = cinema.cinema_id;
    }
    const query = createQueryString(payload);
    console.log("query :>> ", query);
    return Api().get(`api/cinema/get-minifestival-list${query}`);
  },
  GetGlobalOptions() {
    return Api().get(`/global_options`);
  },
  GetCurrency({ curr_id }) {
    return Api().get(`/api/cinema/currency/${curr_id}`);
  },
  GetScreeningTypes(payload) {
    const query = createQueryString(payload);
    console.log("query :>> ", query);
    return Api().get(`api/cinema/admin/screening-type-list${query}`);
  },
  ReleaseSeat(payload) {
    const { reservation_id } = payload;
    return Api().get(
      `/api/cinema/admin/release-seat/${reservation_id}`,
      payload
    );
  },
  ReleaseSeatBrij(payload) {
    const { reservation_id } = payload;
    return Api().post(`/api/external/release-seat`, payload);
  },
  ReleaseRealtimeSeats(payload) {
    return Api().post(`/api/external/release-realtime-seat`, payload);
  },
  QuickBook(payload) {
    // const cinema = JSON.parse(localStorage.getItem(`selected_cinema`));
    // if (cinema) {
    //   payload.cinema_id = cinema.cinema_id;
    // }
    const query = createQueryString(payload);
    return Api().get(`/api/cinema/admin/quick-book-list${query}`);
  },
  QuickBookMovieList(payload) {
    // const cinema = JSON.parse(localStorage.getItem(`selected_cinema`));
    // if (cinema) {
    //   payload.cinema_id = cinema.cinema_id;
    // }
    payload.limit = 5;
    payload.currentPage = 1;
    const query = createQueryString(payload);
    return Api().get(`/api/cinema/admin/quick-book-search-movie${query}`);
  },
  GetOffers(payload) {
    return Api().get(`/api/cinema/admin/offers`);
  },
  GetAuditoriums(payload) {
    return Api().get(`/api/cinema/admin/auditorium-list/${payload.cinema_id}`);
  },
  GetAudiSeatLayout(payload) {
    return Api().get(`/api/cinema/admin/audi-seat-layout/${payload.screen_id}`);
  },
  GetFilters(payload) {
    const cinema = JSON.parse(localStorage.getItem(`selected_cinema`));
    let cinema_id;
    if (cinema) {
      cinema_id = cinema.cinema_id;
    }
    const query = createQueryString({ ...payload, cinema_id });
    return Api().get(`/api/cinema/admin/filter-list${query}`);
  },
  CreateShow(payload) {
    return Api().post(`/api/cinema/admin/create-schedule`, payload);
  },
  GetReservationDetails(payload) {
    const { reservation_id } = payload;
    return Api().get(`/api/cinema/admin/reservation-detail/${reservation_id}`);
  },
  GetReservationDetailsBrij(payload) {
    const { reservation_id } = payload;
    // TODO change route
    return Api().get(`/api/external/reservation-detail/${reservation_id}`);
  },
  // GetReservationDetails(payload) {
  //   const { reservation_id } = payload;
  //   return Api().get(`/api/cinema/admin/reservation-detail/${reservation_id}`);
  // },
  GetConfirmedList(payload) {
    const cinema = JSON.parse(localStorage.getItem(`selected_cinema`));
    if (cinema) {
      payload.queries.cinema_id = cinema.cinema_id;
    }
    const query = createQueryString(payload.queries);
    console.log("query :>> ", query);
    const { movie_id } = payload;
    return Api().get(
      `/api/cinema/admin/movie-confirmed-list/${movie_id}${query}`
    );
  },
  SendEmail(payload) {
    return Api().post(`/api/external/email`, payload);
  },
  SendSMS(payload) {
    return Api().post(`/api/external/sms`, payload);
  },
  // ReleaseSeat(payload) {
  //   const { reservation_id } = payload;
  //   return Api().get(`/api/cinema/admin/reservation-detail/${reservation_id}`);
  // },
  GetTranslations() {
    return Api().get(`/api/external/translations?module=mycinema`);
  },
  GetNowShowingConfirmedList(payload) {
    return Api().get(`/api/cinema/admin/now-showing-confirmed-list`);
  },
  GetCinemas(payload) {
    return Api().post(`/api/external/cinemas`, payload);
  },
  CheckCreateYourShow(payload) {
    const query = createQueryString(payload);
    return Api().get(`/api/cinema/check-create-your-show/${query}`);
  },
  GetAllMoviesAndSchedules(payload = {}) {
    const query = createQueryString(payload);
    return Api().get(`/api/external/all-confirmed-movies-schedules/${query}`);
  },
};
