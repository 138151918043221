import React, {
  useEffect,
  useState,
  useContext,
  useCallback,
  useRef,
} from "react";
import { withNamespaces } from "react-i18next";
import { Link } from "react-router-dom";
import {
  Card,
  ListGroup,
  Alert,
  Form,
  Button,
  Modal,
  Row,
  Container,
  Col,
  Dropdown,
  Accordion,
} from "react-bootstrap";
import MovieFooter from "./partials/movieFooter";
import close from "@assets/pictures/svgs/close.svg";
import infoCircle from "@assets/pictures/svgs/info-circle.svg";
import screenSl from "@assets/pictures/svgs/screen-sl.svg";
import standardSeat from "@assets/pictures/svgs/seat-standard.svg";
import loungerSeat from "@assets/pictures/svgs/seat-lounger.svg";
import selectedSeat from "@assets/pictures/svgs/seat-standard-selected.svg";
import unavailableSeat from "@assets/pictures/svgs/seat-standard-unavailable.svg";
import cinemaBanner from "@assets/pictures/cinema-banner.png";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  updateSeatLayout,
  fetchSeatLayout,
  fetchSeatLayoutBrij,
  selectSeats,
  clearSeatSelection,
  reserveSeats,
  releaseSeatBrij,
  releaseRealTimeBlockedSeats,
  setTempReservationId,
} from "../store/seatLayout/seatLayoutActions";
import {
  onSetCompleteRateCard,
  onSetCompleteNewTotalAmount,
  resetTimer,
  fetchScheduleDetailsBrij,
  fetchMovieDetails,
} from "../store/movies/movieActions";
import { clearFnbState } from "../store/foodAndBeverages/foodAndBeveragesActions";
import {
  fetchVouchers,
  clearSelectedVoucher,
} from "../store/promotions/promotionsActions";
import { useParams } from "react-router";
import { slImageOnhover } from "../helper/formatting.js";
import Spinner from "./partials/Spinner";
import arrowBack from "@assets/pictures/svgs/arrow-back.svg";
import arrowBackActive from "@assets/pictures/svgs/arrow-back-active.svg";
import useIsMobile from "../customHooks/useIsMobile";
import { useIsPresent } from "framer-motion";
import { currencyFormatter } from "../helper/currencyFormatter";
import { getValueFromGlobalOptions } from "@helper/utils";
import dummyPoster from "@assets/pictures/dummyPoster.png";
import { getLangSpecificAttribute } from "@helper/languages";
import { backendSocket } from "@src/index.js";
import { toast } from "react-toastify";
import _ from "lodash";
import io from "socket.io-client";
import { BASEURL } from "@config/index";

let socket_options = {
  reconnectionAttempts: 3,
  reconnectionDelay: 10000,
};

const SeatLayout = ({ t }) => {
  const selected_language = useSelector(
    (state) => state.movies.selected_language
  );
  const { lang_name, iso_2, lang_id } = selected_language;
  const isMobile = useIsMobile();
  const dispatch = useDispatch();
  const [seats, setSeats] = useState([]);
  const [userSelectedSeatsNew, setUserSelectedSeatsNew] = useState([]);
  const [back, setBack] = useState(false);
  const isFromMoviePage = useSelector((state) => state.movies.fromMoviePage);
  const [showModal, setShowModal] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const [modalBtnMessage, setShowModalBtnMsg] = useState("");
  const [backendSocket, setBackendSocket] = useState(null);
  const {
    screen_id,
    ss_id,
    md_id,
    // category_id,
    gogo_ss_id,
    booking_type,
    genre_id,
    movie_id,
    content_lang_id,
    isPrivate,
  } = useParams();
  const reservation_id = useSelector(
    (state) => state.seatLayout.reservation_id
  );
  const category_id = 2;
  useEffect(() => {
    dispatch(clearFnbState());
    if (reservation_id) {
      dispatch(releaseSeatBrij({ reservation_id }));
    }
  }, []);

  const temp_reservation_id = useSelector(
    (state) => state.seatLayout.reservation_id_temp
  );

  const globalOptions = useSelector((state) => state.movies.globalOptions);
  const realTimeSeatLayout = getValueFromGlobalOptions(
    globalOptions,
    "ENABLE_SEAT_LAYOUT_REAL_TIME"
  );

  useEffect(() => {
    dispatch(clearSelectedVoucher());
    dispatch(releaseRealTimeBlockedSeats()).finally(() => {
      const reservation_id = Math.floor(100000000 + Math.random() * 900000000);
      console.log("[reservation id] setting a new reservation id");
      dispatch(setTempReservationId(reservation_id));

      dispatch(fetchSeatLayoutBrij({ screen_id, ss_id, md_id }));
    });
    console.log("ss_id :>> ", ss_id);
    dispatch(
      fetchScheduleDetailsBrij({
        ss_id,
        category_id,
      })
    );

    dispatch(clearSeatSelection());
    window.scrollTo(0, 0);
    dispatch(resetTimer());
  }, []);

  useEffect(() => {
    if (!globalOptions) {
      return;
    }

    let backendSocket2 = io(BASEURL + "/", socket_options);
    setBackendSocket(backendSocket2);

    backendSocket2.on("connect", function () {
      console.log("connected to backend", backendSocket);

      if (
        backendSocket2 &&
        backendSocket2.connected &&
        realTimeSeatLayout === "Y"
      ) {
        console.log("its coming here inside socket init");
        backendSocket2.emit("joined-seat-layout", {
          ss_id: ss_id,
        });

        backendSocket2.on("seat-layout-changes", function (payload) {
          let { sl_id, status } = payload;
          console.log(
            "getting response for seat layout changes ===> ",
            payload
          );
          dispatch(
            updateSeatLayout({
              sl_id,
              status,
            })
          );
        });

        backendSocket2.on("select-seat-response", function (res) {
          if (!res.status) {
            console.log("select-seat-response status false: ", res);
            toast.error(res.message);
            dispatch(clearSeatSelection());
            dispatch(
              dispatch(fetchSeatLayoutBrij({ screen_id, ss_id, md_id }))
            );

            const reservation_id = Math.floor(
              100000000 + Math.random() * 900000000
            );
            dispatch(setTempReservationId(reservation_id));
            setSeats([]);
          }
        });
      }
    });

    return () => {
      if (backendSocket2) {
        backendSocket2.off("seat-layout-changes");
        backendSocket2.off("select-seat-response");
        backendSocket2.disconnect();
      }
    };
  }, [globalOptions]);

  const currency = useSelector((state) => state.movies.currency);
  const { curr_code, curr_id } = currency;
  const [specialRequest, setSpecialRequest] = useState("");
  const seatLayout = useSelector((state) => state.seatLayout.seatLayout);
  const seatLayoutLoading = useSelector(
    (state) => state.seatLayout.seatLayoutLoading
  );
  // useEffect(() => {
  //   if (category_id == 1) {
  //     dispatch(fetchScreeningTypes({ gogo_ss_id }));
  //   }
  // }, []);
  const [minSeatsCompulsory, setMinSeatsCompulsory] = useState(null);
  const screeningTypes = useSelector((state) => state.movies.screeningTypes);
  // useEffect(() => {
  //   if (booking_type) {
  //     if (screeningTypes && category_id == 1 && booking_type == 1) {
  //       const x = screeningTypes.find((type) => type.booking_type_id === 1);
  //       if (x) {
  //         setNumberOfSeats(x.min_seats);
  //         setMinSeatsCompulsory(x.min_seats);
  //       }
  //     }
  //   }
  // }, []);

  const scheduleDetails = useSelector((state) => state.movies.scheduleDetails);
  // useEffect(() => {
  //   if (scheduleDetails) {
  //     if (scheduleDetails.movie_id && scheduleDetails.content_lang_id) {
  //       const { movie_id, content_lang_id } = scheduleDetails;
  //       dispatch(fetchMovieDetails({ movie_id, content_lang_id }));
  //     }
  //   }
  // }, [scheduleDetails]);
  const movieDetails = useSelector((state) => state.movies.movieDetails2);
  const [modalShow, setModalShow] = useState(false);
  const selected_seats = useSelector(
    (state) => state.seatLayout.seats_selected
  );
  const [numberOfSeats, setNumberOfSeats] = useState(0);
  const [loader, setLoader] = useState(false);
  const onSelectSeats = (seatTypeIndex, singleSeat, numberOfSeats) => {
    const { sl_seat_name } = singleSeat;
    dispatch(
      selectSeats({
        seatTypeIndex,
        SeatTypes: singleSeat,
        numberOfSeats,
        category_id,
      })
    );
    // dispatch(fetchVouchers({ seat: SeatTypes }));
    let doesSeatExist = false;

    const newSeats = [...seats];
    if (newSeats.indexOf(sl_seat_name) > -1) {
      newSeats.splice(newSeats.indexOf(sl_seat_name), 1);
      doesSeatExist = true;
    } else {
      newSeats.push(sl_seat_name);
    }
    setSeats(newSeats);
    setUserSelectedSeatsNew(singleSeat);

    if (
      backendSocket &&
      backendSocket.connected &&
      realTimeSeatLayout === "Y"
    ) {
      onSelectSeatSocketHandling(singleSeat, doesSeatExist);
    }
  };

  const onSelectSeatSocketHandling = useCallback(
    function (singleSeat, doesSeatExist) {
      let payload = {
        seat: singleSeat,
        reservation_id: temp_reservation_id,
        ss_id: ss_id,
        guest_user_id: parseInt(window.localStorage.getItem("guest_user_id")),
      };

      if (doesSeatExist) {
        backendSocket.emit("deselect-seat", payload);
      } else {
        backendSocket.emit("select-seat", payload);
      }
    },
    [backendSocket, temp_reservation_id]
  );

  const getSeatClass = (status, singleSeat) => {
    if (singleSeat.sst_seat_type === "Wheelchair") {
      return "cell wheel-chair";
    } else if (status === 3) {
      return "cell wheel-chair";
    } else return "cell";
  };

  const onClickNumberOfSeatsSelector = (index) => {
    setNumberOfSeats(index);
    settotalSeatBooked(0);
    dispatch(clearSeatSelection());
  };
  let getLanguageSpecificMovieDetails = useSelector(
    (state) => state.movies.getLanguageSpecificMovieDetails
  );
  const [totalSeatBooked, settotalSeatBooked] = useState(0);
  let totalAmountPrice = useRef(0);
  let totalNetAmout = useRef(0);
  let seatsSelectedRateGroupNew = useRef([]);
  const [rateCardGroups, setrateCardGroups] = useState([]);
  const [reserveSeatError, setReserveSeatError] = useState(null);
  const rateCardGroupsFunction = useCallback(() => {
    if (selected_seats.length > 0) {
      let array = [];
      let checkCheckoutRateCardNeeded = 0;
      settotalSeatBooked(0);
      totalAmountPrice.current = 0;
      totalNetAmout.current = 0;
      selected_seats.map((x) => {
        let find_In = array.findIndex((new1) => {
          return new1.sst_id == x.sst_id;
        });
        if (find_In >= 0) {
          array[find_In]["AllSeats"].push(x);
          if (array[find_In]["rate_card_group"].length == 1) {
            array[find_In]["totalSelected"] =
              array[find_In]["totalSelected"] + 1;
            array[find_In]["rate_card_group"][0].totalSeatSelected =
              array[find_In]["rate_card_group"][0].totalSeatSelected + 1;
            totalNetAmout.current =
              parseFloat(totalAmountPrice.current) +
              parseFloat(array[find_In]["rate_card_group"][0].rc_total_amount);
            totalNetAmout.current =
              parseFloat(totalNetAmout.current) +
              parseFloat(array[find_In]["rate_card_group"][0].rc_net_amount);
            settotalSeatBooked((prev) => prev + 1);
          } else if (array[find_In]["rate_card_group"].length > 1) {
            checkCheckoutRateCardNeeded = checkCheckoutRateCardNeeded + 1;
          }
        } else {
          let arrayCardGroup = [];
          x.rate_card_group.map((rg) => {
            rg["totalSeatSelected"] = 0;
            arrayCardGroup.push(rg);
          });
          let obj = {
            srt_name: x.srt_name,
            sst_seat_type: x.sst_seat_type,
            sst_id: x.sst_id,
            AllSeats: [x],
            totalSelected: 0,
            rate_card_group: arrayCardGroup,
          };
          if (arrayCardGroup.length == 1) {
            obj["totalSelected"] = 1;
            obj["rate_card_group"][0].totalSeatSelected = 1;
            totalAmountPrice.current =
              parseFloat(totalAmountPrice.current) +
              parseFloat(obj["rate_card_group"][0].rc_total_amount);
            totalNetAmout.current =
              parseFloat(totalNetAmout.current) +
              parseFloat(obj["rate_card_group"][0].rc_net_amount);
            settotalSeatBooked((prev) => prev + 1);
          } else if (arrayCardGroup.length > 1) {
            checkCheckoutRateCardNeeded = checkCheckoutRateCardNeeded + 1;
          }
          array.push(obj);
        }
      });
      seatsSelectedRateGroupNew.current = array;
      console.log(
        "🚀 ~ file: SeatLayout.js ~ line 259 ~ rateCardGroupsFunction ~ seatsSelectedRateGroupNew",
        seatsSelectedRateGroupNew
      );

      if (
        checkCheckoutRateCardNeeded == 0 &&
        array[0]["rate_card_group"].length > 0
      ) {
        dispatch(
          onSetCompleteRateCard({
            status: true,
            seatGroup: seatsSelectedRateGroupNew.current,
          })
        );
        dispatch(
          onSetCompleteNewTotalAmount({
            totalAmount: totalAmountPrice.current,
            netAmount: totalNetAmout.current,
          })
        );
      } else if (checkCheckoutRateCardNeeded > 0) {
        dispatch(
          onSetCompleteRateCard({
            status: false,
            seatGroup: seatsSelectedRateGroupNew.current,
          })
        );
      }
      setrateCardGroups(array);
    } else {
      seatsSelectedRateGroupNew.current = [];
    }
  }, [selected_seats]);
  const history = useHistory();
  const totalCount = (rg, index, rg_index, type) => {
    console.log("seatsSelectedRateGroupNew", seatsSelectedRateGroupNew.current);
    let x =
      seatsSelectedRateGroupNew.current[index]["rate_card_group"][rg_index];
    if (
      type == "increment" &&
      seatsSelectedRateGroupNew.current[index].totalSelected ==
        seatsSelectedRateGroupNew.current[index].AllSeats.length
    ) {
      return false;
    }

    if (
      type == "increment" &&
      totalSeatBooked + 1 <= selected_seats.length &&
      x["totalSeatSelected"] <= selected_seats.length
    ) {
      x["totalSeatSelected"] = x["totalSeatSelected"] + 1;
      seatsSelectedRateGroupNew.current[index].totalSelected =
        seatsSelectedRateGroupNew.current[index].totalSelected + 1;
    } else if (
      seatsSelectedRateGroupNew.current[index].totalSelected >= 1 &&
      type == "decrement" &&
      x["totalSeatSelected"] >= 1
    ) {
      x["totalSeatSelected"] = x["totalSeatSelected"] - 1;
      seatsSelectedRateGroupNew.current[index].totalSelected =
        seatsSelectedRateGroupNew.current[index].totalSelected - 1;
    }
    let totalAmount = 0;
    let totalSeatBooked2 = 0;
    let totalNetAmout2 = 0;
    seatsSelectedRateGroupNew.current.map((z) => {
      z.rate_card_group.map((y) => {
        totalAmount =
          parseFloat(totalAmount) +
          parseFloat(y.totalSeatSelected) * parseFloat(y.rc_total_amount);

        totalNetAmout2 =
          parseFloat(totalNetAmout2) +
          parseFloat(y.totalSeatSelected) * parseFloat(y.rc_net_amount);
      });

      totalSeatBooked2 = parseInt(totalSeatBooked2) + parseInt(z.totalSelected);
    });
    totalAmountPrice.current = totalAmount;

    settotalSeatBooked(totalSeatBooked2);
    totalNetAmout.current = totalNetAmout2;
    x["tt_name"] = x["tt_name"] + " ";
    if (totalSeatBooked2 == selected_seats.length) {
      dispatch(
        onSetCompleteRateCard({
          status: true,
          seatGroup: seatsSelectedRateGroupNew.current,
        })
      );
      dispatch(
        onSetCompleteNewTotalAmount({
          totalAmount: totalAmountPrice.current,
          netAmount: totalNetAmout.current,
        })
      );
    } else {
      dispatch(
        onSetCompleteRateCard({
          status: false,
          seatGroup: seatsSelectedRateGroupNew.current,
        })
      );
    }
  };
  useEffect(() => {
    console.log("rate card group fired");
    rateCardGroupsFunction();
  }, [selected_seats]);

  const onProceedRateCardGroup = async () => {
    setLoader(true);
    dispatch(
      reserveSeats({
        selected_seats,
        schedule_show_id: ss_id,
        history,
        cinema_id: scheduleDetails.cinema_id,
        seatGroup: seatsSelectedRateGroupNew.current,
        gogo_ss_id: scheduleDetails.gogo_ss_id,
        gogo_booking_type_id: scheduleDetails.gogo_booking_type_id,
        category_id,
        callback: setLoader,
        screen_id,
        ss_id,
        md_id,
        closeModal: () => setModalShow(false),
        specialRequest,
        genre_id,
        movie_id,
        content_lang_id,
        isPrivate,
        setReserveSeatError,
        setShowModal,
        setModalMessage,
      })
    );
  };

  useEffect(slImageOnhover);

  // if (!isFromMoviePage) {
  //   history.replace(`/movie-details/${movie_id}/${content_lang_id}`);
  // }

  return (
    <div>
      {/* <SeatTypePopup show={modalShow} onHide={() => setModalShow(false)} />
       */}
      <Modal
        aria-labelledby="contained-modal-title-vcenter"
        className="seat-type-popup"
        centered
        show={modalShow}
        onHide={() => setModalShow(false)}
      >
        <section className="wrapper container-fluid">
          <img
            src={close}
            className="close-btn-main"
            onClick={() => setModalShow(!modalShow)}
            alt=""
          />

          <div className="row title-row">
            <div className="col-12 mb-3 text-center">
              <p className="title">{t("Specify seat type")}</p>
            </div>
            <div className="col-12 text-center">
              <div className="selected-seats">
                {t("Tickets Selected")} {totalSeatBooked}/
                {selected_seats.length}
              </div>
            </div>
          </div>
          <div className="seat-picker">
            <div className="seat-picker-row headings">
              <div>
                <p>{t("Seat Type")}</p>
              </div>
              <div>
                <p>{t("No. of Seats")}</p>
              </div>
              <div>
                <p>{t("Price")}</p>
              </div>
            </div>
            {rateCardGroups?.length > 0 &&
              rateCardGroups?.map((item, index) => (
                <>
                  <p className="seat-type-cat">{item?.sst_seat_type}</p>

                  {item?.rate_card_group?.map((rg, rg_index) => (
                    <div className="seat-picker-row" key={rg_index}>
                      <div>
                        <p className="pb-1">
                          {rg?.ticketTypeTranslations[iso_2?.toLowerCase()] ||
                            rg.tt_name}
                        </p>
                        <p>
                          {curr_code} {currencyFormatter(rg.rc_total_amount)}
                        </p>
                      </div>
                      <div className="modifier">
                        <span
                          className={
                            rg.totalSeatSelected == 0 ? "inactive" : ""
                          }
                          onClick={() =>
                            totalCount(rg, index, rg_index, "decrement")
                          }
                        >
                          -
                        </span>
                        <span
                          className={`${
                            rg.totalSeatSelected == "0" ? "inactive" : ""
                          }`}
                        >
                          {rg.totalSeatSelected}
                        </span>
                        <span
                          className={
                            item.totalSelected == item.AllSeats.length
                              ? "inactive"
                              : ""
                          }
                          onClick={() =>
                            totalCount(rg, index, rg_index, "increment")
                          }
                        >
                          +
                        </span>
                      </div>
                      <div>
                        <p>
                          {curr_code}{" "}
                          {currencyFormatter(
                            rg.rc_total_amount * rg.totalSeatSelected
                          )}
                        </p>
                      </div>
                    </div>
                  ))}
                </>
              ))}
            <div className="seat-picker-row total">
              <div className="">
                <p>{t("Total")}</p>
              </div>
              <div>
                <p>
                  {curr_code} {currencyFormatter(totalAmountPrice.current)}
                </p>
              </div>
            </div>
          </div>

          {false && (
            <>
              <div className="row">
                <div className="px-3 question">
                  <p>{t("Have a special request or instruction")}</p>
                </div>
              </div>
              <div className="row">
                <div className="px-3 w-100">
                  <input
                    type="text"
                    className="answer"
                    placeholder="Type Here..."
                    value={specialRequest}
                    onChange={(e) => setSpecialRequest(e.target.value)}
                  />
                </div>
              </div>
            </>
          )}

          {reserveSeatError && <p className="error">{reserveSeatError}</p>}
          {/* {totalSeatBooked === selected_seats.length && ( */}
          <button
            className={`btn-main btn-main-lg mx-auto ${
              totalSeatBooked !== selected_seats.length && "inactive"
            } ${loader && "loader-text"}`}
            onClick={() =>
              totalSeatBooked == selected_seats.length &&
              onProceedRateCardGroup()
            }
          >
            {t("Confirm")}
          </button>
          {/* )} */}
        </section>
      </Modal>

      <div className="container-fluid container-xl header-space">
        {category_id === "1" && (
          <section className="row justify-content-center for-prev-step">
            <div className="prev-step">
              <button
                className="btn-main mx-auto mt-3 mt-xl-4"
                onClick={() => history.go(-1)}
                onMouseOver={() => setBack(true)}
                onMouseOut={() => setBack(false)}
              >
                <img
                  src={back ? arrowBackActive : arrowBack}
                  className="mr3"
                  alt=""
                />
                {/* Back */}
              </button>
            </div>

            <div className="col-sm-9 mx-auto">
              <div className="steps">
                <div className={`step ticket complete`}>
                  {/* <img src={ticketRed} /> */}
                </div>
                <div className={`step screen complete`}>
                  {/* <img src={ticketRed} /> */}
                </div>
                <div className={`step seat complete`}>
                  {/* <img src={ticketRed} /> */}
                </div>
              </div>
            </div>
            {/* </div> */}
          </section>
        )}

        {false && (
          <>
            <section className="row">
              <div className="px-3 mx-auto">
                <div className="seat-selection-heading">
                  {!minSeatsCompulsory && (
                    <p className="">{t("How many Seats")}</p>
                  )}
                  {minSeatsCompulsory && (
                    <p className="">
                      {numberOfSeats} {t("seats required")}
                    </p>
                  )}
                </div>
              </div>
            </section>

            <section className={`row mb-4 for-prev-step`}>
              <div className="prev-step">
                <button
                  className="btn-main mx-auto my-2"
                  onClick={() => history.go(-1)}
                  onMouseOver={() => setBack(true)}
                  onMouseOut={() => setBack(false)}
                >
                  <img
                    src={back ? arrowBackActive : arrowBack}
                    className="mr3"
                    alt=""
                  />
                  {/* Back */}
                </button>
              </div>
              <div
                className={`col mx-auto  ${
                  minSeatsCompulsory && "seat-disabled"
                }`}
              >
                <div className="seat-selection-row list-inline">
                  {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((index) => (
                    <div className="list-inline-item" key={index}>
                      <div
                        className={
                          numberOfSeats === index
                            ? "seat-selection-btn selected"
                            : "seat-selection-btn"
                        }
                        onClick={() => onClickNumberOfSeatsSelector(index)}
                      >
                        {index}
                      </div>
                    </div>
                  ))}
                  {/* <div className="list-inline-item">
              <div className="seat-selection-btn selected">10</div>
            </div> */}
                </div>
              </div>
            </section>
          </>
        )}

        {/* <section className="row">
          <div className="px-3 mx-auto">
            <div className="seat-layout-screen">
              <img src={screenSl} className="img-fluid" />
            </div>
          </div>
        </section> */}
        {seatLayoutLoading ? (
          <div className="row">
            <div className="col-12 text-center">
              <Spinner />
            </div>
          </div>
        ) : (
          <>
            <section className="row">
              <div className="col-12">
                <div className="seat-layout-screen text-center mx-auto">
                  <img src={screenSl} className="img-fluid" />
                  <span>{t("All eyes this way")}</span>
                </div>
                <div
                  className={`table-responsive table-width ${
                    numberOfSeats === 0 && false ? "seat-disabled" : ""
                  }`}
                >
                  <table className="master-table table table-borderless">
                    {seatLayout?.map((seatTypes, seatTypeIndex) => (
                      <>
                        <tr key={seatTypeIndex} className="category">
                          <td>
                            <tr>
                              {!isMobile && (
                                <>
                                  <td className="category-price">
                                    <p>{seatTypes?.seatType}</p>
                                    <p>
                                      {curr_code}{" "}
                                      {currencyFormatter(seatTypes?.seat_price)}
                                    </p>
                                  </td>

                                  <td className="category-range"></td>
                                </>
                              )}
                              <td>
                                {isMobile && (
                                  <div className="seat-type">
                                    <p className="">
                                      {seatTypes?.seatType}
                                      {seatTypes?.screen_seat_type_url && (
                                        <span className="hover-image-span">
                                          <img
                                            src={infoCircle}
                                            id="hover-image"
                                            alt=""
                                          />
                                          <div className="seat-type-image">
                                            <img
                                              alt=""
                                              src={
                                                seatTypes?.screen_seat_type_url
                                              }
                                            />
                                          </div>
                                        </span>
                                      )}
                                    </p>
                                  </div>
                                )}
                                <table className="actual-table">
                                  <tr>
                                    <td>
                                      <table>
                                        {seatTypes?.seatsFinal.map(
                                          (singleRow, rowIndex) => (
                                            <tr key={rowIndex}>
                                              {singleRow.map(
                                                (singleSeat, seatIndex) =>
                                                  singleSeat.srt_id == 2 ||
                                                  singleSeat.srt_id === 12 ||
                                                  singleSeat.srt_id === 13 ? (
                                                    <td key={seatIndex}>
                                                      <div className="cell"></div>
                                                    </td>
                                                  ) : (
                                                    <td key={seatIndex}>
                                                      <div
                                                        className={`${getSeatClass(
                                                          singleSeat.sl_seat_status,
                                                          singleSeat
                                                        )} ${
                                                          singleSeat.SeatSelected
                                                            ? "selected"
                                                            : singleSeat.seat_reserve_type_id ==
                                                              `8`
                                                            ? "unavailable"
                                                            : ""
                                                        } ${
                                                          singleSeat.Status ===
                                                          4
                                                            ? ""
                                                            : "seat"
                                                        }
                                                      ${
                                                        singleSeat.covidBlocked ||
                                                        singleSeat.covidBlockedTemp
                                                          ? "covid-blocked"
                                                          : ""
                                                      }
                                    `}
                                                        // className="cell seat"
                                                        onClick={() =>
                                                          !singleSeat.covidBlocked &&
                                                          !singleSeat.covidBlockedTemp &&
                                                          singleSeat.seat_reserve_type_id !=
                                                            `8`
                                                            ? onSelectSeats(
                                                                seatIndex,
                                                                singleSeat,
                                                                numberOfSeats
                                                              )
                                                            : null
                                                        }
                                                      ></div>
                                                      {/* {seatIndex} */}
                                                    </td>
                                                  )
                                              )}
                                            </tr>
                                          )
                                        )}
                                      </table>
                                    </td>
                                    {/* <td>
                              <table>
                                {[1, 2, 3, 4, 5].map((index) => (
                                  <tr>
                                    {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map(() => (
                                      <td className=""><div className="cell seat"></div></td>
                                    ))}
                                  </tr>
                                ))}
                              </table>
                            </td> */}
                                    {/* <td>
                              <table>
                                {[1, 2, 3, 4, 5].map((index) => (
                                  <tr>
                                    <td className=""><div className="cell">{index}</div></td>
                                  </tr>
                                ))}
                              </table>
                            </td> */}
                                  </tr>
                                  {/* <tr>
                              <td colSpan="3">
                                <p className="text-center">
                                  {seatTypes?.seatType}
                                </p>
                              </td>
                            </tr>*/}
                                </table>
                              </td>
                            </tr>
                          </td>
                        </tr>
                        {/* <tr>
                          <td colSpan="3">
                            <div className="seat-type">
                              <p className="">
                                {seatTypes?.seatType}
                                {seatTypes?.screen_seat_type_url && (
                                  <span className="hover-image-span">
                                    <img src={infoCircle} id="hover-image" />
                                    <div className="seat-type-image">
                                      <img
                                        src={seatTypes?.screen_seat_type_url}
                                      />
                                    </div>
                                  </span>
                                )}
                              </p>
                            </div>
                          </td>
                        </tr> */}
                      </>
                    ))}
                  </table>
                </div>
              </div>
            </section>

            <section className="row">
              <div className="px-3 seat-layout-icons movie-footer-space">
                <p className="">
                  <img src={standardSeat} className="mr-3" />
                  {t("Available")}
                </p>
                <p className="">
                  <img src={unavailableSeat} className="mr-3" />
                  {t("Unavailable")}
                </p>
                <p>
                  <img src={selectedSeat} className="mr-3" />
                  {t("Selected")}
                  {seats.length > 0 && `(${seats?.length})`}
                </p>
              </div>
            </section>

            {/* {selected_seats.length > 0 &&
              selected_seats.length === numberOfSeats && (
                <button
                  className="btn-main mx-auto"
                  onClick={() => {
                    setModalShow(true);
                    rateCardGroupsFunction();
                  }}
                >
                  {t("Confirm")}
                </button>
              )} */}
          </>
        )}
      </div>
      <MovieFooter
        movie_title={
          getLangSpecificAttribute(
            scheduleDetails?.altMovieContent,
            lang_id,
            "mc_title"
          ) || scheduleDetails?.movie_title
        }
        image={
          getLangSpecificAttribute(
            scheduleDetails?.languageSpecificImages,
            lang_id,
            "artwork"
          ) || dummyPoster
        }
        lang={scheduleDetails?.lang_name}
        format={scheduleDetails?.mf_name}
        rating={scheduleDetails?.rating}
        genre={
          scheduleDetails?.genres_with_translations?.[0]?.[
            iso_2?.toLowerCase()
          ] || scheduleDetails?.g_name
        }
        cinemaName={scheduleDetails?.cine_name}
        cine_location={scheduleDetails?.cine_location}
        cine_address={scheduleDetails?.cine_address}
        date={scheduleDetails?.ss_start_date}
        time={scheduleDetails?.ss_start_show_time}
        screeningType={
          booking_type == 1
            ? "Confirmed Screening"
            : booking_type == 2
            ? "Crowdsourced Screening"
            : booking_type == 3
            ? "Private Screening"
            : null
        }
        category_id={category_id}
        loader={loader}
        onClickMethod={() => {
          setModalShow(true);
          rateCardGroupsFunction();
        }}
        enabled={selected_seats.length > 0}
        // && selected_seats.length === numberOfSeats
        showConfirmButton={true}
      />

      <Modal
        show={showModal}
        aria-labelledby="contained-modal-title-vcenter"
        className="login-popup"
        centered
        // onHide={onCloseModal}
      >
        <section className="wrapper container-fluid">
          <div className="d-flex register_tabs"></div>

          <p className="title mt-4 text-center">{modalMessage}</p>
          <div className="d-flex justify-content-center">
            <button
              onClick={() => setShowModal(false)}
              type="submit text-align-center ml-2"
              className={`btn-main mt-3`}
            >
              {modalBtnMessage ? modalBtnMessage : t(`Try again`)}
            </button>
          </div>
        </section>
      </Modal>
    </div>
  );
};

// const SeatTypePopup = () => {
//   return (

//   );
// };

export default withNamespaces()(SeatLayout);
