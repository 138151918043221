import React, {
  useEffect,
  useState,
  useContext,
  useCallback,
  useRef,
} from "react";
import { withNamespaces } from "react-i18next";
import OtpInput from "react-otp-input";
import { Card, Accordion, Button } from "react-bootstrap";
import moment from "moment";
import poster2 from "@assets/pictures/poster-2.png";
import backArrow from "@assets/pictures/svgs/back-white-arrow.svg";
import greyArrowDown from "@assets/pictures/svgs/grey-arrow-down.svg";
import { setReservationID } from "../store/seatLayout/seatLayoutActions";
import {
  cinemaBookSeats,
  bookSeats,
  fetchScheduleDetails,
  onSuccessStripeToken,
  fetchStripePublisherKey,
  fetchReservationDetails,
  fetchReservationDetailsBrij,
  onSuccessStripeTokenBrij,
} from "../store/movies/movieActions";
import { seatTypesSelector } from "../store/movies/movieSelector";
import {
  clearSelectedVoucher,
  validateVoucherCode,
} from "../store/promotions/promotionsActions";
import {
  login as loginAction,
  register,
  loginViaOtp,
  numberRegex,
  forgotPassword,
  startTimer,
  resetTimer,
} from "../store/auth/authActions";
import creditCard from "@assets/pictures/svgs/credit-card.svg";
import wallet from "@assets/pictures/svgs/wallet.svg";
// import netBanking from "@assets/pictures/svgs/Vector.svg";
import netBanking from "@assets/pictures/svgs/net-banking .svg";
import aliPay from "@assets/pictures/svgs/ali-pay.svg";
import loginIcon from "@assets/pictures/svgs/Profile.svg";
import activeLoginIcon from "@assets/pictures/svgs/profile-active.svg";
import LoginPopup from "./partials/popups/LoginPopup";
import LoginOtp from "./partials/LoginOtp";
import ForgotPasswordOtp from "./partials/ForgotPasswordOtp";
import greenCheckCircle from "@assets/pictures/svgs/green-check-circle.svg";
import useIsMobile from "../customHooks/useIsMobile";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router";
import { loadStripe } from "@stripe/stripe-js";
import {
  CardElement,
  Elements,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import CountryCodeDropdown from "./partials/CountryCodeDropdown";
import { currencyFormatter } from "../helper/currencyFormatter";
import dummyPoster from "@assets/pictures/dummyPoster.png";
import { getLangSpecificAttribute } from "@helper/languages";
import _ from "lodash";
import showPasswordIcon from "@assets/pictures/svgs/show-password.svg";
import hidePasswordIcon from "@assets/pictures/svgs/hide-password.svg";

const PaymentFlowApp = ({ t }) => {
  const isMobile = useIsMobile();
  const [loginIconBool, setLoginIconBool] = useState(false);
  const [login, setLogin] = useState(false);
  const showBookingID = useSelector((state) => state.movies.showBookingID);

  const currency = useSelector((state) => state.movies.currency);
  const { curr_code, curr_id } = currency;
  const [open, setOpen] = useState([1, 0, 0, 0, 0, 0, 0]);
  const [otp, setOtp] = useState([]);
  const history = useHistory();
  const {
    category_id,
    movie_id,
    content_lang_id,
    genre_id,
    isPrivate,
    reservation_id,
  } = useParams();

  const dispatch = useDispatch();
  const toggleHandle = (key) => {
    setDefaultTab(`${key ? key : 0}`);
    setOpen((oldState) => {
      let openCopy = Array(7).fill(0);
      openCopy[key] = !oldState[key];
      return openCopy;
    });
  };
  const [showLoginPassword, setShowLoginPassword] = useState(false);
  const [showSignupPassword, setShowSignupPassword] = useState(false);

  const loginDetails = useSelector((state) => state.auth);
  const { loggedInUser, userHistory, registerError, loginError } = loginDetails;
  const scheduleDetails = useSelector((state) => state.movies.scheduleDetails);
  const [registerLoading, setRegisterLoading] = useState(false);
  const [type, setType] = useState(null);
  const [
    registerConditionsCheckError,
    setRegisterConditionsCheckError,
  ] = useState(false);
  const handleChangeOtp = (value) => {
    setOtp(value);
  };
  useEffect(() => {
    category_id == 1
      ? dispatch(fetchReservationDetails({ reservation_id, category_id }))
      : dispatch(fetchReservationDetailsBrij({ reservation_id, category_id }));
    window.scrollTo(0, 0);
    dispatch(fetchStripePublisherKey());
    dispatch(setReservationID(reservation_id));
  }, []);

  const booking_type = useSelector((state) => state.movies.booking_type);
  const reservationDetails = useSelector(
    (state) => state.movies.reservationDetails
  );
  const minutes = useSelector((state) => state.movies.timer.minutes);
  const seconds = useSelector((state) => state.movies.timer.seconds);
  const timerCompleted = useSelector((state) => state.movies.timerCompleted);
  const selectedFnbItems = useSelector((state) => state.fnb.selected_fnb_items);
  const fnbPrice = useSelector((state) => state.fnb.fnb_price);
  const seatTypes = useSelector((state) => seatTypesSelector(state));

  useEffect(() => {
    console.log("seatTypes in payment page: ===========>>", seatTypes);
  }, [scheduleDetails]);

  useEffect(() => {
    if (timerCompleted === true) {
      // toast.dismiss();
      // toast.error("Your session has expired");
      // history.replace(
      //   `/create-show-details/${scheduleDetails?.movie_id}/${scheduleDetails?.content_lang_id}`
      // );
      dispatch({
        type: `SET_SHOW`,
        payload: {
          movie_id: scheduleDetails.movie_id,
          content_lang_id: scheduleDetails.content_lang_id,
          category_id,
        },
      });
      dispatch({
        type: `SET_TIMEOUT_ERROR`,
        payload: { message: `Your session has expired`, show: true },
      });
    }
  }, [timerCompleted]);
  const [countryId, setCountryId] = useState(null);
  const [countryMobCode, setCountryMobCode] = useState(null);
  const onConfirmPayment = () => {
    console.log("category_id :>> ", category_id);
    // category_id == 1
    //   ? dispatch(
    //       cinemaBookSeats({
    //         reservation_id,
    //         cinema_id: scheduleDetails.cinema_id,
    //         customer_id: loggedInUser.cust_id,
    //         email,
    //       })
    //     )
    //   : dispatch(
    //       bookSeats({bookSeats
    //         reservation_id,
    //         cinema_id: scheduleDetails.cinema_id,
    //         customer_id: loggedInUser.cust_id,
    //         email,
    //       })
    //     );
    dispatch(
      cinemaBookSeats({
        reservation_id,
        cinema_id: scheduleDetails.cinema_id,
        customer_id: loggedInUser.cust_id,
        email,
      })
    );

    if (isMobile) {
      toggleHandle(3);
    } else {
      toggleHandle(2);
    }
  };
  const onClickLoginConfirmDetails = (e) => {
    if (e) {
      e.preventDefault();
    }
    // if (!loggedInConditionsCheck) {
    //   setLoginConditionsCheckError(true);
    //   return;
    // }
    setLoginSubmitted(true);
    if (mobile === "" || email === "") {
      return;
    }
    console.log("in confirm condition");
    setIsConfirmed(true);

    let openNew = open;
    openNew[0] = false;
    setOpen(openNew);
    setTimeout(() => {
      isMobile ? toggleHandle(2) : toggleHandle(1);
    }, 100);
  };
  const onClickRegisterConfirmDetails = (e) => {
    e.preventDefault();
    setRegisterConditionsCheckError(false);
    if (!registerConditionsCheck) {
      setSubmitted(true);
      setRegisterConditionsCheckError(true);
      return;
    }
    if (
      registerEmail === "" ||
      registerMobile === "" ||
      firstName === "" ||
      lastName === "" ||
      password === "" ||
      !registerConditionsCheck
    ) {
      return;
    }
    setRegisterLoading(true);
    dispatch(
      register({
        email: registerEmail,
        first_name: firstName,
        last_name: lastName,
        password,
        phone_no: registerMobile,
        country_id: countryId,
        callback: setRegisterLoading,
      })
    );
  };

  const onErrorHandling = () => {
    if (loginError === "Password is incorrect") {
      onClickForgotPassword();
    } else {
      setMemberBool("register");
      setLoginSubmitted(false);
    }
  };

  const onSubmitLogin = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setLoading(true);
    setType("email");
    setLoginSubmitted(true);
    let count = 0;

    if (
      (!isNumber && loginField === "") ||
      (isNumber && (loginField === "" || !countryId))
    ) {
      count++;
    }

    if (loginPassword === "") {
      count++;
    }

    if (count > 0) {
      return;
    }

    dispatch(
      loginAction({ loginField, password: loginPassword, callback: setLoading })
    );
  };

  const show = useSelector((state) => state.movies.show);
  // const reservation_id = useSelector((state) => state.seatLayout.reservation_id);

  const bookingSuccessful = useSelector(
    (state) => state.movies.bookingSuccessful
  );
  const [email, setEmail] = useState(``);
  const [mobile, setMobile] = useState(``);
  const [loggedInConditionsCheck, setLoggedInConditionsCheck] = useState(true);
  const [isConfirmed, setIsConfirmed] = useState(false);
  const emailInput = React.useRef(null);
  const mobileInput = React.useRef(null);

  useEffect(() => {
    if (loggedInUser) {
      emailInput.current.focus();
    }
  }, [email]);
  useEffect(() => {
    if (loggedInUser) {
      mobileInput.current.focus();
    }
  }, [mobile]);
  const stripeKey = useSelector((state) => state.movies.stripePublisherKey);
  const [loading, setLoading] = useState(false);
  const selected_seats = useSelector(
    (state) => state.seatLayout.seats_selected
  );
  const totalAmount = useSelector((state) => state.movies.totalAmount);
  const netAmount = useSelector((state) => state.movies.netAmount);
  const [registerEmail, setRegisterEmail] = useState(``);
  const [registerMobile, setRegisterMobile] = useState(``);
  const [firstName, setFirstName] = useState(``);
  const [defaultTab, setDefaultTab] = useState("0");
  const [lastName, setLastName] = useState(``);
  const [password, setPassword] = useState(``);
  const [registerConditionsCheck, setRegisterConditionsCheck] = useState(false);
  const [loginConditionsCheckError, setLoginConditionsCheckError] = useState(
    false
  );
  const [submitted, setSubmitted] = useState(false);
  const [loginSubmitted, setLoginSubmitted] = useState(false);
  const [loginSubmittedViaOTP, setLoginSubmittedViaOTP] = useState(false);
  const [loginPassword, setLoginPassword] = useState("");
  const [loadingOtp, setLoadingOtp] = useState(false);
  const [allowResendLogin, setAllowResendLogin] = useState(false);
  const [otpPopup, setOtpPopup] = useState(false);
  const [allowForgotPasswordResend, setAllowForgotPasswordResend] = useState(
    false
  );
  const [fpField, setFpField] = useState("");

  const [loginField, setLoginField] = useState("");
  const [isNumber, setisNumber] = useState(true);

  useEffect(() => {
    setisNumber(numberRegex(loginField));
  }, [loginField]);

  const state = useSelector((state) => state);
  const {
    auth: { loginOtpError },
  } = state;

  const isLoginViaOTPClicked = useRef(false);

  const onClickLoginViaOTP = () => {
    setType("otp");
    if (isLoginViaOTPClicked.current === true) {
      return;
    }

    setLoginSubmittedViaOTP(true);
    let count = 0;
    if (loginField === ``) {
      count++;
    }

    if (count > 0) {
      return;
    }
    setLoadingOtp(true);
    // setSubmittedviaOtp(false);
    isLoginViaOTPClicked.current = true;

    setTimeout(() => {
      isLoginViaOTPClicked.current = false;
    }, 5 * 1000);

    dispatch(
      loginViaOtp({
        loginField,
        callback: setLoadingOtp,
        successCallback: () => {
          setMemberBool("otp-login");
          dispatch(startTimer());
          setAllowResendLogin(false);
          setTimeout(() => setAllowResendLogin(true), 30000);
        },
      })
    );
  };

  const onClickForgotPassword = () => {
    setOtpPopup(true);
    setMemberBool("forgot-password");
  };

  const onClickResendLogin = () => {
    if (allowResendLogin) {
      setAllowResendLogin(false);
      dispatch(
        loginViaOtp({
          loginField,
          callback: () => {},
          successCallback: () => {
            dispatch(resetTimer());
            dispatch(startTimer());
            setAllowResendLogin(false);
            setInterval(() => setAllowResendLogin(true), 30000);
          },
        })
      );
    }
  };

  const onClickResend = () => {
    if (allowForgotPasswordResend) {
      setAllowForgotPasswordResend(false);
      dispatch(
        forgotPassword({
          field: fpField,
          callback: () => {},
          successCallback: () => {
            setAllowForgotPasswordResend(false);
            dispatch(resetTimer());
            dispatch(startTimer());
            setInterval(() => setAllowForgotPasswordResend(true), 30000);
          },
        })
      );
    }
  };

  const emailRegisterInput = React.useRef(null);
  const mobileRegisterInput = React.useRef(null);
  const fNameRegisterInput = React.useRef(null);
  const lnameRegisterInput = React.useRef(null);
  const passwordRef = React.useRef(null);
  useEffect(() => {
    if (!loggedInUser) {
      emailRegisterInput.current.focus();
    }
  }, [registerEmail]);
  useEffect(() => {
    if (!loggedInUser) {
      mobileRegisterInput.current.focus();
    }
  }, [registerMobile]);

  useEffect(() => {
    if (!loggedInUser) {
      fNameRegisterInput.current.focus();
    }
  }, [firstName]);

  useEffect(() => {
    if (!loggedInUser) {
      lnameRegisterInput.current.focus();
    }
  }, [lastName]);
  useEffect(() => {
    if (loggedInUser) {
      setEmail(loggedInUser.email || loggedInUser.cust_email);
      setMobile(loggedInUser.phone_no || loggedInUser.cust_mobile);
    }
  }, [loggedInUser]);

  useEffect(() => {
    if (!loggedInUser) {
      passwordRef.current.focus();
    }
  }, [password]);
  useEffect(() => {
    if (reservation_id === null) {
      history.replace(`/movie-details/${movie_id}/${content_lang_id}`);
    }
  }, [reservation_id, history]);
  //Start User Register Form
  const UserRegisterForm = () => {
    return <p></p>;
  };
  //End User Register Form

  //Start User Contact Information
  const UserContactInfo = () => {
    return <p></p>;
  };

  //Start OTP Screen
  const PaymentOtp = () => {
    return (
      <>
        <div className="col-md-10 col-lg-9 col-xl-7 px-xl-0 mx-auto otp-form">
          <p className="error">
            Almost there! <br />
            Please enter the 4 digit verification code you might have just
            received via email/ SMS.
          </p>
          <div className="otp-input">
            <OtpInput
              value={otp}
              onChange={handleChangeOtp}
              numInputs={4}
              separator={<span></span>}
            />
          </div>
          <div>
            <button className="payment-submit-btn btn-main">
              Verify Your Account
            </button>
          </div>
          {/* <div className="next-step">
            <p>Next Step</p>
            <div>
              <img
                src={greyArrowDown}
                alt="Arrow Down"
                className="grey-arrow-down"
              />
            </div>
          </div> */}
        </div>
      </>
    );
  };
  //End OTP Screen

  //Start Card Details
  const PaymentCardDetails = () => {
    return (
      <>
        <div className="col-md-10 col-lg-9 col-xl-7 mx-auto text-center payment-success payment-card">
          <div>
            <h4 className="font-weight-bold">Pay $150</h4>
            <p className="grey-font">Transaction #1234567889</p>
          </div>
          <div className="custom-form">
            <div>
              <label>Card Number</label>
              <input
                type="text"
                className="form-control"
                placeholder="1234 5678 1234 5678"
              />
            </div>
            <div>
              <label>Name on card</label>
              <input
                type="text"
                className="form-control"
                placeholder="Name Surname"
              />
            </div>
            <div className="grid-col">
              <div>
                <label>Expiry Date</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="11/24"
                />
              </div>
              <div>
                <label>CVV2</label>
                <input type="text" className="form-control" placeholder="123" />
              </div>
            </div>
          </div>
          <div className="mb-0 mt-n3">
            <button className="payment-submit-btn">Pay Now</button>
          </div>
        </div>
      </>
    );
  };
  //End Card Details

  //End User Contact Information

  //Start Payment Confirm Message
  const PaymentConfirmMessage = ({ t }) => {
    return (
      <>
        <div className="col-md-10 col-lg-9 col-xl-7 mx-auto text-center payment-success">
          {/* <div> */}
          {/* <h4 className="font-weight-bold">Pay ₹{totalAmount}</h4> */}
          {/* <p className="grey-font">Transaction #1234567889</p> */}
          {/* </div> */}
          <div className="">
            <img
              src={greenCheckCircle}
              alt="Check Circle"
              className="green-check-circle"
            />
          </div>
          <div className="pt-2">
            <h2 className="font-weight-bold">{t("Great News")}</h2>
            <p className="px-lg-5 pt-2">{t("transaction_success")}</p>
          </div>
          <div className="mb-0">
            <button
              className="payment-submit-btn"
              onClick={() =>
                history.replace(`/final-ticket/${showBookingID}/${category_id}`)
              }
            >
              {t("Close and get ticket")}
            </button>
          </div>
        </div>
      </>
    );
  };
  //End Confirm Message
  const onClickPay = () => {
    alert(1);
    dispatch(
      cinemaBookSeats({ reservation_id, cinema_id: scheduleDetails.cinema_id })
    );
  };
  const [memberBool, setMemberBool] = useState("login");
  const appliedVoucher = useSelector(
    (state) => state.promotions.applied_voucher
  );
  const discount = useSelector((state) => state.promotions.voucher_discount);
  const selected_language = useSelector(
    (state) => state.movies.selected_language
  );
  const { lang_name, iso_2, lang_id } = selected_language;
  return (
    <>
      <LoginPopup show={login} closeFunc={setLogin} />

      <section className="payment-page">
        <div className="container-fluid header-space">
          {/* {open} */}
          <div className="back-header">
            {false && (
              <div
                className="d-none d-md-flex"
                onClick={() =>
                  // history.push(
                  //   `/seat-layout/${scheduleDetails?.md_id}/${scheduleDetails?.ss_id}/${scheduleDetails?.screen_id}/${category_id}/${scheduleDetails.gogo_ss_id}/${booking_type}`
                  // )
                  history.go(-1)
                }
              >
                <img
                  src={backArrow}
                  alt="Back Arrow"
                  className="back-arrow-img"
                />
                <p className="text">{t("common.back")}</p>
              </div>
            )}
            <div className="ml-auto mr-auto mr-md-0 ml-md-auto">
              <p>
                {t("Time Remaining")}: {minutes.toString().padStart(2, "0")}:
                {seconds.toString().padStart(2, "0")}
              </p>
            </div>
          </div>
          {/* <button onClick={onClickPay}>Pay Now</button> */}
          <div className="row page-content">
            {!isMobile && (
              <div className="col-lg-4 col-xl-4 col-md-5 payment-booking-details">
                <PaymentBookingDetails category_id={category_id} t={t} />
              </div>
            )}

            <div className="col-md-7 col-lg-8 col-xl-8 pl-lg-5">
              <Accordion activeKey={open[parseInt(defaultTab)] && defaultTab}>
                {isMobile && (
                  <div className="custom-accordian">
                    <Accordion.Toggle
                      as={Button}
                      variant="link"
                      eventKey="0"
                      onClick={() => toggleHandle(0)}
                      className={open[0] === true ? "show" : "hidden"}
                    >
                      <div className="number-circle">1</div>
                      {/* <span>{t("Please enter contact information")}</span> */}
                      <span>{t("Verify movie details")}</span>
                    </Accordion.Toggle>
                    <Accordion.Collapse id="collapse-padding" eventKey="0">
                      <article className="w-100 payment-booking-details pt-3">
                        <div className="header">
                          <div className="left">
                            <img
                              src={
                                getLangSpecificAttribute(
                                  scheduleDetails?.languageSpecificImages,
                                  lang_id,
                                  "artwork"
                                ) ||
                                scheduleDetails?.md_thumbnail_url ||
                                dummyPoster
                              }
                              alt="Movie Img"
                              className="movie-img"
                            />
                          </div>
                          <div className="right">
                            <div>
                              <h6 className="title mb-1">
                                {getLangSpecificAttribute(
                                  scheduleDetails?.altMovieContent,
                                  lang_id,
                                  "mc_title"
                                ) || scheduleDetails?.movie_title}
                              </h6>
                              <div className="features">
                                <p className="mb-1">
                                  {scheduleDetails?.lang_name}{" "}
                                  {scheduleDetails?.mf_name}
                                </p>
                                <p className="mb-1">
                                  {scheduleDetails?.mrrdr_runtime}{" "}
                                  {t("common.mins")}{" "}
                                </p>
                                <p>
                                  {moment(
                                    scheduleDetails?.mrrdr_release_date
                                  ).format("YYYY")}{" "}
                                </p>
                              </div>
                              <div className="features">
                                {moment(
                                  scheduleDetails?.ss_actual_start_date_time
                                ).format("ll")}{" "}
                                |{" "}
                                {moment(
                                  scheduleDetails?.ss_start_show_time,
                                  "HH:mm:ss"
                                ).format("HH:mm")}
                              </div>
                            </div>
                            {/* <div className="movie-imbd">
            <p className="fs-13">{t("IMDB")}</p>
            <p className="value">7.5 / 10</p>
          </div> */}
                            <div className="movie-rating">
                              <p className="fs-13">
                                {t("Rating")}&nbsp;:&nbsp;
                                <span className="value small-font">
                                  {scheduleDetails?.rating}
                                </span>
                              </p>
                            </div>
                          </div>
                        </div>
                        {/* End Header */}

                        <div className="middle">
                          {false && (
                            <>
                              <div className="item">
                                <div className="heading">
                                  {t("Cinema")}:{" "}
                                  {/* <span className="text-primary fs-10 font-italic">(Change)</span> */}
                                </div>
                                <div className="content">
                                  {scheduleDetails?.cine_name},{" "}
                                  {scheduleDetails?.cine_address}
                                  {/* {scheduleDetails?.cine_location} */}
                                </div>
                              </div>
                              <div className="item">
                                <div className="heading">
                                  {t("Date")} & {t("Time")}:{" "}
                                  {/* <span className="text-primary fs-10 font-italic">(Change)</span> */}
                                </div>
                                <div className="content">
                                  {moment(
                                    scheduleDetails?.ss_actual_start_date_time
                                  ).format("ll")}{" "}
                                  |{" "}
                                  {moment(
                                    scheduleDetails?.ss_start_show_time,
                                    "HH:mm:ss"
                                  ).format("HH:mm")}
                                </div>
                              </div>
                              {category_id != 2 && (
                                <div>
                                  <p>{t("Screening Type")}:</p>
                                  <p>
                                    {/* {booking_type == 1
                ? t("Confirmed Screening")
                : booking_type == 2
                ? t("Crowdsourced Screening")
                : booking_type == 3
                ? t("Private Screening")
                : null} */}
                                    {scheduleDetails?.booking_type_name}
                                  </p>
                                </div>
                              )}
                              <div className="item">
                                <div className="heading">
                                  {t("Selected Seats")} (
                                  {scheduleDetails?.ticket_count}):
                                </div>
                                <div className="content">
                                  {/* {booking_type !== 3 && (
              // selected_seats?.map((seat, index) => (
              //   <>
              //     {seat.sl_seat_name}
              //     {selected_seats.length - 1 !== index && ","}
              //   </>
              // ))
              
            )}
            {booking_type === 3 && <p>{t("common.All")}</p>} */}
                                  <p>{scheduleDetails?.seat_name}</p>
                                </div>
                              </div>

                              <div className="item">
                                <div className="heading">
                                  {t("Ticket Type(s)")}:
                                </div>
                                <div className="content">
                                  <div>Premium 2 Adult, 2 Child</div>
                                  <div>Regular 2 Adult, 2 Child</div>
                                </div>
                              </div>

                              <div className="item">
                                <div className="heading">
                                  {t("Concessions")}:
                                </div>
                                <div className="content concession">
                                  <div className="d-flex justify-content-between align-items-center pb-1">
                                    <div className="fs-16">
                                      Popcorn <span class="text-light">x2</span>
                                    </div>
                                    <div className="fs-16">CHF 99.99</div>
                                  </div>
                                  <div className="d-flex justify-content-between align-items-center pb-1">
                                    <div className="fs-16">
                                      Burger <span class="text-light">x2</span>
                                    </div>
                                    <div className="fs-16">CHF 99.99</div>
                                  </div>
                                  <div className="d-flex justify-content-between align-items-center pb-1">
                                    <div className="fs-16">
                                      Pizza <span class="text-light">x2</span>
                                    </div>
                                    <div className="fs-16">CHF 99.99</div>
                                  </div>
                                  <div className="d-flex justify-content-between align-items-center border-top pt-2 mt-2">
                                    <div className="fs-16">Total</div>
                                    <div className="fs-16">CHF 99.99</div>
                                  </div>
                                </div>
                              </div>

                              <div className="d-flex justify-content-between align-items-center pt-3 pb-2">
                                <div className="fs-16">
                                  {t("final_ticket_page.Tickets")} (
                                  {booking_type === 3
                                    ? "All"
                                    : scheduleDetails?.ticket_count}
                                  ):{" "}
                                </div>
                                <div className="fs-22 font-weight-bold">
                                  {curr_code}{" "}
                                  {currencyFormatter(scheduleDetails?.amount)}
                                </div>
                              </div>
                              {discount && (
                                <div className="d-flex justify-content-between align-items-center pb-2">
                                  <div className="fs-16">
                                    {t("Discount Code")}:
                                    <br />({appliedVoucher.voucher_title})
                                  </div>
                                  <div className="fs-22 green-text font-weight-bold">
                                    - {curr_code} {currencyFormatter(discount)}
                                  </div>
                                </div>
                              )}

                              <div className="d-flex justify-content-between align-items-center pb-3">
                                <div className="fs-16">
                                  {t("VAT(Incl. 2.5%)")}
                                </div>
                                <div className="fs-22 font-weight-bold">
                                  CHF 20.00
                                </div>
                              </div>
                            </>
                          )}

                          {/* new layout */}
                          <article className="grid-50">
                            <div>
                              <p className="grey-title">{t("Cinema")}: </p>
                              <p>
                                {" "}
                                {scheduleDetails?.cine_name},{" "}
                                {scheduleDetails?.cine_address}
                                {/* {scheduleDetails?.cine_location} */}
                              </p>
                            </div>

                            {(true || scheduleDetails?.booking_type_name) && (
                              <div className="">
                                <p className="grey-title">
                                  {t("Screening Type")}:
                                </p>
                                <p className="">
                                  {scheduleDetails?.booking_type_name ||
                                    `Crowdsourced Screening`}
                                </p>
                              </div>
                            )}
                          </article>

                          {scheduleDetails?.seat_name && (
                            <>
                              <article className="grid-50">
                                <p className="grey-title">
                                  {t("Selected Seats")} (
                                  {scheduleDetails?.ticket_count}):
                                </p>
                                <p className="">{scheduleDetails?.seat_name}</p>
                              </article>
                            </>
                          )}

                          {seatTypes?.length > 0 && (
                            <article className="grid-25 primary-title bt-1 pt-2">
                              <div className="">{t("Ticket Type")}</div>
                              <div className="">{t("Qty.")}</div>
                              <div className="">{t("Price/ticket")}</div>
                              <div className="">{t("Total Price")}</div>
                            </article>
                          )}

                          {seatTypes?.length > 0 &&
                            seatTypes.map((seatType, seatTypeIndex) => (
                              <>
                                <article
                                  className="grey-title-lg"
                                  key={seatTypeIndex}
                                >
                                  {seatType.sst_seat_type}
                                </article>

                                {seatType?.seats?.length > 0 &&
                                  seatType.seats.map((rcg_child, index2) => (
                                    <article className="grid-25">
                                      <div className="">
                                        {rcg_child?.ticket_type_translations?.[
                                          iso_2?.toLowerCase()
                                        ] || rcg_child.tt_name}
                                      </div>
                                      <div className="">
                                        x{rcg_child.no_of_seats}
                                      </div>
                                      <div className="">
                                        {curr_code}{" "}
                                        {currencyFormatter(
                                          rcg_child.single_seat_price
                                        )}
                                      </div>
                                      <div className="">
                                        {curr_code}{" "}
                                        {currencyFormatter(
                                          rcg_child.total_price
                                        )}
                                      </div>
                                    </article>
                                  ))}
                              </>
                            ))}

                          {selectedFnbItems && selectedFnbItems.length > 0 && (
                            <article className="grey-title-lg">
                              {t("Concessions")}
                            </article>
                          )}

                          {selectedFnbItems &&
                            selectedFnbItems.length > 0 &&
                            selectedFnbItems.map((item) => (
                              <article className="grid-25">
                                <div>
                                  <p className="">
                                    {getLangSpecificAttribute(
                                      item?.FnbContent,
                                      lang_id,
                                      "item_lang_name"
                                    ) || item.item_name}
                                  </p>
                                </div>
                                <div>
                                  <p className="">x{item.Quantity}</p>
                                </div>
                                <div>
                                  <p className="">
                                    {curr_code}{" "}
                                    {currencyFormatter(item.fp_total_amount)}
                                  </p>
                                </div>
                                <div>
                                  <p className="">
                                    {curr_code}{" "}
                                    {currencyFormatter(item.total_price)}
                                  </p>
                                </div>
                              </article>
                            ))}

                          {discount && (
                            <article className="grid-50">
                              <div>
                                <p className="grey-title-lg discount">
                                  {t("Discount")}:{" "}
                                </p>
                              </div>
                              <div>
                                <p className="discount">
                                  {" "}
                                  - {curr_code} {currencyFormatter(discount)}
                                </p>
                              </div>
                            </article>
                          )}

                          <article className="grid-50 grand-total">
                            <p className="grey-title-lg d-flex flex-column">
                              {t("Grand Total")}
                              <span>
                                {t("(Incl. of 2.5% VAT - ")} {curr_code}{" "}
                                {currencyFormatter(
                                  _.round(
                                    totalAmount -
                                      netAmount +
                                      fnbPrice.total_tax,
                                    4
                                  )
                                )}{" "}
                                {")"}
                              </span>
                            </p>
                            <p className="d-flex align-items-center justify-content-end">
                              {curr_code}{" "}
                              {currencyFormatter(
                                scheduleDetails?.amount +
                                  fnbPrice.total_price -
                                  (discount ? discount : 0)
                              )}
                            </p>
                          </article>
                        </div>
                        {/* End Middle */}
                        {/* 
                        <div className="d-flex justify-content-between footer">
                          <div>
                            <p className="fs-16">
                              {t("Total Amount Payable")}:{" "}
                            </p>
                            <p className="fs-10 text-grey font-italic">
                              ({t("Amount inclusive of all taxes")})
                            </p>
                          </div>
                          <p className="fs-22 font-weight-bold">
                            {curr_code}{" "}
                            {currencyFormatter(
                              scheduleDetails?.amount -
                                (discount ? discount : 0)
                            )}
                          </p>
                        </div> */}

                        {/* End Footer */}

                        <button
                          className="btn-main payment-submit-btn mt-3"
                          onClick={() => toggleHandle(1)}
                        >
                          {t("Confirm")}
                        </button>
                      </article>
                    </Accordion.Collapse>
                  </div>
                )}
                <div
                  className={`custom-accordian ${
                    (isMobile ? open[1] : open[0]) && "inactive"
                  }`}
                >
                  <Accordion.Toggle
                    as={Button}
                    variant="link"
                    eventKey={isMobile ? "1" : "0"}
                    onClick={(e) => {
                      if (bookingSuccessful === true) {
                        e.preventDefault();
                        e.stopPropagation();
                        return false;
                      }
                      if (isMobile) {
                        toggleHandle(1);
                      } else {
                        toggleHandle(0);
                      }
                    }}
                    className={
                      (isMobile ? open[1] : open[0]) === true
                        ? "show"
                        : "hidden"
                    }
                  >
                    <div className="number-circle">{isMobile ? "2" : "1"}</div>
                    {/* <span>{t("Please enter contact information")}</span> */}
                    <span>
                      {loggedInUser
                        ? t("Verify your details")
                        : t("Sign in/Sign up to become a member")}
                    </span>
                  </Accordion.Toggle>
                  {/* {defaultTab == "0" ? ( */}
                  <Accordion.Collapse
                    eventKey={isMobile ? "1" : "0"}
                    id="collapse-padding"
                  >
                    {/* <> */}
                    {loggedInUser ? (
                      // USER CONTACT INFO START
                      <>
                        <form
                          onSubmit={(e) => onClickLoginConfirmDetails(e)}
                          className="pt-3"
                        >
                          <div className="custom-form col-md-10 col-lg-9 col-xl-7 px-xl-0 mx-auto">
                            <div>
                              <input
                                ref={emailInput}
                                type="email"
                                className="form-control"
                                placeholder={t(
                                  "placeholder.Enter your Email ID"
                                )}
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                              />
                              {/* {loginSubmitted && email === "" && ( */}
                              <p className="error">
                                {loginSubmitted &&
                                  email === "" &&
                                  t(
                                    "login_form.error_messages.Email is required"
                                  )}
                              </p>
                              {/* )} */}
                            </div>
                            <div class="d-flex">
                              <CountryCodeDropdown
                                className="form-control mr-2"
                                onSelectCountry={({
                                  country_id,
                                  country_mob_code,
                                }) => {
                                  setCountryId(country_id);
                                  setCountryMobCode(country_mob_code);
                                }}
                              />
                              <input
                                ref={mobileInput}
                                type="number"
                                min="10"
                                className="form-control mb-0"
                                placeholder={t(
                                  "login_form.placeholders.Enter your phone number"
                                )}
                                value={mobile}
                                onChange={(e) => setMobile(e.target.value)}
                              />
                              {/* {loginSubmitted && mobile === "" && ( */}

                              {/* )} */}
                            </div>
                            <p className="error">
                              {loginSubmitted &&
                                mobile === "" &&
                                t(
                                  "login_form.error_messages.Phone number is required"
                                )}
                            </p>
                            {/* <div className="agree-check">
                             <input
                               className="form-check-input"
                               type="checkbox"
                               name="exampleRadios"
                               id="exampleRadios1"
                               checked={loggedInConditionsCheck}
                               onChange={(e) => setLoggedInConditionsCheck(e.target.checked)}
                             />
                             <label className="form-check-label pt-1 pl-1" for="exampleRadios1">
                               I agree to all Terms & Conditions
                             </label>
                             {loginConditionsCheckError && (
                               <p className="error">
                                 You must agree to all Terms & Conditions
                               </p>
                             )}
                           </div> */}

                            <div class="pt-4 pb-3">
                              <button
                                className="payment-submit-btn btn-main"
                                type="submit"
                              >
                                {t("Confirm")}
                              </button>
                            </div>
                          </div>
                        </form>
                        {/* <div className="next-step pb-3">
                          <p>{t("Next Step")}</p>
                          <div>
                            <img
                              src={greyArrowDown}
                              alt="Arrow Down"
                              className="grey-arrow-down"
                            />
                          </div>
                        </div> */}
                      </>
                    ) : (
                      <>
                        <form
                          onSubmit={(e) => onClickRegisterConfirmDetails(e)}
                          className="py-3"
                        >
                          <div className="custom-form col-lg-9 col-xl-7 px-xl-0 mx-auto">
                            {/* <div
                              className="register-btn mx-auto"
                              onClick={() => setLogin(true)}
                              onMouseOver={() => setLoginIconBool(true)}
                              onMouseOut={() => setLoginIconBool(false)}
                            >
                              <button
                                className="payment-submit-btn"
                                onClick={() => setMemberBool("login")}
                              >
                                 <img
                                  src={
                                    loginIconBool && !isMobile
                                      ? activeLoginIcon
                                      : loginIcon
                                  }
                                  className="img-fluid pr-3"
                                  alt=""
                                /> 
                                {t("Login")}
                              </button>
                            </div>
                            <div className="or-div">
                              <p className="or-text">
                                <span>{t("Or")}</span>
                              </p>
                            </div>
                            <div>
                              <button
                                className={`mb-3 payment-submit-btn btn-main ${
                                  registerLoading && "loader-text"
                                }`}
                                type="submit"
                                onClick={() => setMemberBool("register")}
                              >
                                {t("Register")}
                              </button>
                            </div> */}
                            {/* {memberBool ? ( */}
                            <div className="d-flex w-100 justify-content-around align-items-center mb-4 register_tabs">
                              <div
                                className={`w-100 tabs py-2 ${
                                  memberBool === "login" && "active"
                                }`}
                                onClick={() => setMemberBool("login")}
                              >
                                {t(`header-footer-menu.Sign in`)}
                              </div>
                              <div
                                className={`w-100 tabs py-2 ${
                                  memberBool === "register" && "active"
                                }`}
                                onClick={() => setMemberBool("register")}
                              >
                                {/* {t(`login_form.Login`)} */}
                                {t(`header-footer-menu.signup`)}
                              </div>
                            </div>
                            <div
                              className={`w-100 login-popup ${
                                memberBool === "login" ? "d-block" : "d-none"
                              }`}
                            >
                              <div className="w-100 user-form">
                                <label>
                                  {t("login_form.placeholders.email-mobile")}
                                  <div className="d-flex">
                                    {isNumber && (
                                      <CountryCodeDropdown
                                        className="country_code_drop mr-2"
                                        onSelectCountry={({
                                          country_id,
                                          country_mob_code,
                                        }) => {
                                          setCountryId(country_id);
                                          setCountryMobCode(country_mob_code);
                                        }}
                                      />
                                    )}
                                    <input
                                      type="text"
                                      placeholder={t(`Enter_email_or_mobile`)}
                                      value={loginField}
                                      onChange={(e) =>
                                        setLoginField(e.target.value)
                                      }
                                    />
                                  </div>
                                </label>

                                <p className="error">
                                  {(loginSubmitted || loginSubmittedViaOTP) &&
                                    loginField === "" &&
                                    t("field_required")}
                                </p>
                                <label className="position-relative">
                                  {t("login_form.Password")}{" "}
                                  <input
                                    type={
                                      showLoginPassword ? "text" : "password"
                                    }
                                    autocomplete="new-password"
                                    placeholder={t(
                                      "login_form.placeholders.Enter your password"
                                    )}
                                    value={loginPassword}
                                    min="4"
                                    onChange={(e) =>
                                      setLoginPassword(e.target.value)
                                    }
                                  />
                                  {!showLoginPassword && (
                                    <img
                                      width="30"
                                      height="30"
                                      className="show-password-icon"
                                      src={showPasswordIcon}
                                      onClick={() =>
                                        setShowLoginPassword((x) => !x)
                                      }
                                    />
                                  )}
                                  {showLoginPassword && (
                                    <img
                                      width="30"
                                      height="30"
                                      className="show-password-icon"
                                      src={hidePasswordIcon}
                                      onClick={() =>
                                        setShowLoginPassword((x) => !x)
                                      }
                                    />
                                  )}
                                </label>
                                <p className="error">
                                  {loginSubmitted &&
                                    loginPassword === "" &&
                                    type === "email" &&
                                    t(
                                      "login_form.error_messages.Password is required"
                                    )}
                                </p>
                                {loginError &&
                                loginPassword !== "" &&
                                type === "email" ? (
                                  <p className="error">
                                    {loginError},{" "}
                                    <span
                                      style={{ cursor: `pointer` }}
                                      onClick={onErrorHandling}
                                    >
                                      {loginError ===
                                      "Password is incorrect" ? (
                                        <u>
                                          {t(
                                            `please click here to change password`
                                          )}
                                        </u>
                                      ) : (
                                        <u>
                                          {t(`please click here to sign up`)}
                                        </u>
                                      )}
                                    </span>
                                  </p>
                                ) : null}
                                {loginOtpError && type === "otp" ? (
                                  <p className="error">
                                    {loginOtpError},{" "}
                                    <span
                                      style={{ cursor: `pointer` }}
                                      onClick={() => {
                                        setMemberBool("register");
                                        setLoginSubmitted(false);
                                      }}
                                    >
                                      <u>{t("please click here to sign up")}</u>
                                    </span>
                                  </p>
                                ) : null}

                                <button
                                  type="button"
                                  onClick={onSubmitLogin}
                                  className={`payment-submit-btn btn-main mt-3 ${
                                    loading && "loader-text"
                                  }`}
                                >
                                  {t("login_form.Login")}
                                </button>
                              </div>

                              <div className="d-flex justify-content-between align-items-center mt-3">
                                <button
                                  className="btn-basic"
                                  onClick={onClickForgotPassword}
                                >
                                  {t(`login_form.forgot-password`)}
                                </button>

                                <button
                                  onClick={onClickLoginViaOTP}
                                  className={`btn-basic ${
                                    loadingOtp && "loader-text"
                                  }`}
                                >
                                  {t(`Login via OTP`)}
                                </button>
                              </div>
                              <div className="mt-3">
                                <p
                                  className="text-center"
                                  onClick={() => setMemberBool("register")}
                                >
                                  Not a member yet? <a href="#">Sign up</a>
                                </p>
                              </div>
                            </div>

                            {/* Login Via OTP */}
                            <div
                              className={`w-100 login-popup ${
                                memberBool === "otp-login"
                                  ? "d-block"
                                  : "d-none"
                              }`}
                            >
                              <LoginOtp
                                allowResendLogin={allowResendLogin}
                                setAllowResendLogin={setAllowResendLogin}
                                onClickResendLogin={onClickResendLogin}
                                onSuccess={() => setMemberBool("login-success")}
                                closeFunc={() => setMemberBool("login")}
                              />
                            </div>

                            <div
                              className={`w-100 login-popup ${
                                memberBool === "forgot-password"
                                  ? "d-block"
                                  : "d-none"
                              }`}
                            >
                              <ForgotPasswordOtp
                                onClickResend={onClickResend}
                                allowResend={allowForgotPasswordResend}
                                setAllowResend={setAllowForgotPasswordResend}
                                field={fpField}
                                setField={setFpField}
                                onSuccess={() =>
                                  setMemberBool("forgot-password-success")
                                }
                                closeFunc={() => setMemberBool("login")}
                              />
                            </div>

                            {/* ) : ( */}
                            <div
                              className={`w-100 ${
                                memberBool === "register" ? "d-block" : "d-none"
                              }`}
                            >
                              <div>
                                <input
                                  ref={emailRegisterInput}
                                  type="email"
                                  className="form-control"
                                  placeholder={t(
                                    "login_form.placeholders.Enter your email"
                                  )}
                                  value={registerEmail}
                                  onChange={(e) =>
                                    setRegisterEmail(e.target.value)
                                  }
                                />
                                {/* {submitted && registerEmail === "" && ( */}
                                <p className="error">
                                  {submitted &&
                                    registerEmail === "" &&
                                    t(
                                      "login_form.error_messages.Email is required"
                                    )}
                                </p>
                                {/* )} */}
                              </div>

                              <div className="d-flex">
                                <CountryCodeDropdown
                                  className="form-control mr-2"
                                  onSelectCountry={({
                                    country_id,
                                    country_mob_code,
                                  }) => {
                                    setCountryId(country_id);
                                    setCountryMobCode(country_mob_code);
                                  }}
                                />
                                <input
                                  ref={mobileRegisterInput}
                                  type="number"
                                  min="9"
                                  className="form-control"
                                  placeholder={t(
                                    "login_form.placeholders.Enter your phone number"
                                  )}
                                  value={registerMobile}
                                  onChange={(e) =>
                                    setRegisterMobile(e.target.value)
                                  }
                                />
                                {/* {submitted && registerMobile === "" && ( */}

                                {/* )} */}
                              </div>
                              <p className="error">
                                {submitted &&
                                  registerMobile === "" &&
                                  t(
                                    "login_form.error_messages.Phone number is required"
                                  )}
                              </p>

                              <div className="row justify-content-between align-items-center">
                                <div className="col-lg-6 pr-lg-2">
                                  <input
                                    ref={fNameRegisterInput}
                                    type="text"
                                    className="form-control"
                                    placeholder={t(
                                      "login_form.placeholders.Enter your first name"
                                    )}
                                    value={firstName}
                                    onChange={(e) =>
                                      setFirstName(e.target.value)
                                    }
                                  />
                                  {/* {submitted && firstName === "" && ( */}
                                  <p className="error">
                                    {submitted &&
                                      firstName === "" &&
                                      t(
                                        "login_form.error_messages.First name is required"
                                      )}
                                  </p>
                                  {/* )} */}
                                </div>
                                <div className="col-lg-6 pl-lg-2">
                                  <input
                                    ref={lnameRegisterInput}
                                    type="text"
                                    className="form-control"
                                    placeholder={t(
                                      "login_form.placeholders.Enter your last name"
                                    )}
                                    value={lastName}
                                    onChange={(e) =>
                                      setLastName(e.target.value)
                                    }
                                  />
                                  {/* {submitted && lastName === "" && ( */}
                                  <p className="error">
                                    {submitted &&
                                      lastName === "" &&
                                      t(
                                        "login_form.error_messages.Last name is required"
                                      )}
                                  </p>
                                  {/* )} */}
                                </div>
                              </div>
                              <div className="position-relative">
                                <input
                                  ref={passwordRef}
                                  type={
                                    showSignupPassword ? "text" : "password"
                                  }
                                  autocomplete="new-password"
                                  className="form-control mb-0"
                                  placeholder={t(
                                    "login_form.placeholders.Enter your password"
                                  )}
                                  value={password}
                                  onChange={(e) => setPassword(e.target.value)}
                                />
                                {!showSignupPassword && (
                                  <img
                                    width="30"
                                    height="30"
                                    className="show-password-icon show-password-icon-2"
                                    src={showPasswordIcon}
                                    onClick={() =>
                                      setShowSignupPassword((x) => !x)
                                    }
                                  />
                                )}
                                {showSignupPassword && (
                                  <img
                                    width="30"
                                    height="30"
                                    className="show-password-icon show-password-icon-2"
                                    src={hidePasswordIcon}
                                    onClick={() =>
                                      setShowSignupPassword((x) => !x)
                                    }
                                  />
                                )}
                                {/* {submitted && password === "" && ( */}
                                <p className="error">
                                  {submitted &&
                                    password === "" &&
                                    t(
                                      "login_form.error_messages.Password is required"
                                    )}
                                </p>
                                {/* )} */}
                              </div>
                              <div className="agree-check">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  name="exampleRadios"
                                  id="exampleRadios1"
                                  checked={registerConditionsCheck}
                                  onChange={(e) =>
                                    setRegisterConditionsCheck(e.target.checked)
                                  }
                                />
                                <label
                                  className="form-check-label pt-1 pl-1"
                                  for="exampleRadios1"
                                >
                                  {/* {t("I agree to all Terms & Conditions")} */}
                                  {t("I agree to all")}{" "}
                                  <a href="/tnc" target="_blank">
                                    {t("Terms & Conditions")}
                                  </a>
                                </label>
                                {/* {registerConditionsCheckError && ( */}

                                {/* )} */}
                                {/* {registerError && <p>{registerError}</p>} */}
                              </div>
                              <p className="error">
                                {submitted && !registerConditionsCheck && (
                                  <>
                                    {t(
                                      "You must agree to all Terms & Conditions"
                                    )}
                                    <br /> <br />
                                  </>
                                )}
                                {registerError && <>{registerError}</>}
                              </p>
                              {/* <p className="error">
                            </p> */}
                              {/* <div>
                              <button
                                className={`payment-submit-btn btn-main ${
                                  registerLoading && "loader-text"
                                }`}
                                type="submit"
                              >
                                {t("Continue")}
                              </button>
                            </div>
                            <div className="or-div">
                              <p className="or-text">
                                <span>{t("Or")}</span>
                              </p>
                            </div>
                            <div
                              className="register-btn w-75 mx-auto"
                              onClick={() => setLogin(true)}
                              onMouseOver={() => setLoginIconBool(true)}
                              onMouseOut={() => setLoginIconBool(false)}
                            >
                              <button className="payment-submit-btn">
                                <img
                                  src={
                                    loginIconBool && !isMobile
                                      ? activeLoginIcon
                                      : loginIcon
                                  }
                                  className="img-fluid pr-3"
                                  alt=""
                                />
                                {t("Login")}
                              </button>
                            </div> */}
                              <div>
                                <button
                                  className={`payment-submit-btn btn-main ${
                                    registerLoading && "loader-text"
                                  }`}
                                  type="submit"
                                >
                                  {t("Continue")}
                                </button>
                              </div>
                            </div>
                            {/* )} */}
                          </div>
                        </form>
                        {/* <div className="next-step">
                          <p>Next Step</p>
                          <div>
                            <img
                              src={greyArrowDown}
                              alt="Arrow Down"
                              className="grey-arrow-down"
                            />
                          </div>
                        </div> */}
                      </>
                    )}

                    {/* <PaymentOtp /> */}
                    {/* </> */}
                  </Accordion.Collapse>
                  {/* ) : null} */}
                </div>
                {/* {loggedInUser && loggedInConditionsCheck && isConfirmed && ( */}
                <div
                  className={`custom-accordian ${
                    !(loggedInUser && loggedInConditionsCheck && isConfirmed) &&
                    "inactive"
                  }`}
                >
                  <Accordion.Toggle
                    as={Button}
                    variant="link"
                    eventKey="1"
                    onClick={(e) => {
                      if (bookingSuccessful === true) {
                        e.preventDefault();
                        e.stopPropagation();
                        return false;
                      }
                      loggedInUser && isMobile
                        ? toggleHandle(2)
                        : toggleHandle(1);
                    }}
                    className={
                      (isMobile ? open[2] : open[1]) === true
                        ? "show"
                        : "hidden"
                    }
                  >
                    <div className="number-circle">{isMobile ? "3" : "2"}</div>
                    <span>{t("Choose Payment Mode")}</span>
                  </Accordion.Toggle>
                  {/* {defaultTab == "1" ? ( */}
                  <Accordion.Collapse
                    eventKey={isMobile ? "2" : "1"}
                    id="collapse-padding"
                  >
                    <>
                      <PaymentSelectMode
                        email={email}
                        category_id={category_id}
                        toggleHandle={toggleHandle}
                        onConfirmPayment={onConfirmPayment}
                        t={t}
                        mobile={mobile}
                        countryMobCode={countryMobCode}
                      />
                    </>
                    {/* <>
                    <PaymentCardDetails />
                  </> */}
                  </Accordion.Collapse>
                  {/* ) : null} */}
                </div>
                {/* // )} */}
                {/* {loggedInUser &&
                  isConfirmed &&
                  bookingSuccessful === true &&
                  loggedInConditionsCheck && ( */}
                <div
                  className={`custom-accordian booking-success-accordian ${
                    !(
                      loggedInUser &&
                      isConfirmed &&
                      bookingSuccessful === true &&
                      loggedInConditionsCheck
                    ) && "inactive"
                  }`}
                >
                  <Accordion.Toggle
                    as={Button}
                    variant="link"
                    eventKey="2"
                    // onClick={() => {
                    //   isMobile ? toggleHandle(3) : toggleHandle(2);
                    // }}
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                    }}
                    className={
                      (isMobile ? open[3] : open[2]) === true
                        ? "show"
                        : "hidden"
                    }
                  >
                    <div className="number-circle">{isMobile ? "4" : "3"}</div>
                    <span>{t("Confirmation")}</span>
                  </Accordion.Toggle>
                  {/* {defaultTab == "2" ? ( */}
                  <Accordion.Collapse
                    eventKey={isMobile ? "3" : "2"}
                    id="collapse-padding"
                  >
                    <div className="py-3">
                      {/* <PaymentConfirmMessage t={t} /> */}
                      <div className="col-md-10 col-lg-9 col-xl-7 mx-auto text-center payment-success">
                        {/* <div> */}
                        {/* <h4 className="font-weight-bold">Pay ₹{totalAmount}</h4> */}
                        {/* <p className="grey-font">Transaction #1234567889</p> */}
                        {/* </div> */}
                        <div className="">
                          <img
                            src={greenCheckCircle}
                            alt="Check Circle"
                            className="green-check-circle"
                          />
                        </div>
                        <div className="pt-2">
                          <h2 className="font-weight-bold">
                            {t("Great News")}
                          </h2>
                          <p className="px-lg-5 pt-2">
                            {t("transaction_success")}
                          </p>
                        </div>
                        <div className="mb-0">
                          <button
                            className="payment-submit-btn"
                            onClick={() =>
                              history.replace(
                                `/final-ticket/${showBookingID}/${category_id}`
                              )
                            }
                          >
                            {t("Close and get ticket")}
                          </button>
                        </div>
                      </div>
                    </div>
                  </Accordion.Collapse>
                  {/* ) : null} */}
                </div>
                {/* )} */}
              </Accordion>
            </div>
          </div>
        </div>
      </section>
      {/* <MovieFooter
        movie_title={scheduleDetails?.movie_title}
        image={scheduleDetails?.md_thumbnail_url}
        lang={scheduleDetails?.lang_name}
        format={scheduleDetails?.mf_name}
        rating={scheduleDetails?.rating}
        genre={scheduleDetails?.g_name}
        cinemaName={scheduleDetails?.cine_name}
        cine_location={scheduleDetails?.cine_location}
        cine_address={scheduleDetails?.cine_address}
        date={scheduleDetails?.ss_actual_start_date_time}
        time={scheduleDetails?.ss_actual_start_date_time}
        totalAmount={totalAmount}
        curr_code={curr_code}
        screeningType={
          booking_type == 1
            ? "Confirmed Screening"
            : booking_type == 2
            ? "Crowdsourced Screening"
            : booking_type == 3
            ? "Private Screening"
            : null
        }
        showType={
          show?.showType == 2
            ? "Unconfirmed"
            : show?.showType == 1
            ? "Confirmed"
            : null
        }
        category_id={category_id}
        booking_type={booking_type}
      /> */}
    </>
  );
};
// const stripePublisherKey = useSelector(
//   (state) => state.movies.stripePublisherKey
// );

// Start  Payment Select Mode
const PaymentSelectMode = ({
  email,
  category_id,
  toggleHandle,
  onConfirmPayment,
  t,
  mobile,
  countryMobCode,
}) => {
  const stripe = useStripe();
  const elements = useElements();
  const stripeKey = useSelector((state) => state.movies.stripePublisherKey);
  const currency = useSelector((state) => state.movies.currency);
  const { curr_code, curr_id } = currency;
  // const reservation_id = useSelector((state) => state.seatLayout.reservation_id);
  const { reservation_id } = useParams();
  const loginDetails = useSelector((state) => state.auth);
  const { loggedInUser, userHistory, registerError } = loginDetails;

  const [promoCode, setPromoCode] = useState(null);
  const [promoCodeError, setPromoCodeError] = useState(null);
  const appliedVoucher = useSelector(
    (state) => state.promotions.applied_voucher
  );
  const discount = useSelector((state) => state.promotions.voucher_discount);
  const scheduleDetails = useSelector((state) => state.movies.scheduleDetails);

  const onApplyPromoCode = () => {
    if (!promoCode || promoCode.length === 0) {
      // toast.dismiss();
      // toast.error("Please enter a promo code");
      return;
    }
    setPromoCodeError(null);
    dispatch(validateVoucherCode({ promoCode }))
      .then((response) => {
        console.log("response :>> ", response);
        if (response && response.status === false) {
          setPromoCodeError(response.message);
        }
      })
      .catch((err) => {
        console.log("error applying voucher", err);
        setPromoCodeError(err.message);
      });
  };

  const [loading, setLoading] = useState(false);
  const [stripeError, setStripeError] = useState(null);
  const dispatch = useDispatch();
  const timerCompleted = useSelector((state) => state.movies.timerCompleted);
  let isPayBtnClick = useRef(false);
  const handleSubmit = async (event) => {
    event.preventDefault();

    if (isPayBtnClick.current === true) {
      return;
    }

    // await stripe.create;
    isPayBtnClick.current = true;
    const response = await stripe.createToken(
      elements.getElement(CardElement),
      {
        currency: curr_code,
        account_holder_name: loggedInUser.first_name,
        account_holder_type: "individual",
      }
    );
    console.log("response :>> ", response);

    if (response.token && !response.token.used) {
      setStripeError(null);
      setLoading(true);
      console.log(`reservation_id`, reservation_id);
      if (timerCompleted === false) {
        dispatch(
          category_id == 1
            ? onSuccessStripeToken({
                reservation_id,
                token: response.token,
                cust_id: loggedInUser.cust_id,
                email,
                cinema_id: scheduleDetails.cinema_id,
                category_id,
                callback: setLoading,
                secondCallback: toggleHandle,
                cinema_name: scheduleDetails.cine_name,
                mobile,
                countryMobCode,
              })
            : onSuccessStripeTokenBrij({
                reservation_id,
                token: response.token,
                cust_id: loggedInUser.cust_id,
                email,
                cinema_id: scheduleDetails.cinema_id,
                category_id,
                callback: setLoading,
                secondCallback: toggleHandle,
                cinema_name: scheduleDetails.cine_name,
                mobile,
                countryMobCode,
              })
        );
      }

      // onConfirmPayment({stripe:true,})
    } else {
      isPayBtnClick.current = false;
      setStripeError(response.error.message);
      return;
    }
  };
  const onRemoveVoucher = () => {
    dispatch(clearSelectedVoucher());
  };
  const bookSeatError = useSelector((state) => state.movies.bookSeatError);
  return (
    <>
      {/* <div className="payment-mode col-md-10 col-lg-9 col-xl-7 px-0 mx-auto pt-3">
        <div>
          <img src={wallet} alt="wallet" />
          <h6
            className="text"
            onClick={() => loggedInUser && onConfirmPayment({ stripe: false })}
          >
            My Cinema Wallet
          </h6>
        </div> */}

      {/* <div>
          <img src={creditCard} alt="credit-card" />
          <h6 className="text">Credit / Debit Card</h6>
        </div> */}
      {/* <div>
          <img src={netBanking} alt="net-banking" />
          <h6 className="text">Netbanking</h6>
        </div> */}
      {/* <div>
          <img src={aliPay} alt="Ali Pay" className="grey-arrow-down" />
          <h6 className="text">Ali Pay</h6>
        </div>
      </div> */}
      <div
        className={`promo-code-input col-md-10 col-lg-9 col-xl-7 px-0 mx-auto ${
          appliedVoucher ? "align-items-center" : ""
        }`}
      >
        {!appliedVoucher ? (
          <>
            <input
              type="text"
              className="form-control"
              placeholder={t("Apply your promo code here")}
              P
              value={promoCode}
              onChange={(e) => setPromoCode(e.target.value)}
            />
            <button onClick={() => onApplyPromoCode()} className="promo-btn">
              {t("Apply")}
            </button>
            {promoCodeError && (
              <p className="error mt-1"> {t(promoCodeError)}</p>
            )}
          </>
        ) : (
          <>
            <p>
              {t("Voucher Applied")}: {appliedVoucher?.voucher_title}
            </p>
            <button
              onClick={onRemoveVoucher}
              className="promo-btn remove-code ml-2"
            >
              {t("Remove")}
            </button>
          </>
        )}
      </div>
      <div className="col-md-10 col-lg-9 col-xl-7 px-0 mx-auto mt-4 pb-3">
        {bookSeatError && <p className="error">{bookSeatError}</p>}
        <form onSubmit={handleSubmit}>
          {stripeKey && (
            <CardElement
              options={{
                style: {
                  base: {
                    fontSize: "16px",
                    color: "white",
                    "::placeholder": {
                      color: "#eeeeee",
                    },
                    padding: "10px",
                    border: "1px solid white",
                  },
                  invalid: {
                    color: "#dc3545",
                  },
                },
              }}
            />
          )}
          {stripeError && <p className="error stripe-error">{stripeError}</p>}
          <button
            className={`btn-main mx-auto mt-3 ${
              loading && "loader-text inactive"
            }`}
            disabled={!stripe}
          >
            {t("Pay")}
          </button>
        </form>
      </div>
    </>
  );
};
// End  Payment Select Mode

//Start Payment Booking Details
const PaymentBookingDetails = ({ category_id, t }) => {
  const currency = useSelector((state) => state.movies.currency);
  const { curr_code, curr_id } = currency;
  const totalAmount = useSelector((state) => state.movies.totalAmount);
  const netAmount = useSelector((state) => state.movies.netAmount);
  const scheduleDetails = useSelector((state) => state.movies.scheduleDetails);
  const booking_type = useSelector((state) => state.movies.booking_type);
  const appliedVoucher = useSelector(
    (state) => state.promotions.applied_voucher
  );
  const discount = useSelector((state) => state.promotions.voucher_discount);
  const selected_seats = useSelector(
    (state) => state.seatLayout.seats_selected
  );
  const selected_language = useSelector(
    (state) => state.movies.selected_language
  );
  const { lang_name, iso_2, lang_id } = selected_language;
  const selectedFnbItems = useSelector((state) => state.fnb.selected_fnb_items);
  const fnbPrice = useSelector((state) => state.fnb.fnb_price);
  const seatTypes = useSelector((state) => seatTypesSelector(state));


  const is_internet_handling_enable = useSelector((state) => state?.seatLayout?.is_internet_handling_enable);
  const internet_handling_fee =
      scheduleDetails?.selectedSeats?.[0]?.internet_handling_fee ?? 0
  return (
    <>
      <div className="header">
        <div className="left">
          <img
            src={
              getLangSpecificAttribute(
                scheduleDetails?.languageSpecificImages,
                lang_id,
                "artwork"
              ) ||
              scheduleDetails?.md_thumbnail_url ||
              dummyPoster
            }
            alt="Movie Img"
            className="movie-img"
          />
        </div>
        <div className="right">
          <div>
            <h6 className="title mb-1">
              {getLangSpecificAttribute(
                scheduleDetails?.altMovieContent,
                lang_id,
                "mc_title"
              ) || scheduleDetails?.movie_title}
            </h6>
            <div className="features">
              <p className="mb-1">
                {scheduleDetails?.lang_name} {scheduleDetails?.mf_name}
              </p>
              <p className="mb-1">
                {scheduleDetails?.mrrdr_runtime} {t("common.mins")}{" "}
              </p>
              <p>
                {moment(scheduleDetails?.mrrdr_release_date).format("YYYY")}{" "}
              </p>
            </div>
            <div className="features">
              {moment(scheduleDetails?.ss_actual_start_date_time).format("ll")}{" "}
              |{" "}
              {moment(scheduleDetails?.ss_start_show_time, "HH:mm:ss").format(
                "HH:mm"
              )}
            </div>
          </div>
          {/* <div className="movie-imbd">
            <p className="fs-13">{t("IMDB")}</p>
            <p className="value">7.5 / 10</p>
          </div> */}
          <div className="movie-rating">
            <p className="fs-13">
              {t("Rating")}&nbsp;:&nbsp;
              <span className="value small-font">
                {scheduleDetails?.rating}
              </span>
            </p>
          </div>
        </div>
      </div>
      {/* End Header */}

      <div className="middle">
        {false && (
          <>
            <div className="item">
              <div className="heading">
                {t("Cinema")}:{" "}
                {/* <span className="text-primary fs-10 font-italic">(Change)</span> */}
              </div>
              <div className="content">
                {scheduleDetails?.cine_name}, {scheduleDetails?.cine_address}
                {/* {scheduleDetails?.cine_location} */}
              </div>
            </div>
            <div className="item">
              <div className="heading">
                {t("Date")} & {t("Time")}:{" "}
                {/* <span className="text-primary fs-10 font-italic">(Change)</span> */}
              </div>
              <div className="content">
                {moment(scheduleDetails?.ss_actual_start_date_time).format(
                  "ll"
                )}{" "}
                |{" "}
                {moment(scheduleDetails?.ss_start_show_time, "HH:mm:ss").format(
                  "HH:mm"
                )}
              </div>
            </div>
            {category_id != 2 && (
              <div>
                <p>{t("Screening Type")}:</p>
                <p>
                  {/* {booking_type == 1
                ? t("Confirmed Screening")
                : booking_type == 2
                ? t("Crowdsourced Screening")
                : booking_type == 3
                ? t("Private Screening")
                : null} */}
                  {scheduleDetails?.booking_type_name}
                </p>
              </div>
            )}
            <div className="item">
              <div className="heading">
                {t("Selected Seats")} ({scheduleDetails?.ticket_count}):
              </div>
              <div className="content">
                {/* {booking_type !== 3 && (
              // selected_seats?.map((seat, index) => (
              //   <>
              //     {seat.sl_seat_name}
              //     {selected_seats.length - 1 !== index && ","}
              //   </>
              // ))
              
            )}
            {booking_type === 3 && <p>{t("common.All")}</p>} */}
                <p>{scheduleDetails?.seat_name}</p>
              </div>
            </div>

            <div className="item">
              <div className="heading">{t("Ticket Type(s)")}:</div>
              <div className="content">
                <div>Premium 2 Adult, 2 Child</div>
                <div>Regular 2 Adult, 2 Child</div>
              </div>
            </div>

            <div className="item">
              <div className="heading">{t("Concessions")}:</div>
              <div className="content concession">
                <div className="d-flex justify-content-between align-items-center pb-1">
                  <div className="fs-16">
                    Popcorn <span class="text-light">x2</span>
                  </div>
                  <div className="fs-16">CHF 99.99</div>
                </div>
                <div className="d-flex justify-content-between align-items-center pb-1">
                  <div className="fs-16">
                    Burger <span class="text-light">x2</span>
                  </div>
                  <div className="fs-16">CHF 99.99</div>
                </div>
                <div className="d-flex justify-content-between align-items-center pb-1">
                  <div className="fs-16">
                    Pizza <span class="text-light">x2</span>
                  </div>
                  <div className="fs-16">CHF 99.99</div>
                </div>
                <div className="d-flex justify-content-between align-items-center border-top pt-2 mt-2">
                  <div className="fs-16">Total</div>
                  <div className="fs-16">CHF 99.99</div>
                </div>
              </div>
            </div>

            <div className="d-flex justify-content-between align-items-center pt-3 pb-2">
              <div className="fs-16">
                {t("final_ticket_page.Tickets")} (
                {booking_type === 3 ? "All" : scheduleDetails?.ticket_count}
                ):{" "}
              </div>
              <div className="fs-22 font-weight-bold">
                {curr_code} {currencyFormatter(scheduleDetails?.amount)}
              </div>
            </div>
            {discount && (
              <div className="d-flex justify-content-between align-items-center pb-2">
                <div className="fs-16">
                  {t("Discount Code")}:
                  <br />({appliedVoucher.voucher_title})
                </div>
                <div className="fs-22 green-text font-weight-bold">
                  - {curr_code} {currencyFormatter(discount)}
                </div>
              </div>
            )}

            <div className="d-flex justify-content-between align-items-center pb-3">
              <div className="fs-16">{t("VAT(Incl. 2.5%)")}</div>
              <div className="fs-22 font-weight-bold">CHF 20.00</div>
            </div>
          </>
        )}

        {/* new layout */}
        <article className="grid-50">
          <div>
            <p className="grey-title">{t("Cinema")}: </p>
            <p>
              {" "}
              {scheduleDetails?.cine_name}, {scheduleDetails?.cine_address}
              {/* {scheduleDetails?.cine_location} */}
            </p>
          </div>
          {(true || scheduleDetails?.booking_type_name) && (
            <div className="">
              <p className="grey-title">{t("Screening Type")}:</p>
              <p className="">
                {scheduleDetails?.booking_type_name || `Crowdsourced Screening`}
              </p>
            </div>
          )}
        </article>

        {scheduleDetails?.seat_name && (
          <>
            <article className="grid-50">
              <p className="grey-title">
                {t("Selected Seats")} ({scheduleDetails?.ticket_count}):
              </p>
              <p className="">{scheduleDetails?.seat_name}</p>
            </article>
          </>
        )}

        {seatTypes?.length > 0 && (
          <article className="grid-25 primary-title bt-1 pt-2">
            <div className="">{t("Ticket Type")}</div>
            <div className="">{t("Qty")}</div>
            <div className="">{t("Price/ticket")}</div>
            <div className="">{t("Total Price")}</div>
          </article>
        )}

        {seatTypes?.length > 0 &&
          seatTypes.map((seatType, seatTypeIndex) => (
            <>
              <article className="grey-title-lg" key={seatTypeIndex}>
                {seatType.sst_seat_type}
              </article>

              {seatType?.seats?.length > 0 &&
                seatType.seats.map((rcg_child, index2) => (
                  <article className="grid-25" key={index2}>
                    <div className="">
                      {rcg_child?.ticket_type_translations?.[
                        iso_2?.toLowerCase()
                      ] || rcg_child.tt_name}
                    </div>
                    <div className="">x{rcg_child.no_of_seats}</div>
                    <div className="">
                      {curr_code}{" "}
                      {currencyFormatter(rcg_child.single_seat_price)}
                    </div>
                    <div className="">
                      {curr_code} {currencyFormatter(rcg_child.total_price)}
                    </div>
                  </article>
                ))}
            </>
          ))}
        { (is_internet_handling_enable && internet_handling_fee) &&
          (
            <>
              <article className="grey-title-lg">
              </article>

              <article className="grid-25">
                <div className="">
                  {t("Internet Handling Fee")}
                </div>
                <div className="">x{seatTypes?.length || 1}</div>
                <div className="">
                  {curr_code}{" "}
                  {currencyFormatter(internet_handling_fee)}
                </div>
                <div className="">
                  {curr_code} {currencyFormatter(internet_handling_fee * (seatTypes?.length || 1))}
                </div>
              </article>
            </>
          )
        }

        {selectedFnbItems?.length > 0 && (
          <article className="grey-title-lg">{t("Concessions")}</article>
        )}

        {selectedFnbItems?.length > 0 &&
          selectedFnbItems.map((item) => (
            <article className="grid-25">
              <div>
                <p className="">
                  {getLangSpecificAttribute(
                    item?.FnbContent,
                    lang_id,
                    "item_lang_name"
                  ) || item.item_name}
                </p>
              </div>
              <div>
                <p className="">x{item.Quantity}</p>
              </div>
              <div>
                <p className="">
                  {curr_code} {currencyFormatter(item.fp_total_amount)}
                </p>
              </div>
              <div>
                <p className="">
                  {curr_code} {currencyFormatter(item.total_price)}
                </p>
              </div>
            </article>
          ))}

        {discount && (
          <article className="grid-50">
            <div>
              <p className="grey-title-lg discount">{t("Discount")}: </p>
            </div>
            <div>
              <p className="discount">
                {" "}
                - {curr_code} {currencyFormatter(discount)}
              </p>
            </div>
          </article>
        )}

        <article className="grid-50 grand-total">
          <p className="grey-title-lg d-flex flex-column">
            {t("Grand Total")}
            <span>
              {t("(Incl. of 2.5% VAT - ")} {curr_code}{" "}
              {currencyFormatter(
                _.round(totalAmount - netAmount + fnbPrice.total_tax, 4)
              )}{" "}
              {")"}
            </span>
          </p>
          <p className="d-flex align-items-center justify-content-end">
            {curr_code}{" "}
            {currencyFormatter(
              scheduleDetails?.amount +
                fnbPrice.total_price -
                (discount ? discount : 0) +
                (internet_handling_fee ? internet_handling_fee : 0)
            )}
          </p>
        </article>
      </div>
      {/* End Middle */}

      {/* <div className="d-flex justify-content-between footer">
        <div>
          <p className="fs-16">{t("Total Amount Payable")}: </p>
          <p className="fs-10 text-grey font-italic">
            ({t("Amount inclusive of all taxes")})
          </p>
        </div>
        <p className="fs-22 font-weight-bold">
          {curr_code}{" "}
          {currencyFormatter(
            scheduleDetails?.amount - (discount ? discount : 0)
          )}
        </p>
      </div> */}

      {/* End Footer */}
    </>
  );
};
//End Payment Booking Details

const PaymentFlow = ({ t }) => {
  const stripePublisherKeyFromStore = useSelector(
    (state) => state.movies.stripePublisherKey
  );
  const stripePublisherKey =
    localStorage.getItem("stripe_publisher_key") || stripePublisherKeyFromStore;
  const stripePromise = loadStripe(stripePublisherKey);
  return (
    <Elements stripe={stripePromise}>
      <PaymentFlowApp t={t} />
    </Elements>
  );
};
export default withNamespaces()(PaymentFlow);
