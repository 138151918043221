import React, { useState, useEffect } from "react";
import { withNamespaces } from "react-i18next";
import MovieSection from "./partials/movieSection.jsx";
import { useSelector, useDispatch } from "react-redux";
import { fetchCreateOwnShow } from "../store/homepage/homepageActions";
import { motion } from "framer-motion";
import cinemaBg from "@assets/pictures/cinema-banner.png";

const OnDemand = ({ t }) => {
  const dispatch = useDispatch();
  const [createOwnShowCurrentPage, setCreateOwnShowCurrentPage] = useState(1);
  const [limit, setLimit] = useState(24);

  const createOwnShowMovies = useSelector(
    (state) => state.homepage.createOwnShow
  );
  const createOwnShowLoadMore = ({ callback }) => {
    setCreateOwnShowCurrentPage(createOwnShowCurrentPage + 1);
    setTimeout(() => callback(false), 750);
  };
  const createOwnShowLastPage = useSelector(
    (state) => state.homepage.createOwnShowLastPage
  );
  const uniqueCreateOwnShowMovies = {
    ...createOwnShowMovies,
    data: [
      ...new Map(
        createOwnShowMovies?.data?.map((item) => [item["movie_id"], item])
      ).values(),
    ],
  };

  console.log("this", createOwnShowMovies);
  useEffect(() => {
    dispatch(
      fetchCreateOwnShow({
        limit,
        currentPage: createOwnShowCurrentPage,
      })
    );
  }, [dispatch, limit, createOwnShowCurrentPage]);

  return (
    <>
      <section className="cinema-hero-section">
        <div className="hero-img-section">
          <img src={cinemaBg} alt="Movie Banner" className="hero-img-1" />
        </div>
        <div className="hero-text justify-content-center">
          <motion.div
            className="container-fluid container-xl"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 1.5 }}
          >
            <div className="row h-100 align-items-center">
              <div className="col-12 col-md-10 col-lg-8 col-xl-6 mx-auto text-center">
                <p className="title">{t("On-demand")}</p>
                <p className="msg">{t("on-demand-desc")}</p>
                {/* <div className="d-flex justify-content-center">
                      <img src={whiteArrowDown} className="down-arrow" alt="" />
                    </div> */}
              </div>
            </div>
          </motion.div>
        </div>
      </section>
      <div className="container-fluid header-space">
        {uniqueCreateOwnShowMovies?.data?.length > 0 && (
          <MovieSection
            heading={t("On Demand")}
            totalMovies={uniqueCreateOwnShowMovies?.pagination?.total}
            moviesArray={uniqueCreateOwnShowMovies?.data}
            movieCardArray={uniqueCreateOwnShowMovies?.data}
            moviesObject={uniqueCreateOwnShowMovies}
            loadMore={createOwnShowLoadMore}
            currentPage={uniqueCreateOwnShowMovies?.pagination?.currentPage}
            lastPage={createOwnShowLastPage}
            type={1}
            // setShowOffers={setShowOffers}
            notOnMobile
          />
        )}
      </div>
    </>
  );
};

export default withNamespaces()(OnDemand);
